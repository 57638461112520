import React from "react";
import { Button } from "@mui/material";

const PrimaryButton = ({ children, style, ...props }) => {
  return (
    <Button
      color="primary"
      size="large"
      variant="contained"
      style={{
        // border: "2px solid black",
        padding: ".2rem 2rem",
        borderRadius: 25,
        boxShadow: "4px 4px 0px 0px #D6352A",
        color: "#EDE8DA",
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
