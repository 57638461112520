import { Box, IconButton, Slider, Stack } from "@mui/material";
import React, { useEffect } from "react";

import Brightness1Icon from "@mui/icons-material/Brightness1";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import UndoIcon from "@mui/icons-material/Undo";
import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import { AreYouSure } from "../elements/AreYouSure";

import { DeleteSweep, Image, OpenWith } from "@mui/icons-material";
import BrushIcon from "@mui/icons-material/BrushOutlined";
import PanToolIcon from "@mui/icons-material/PanToolOutlined";
import HomeMiniIcon from "@mui/icons-material/HomeMini";
import HideImageIcon from "@mui/icons-material/HideImage";
import ImageIcon from "@mui/icons-material/Image";
import IconButtonWithLabel from "../IconButtonWithLabel";

const ToolbarOutliner = ({
  toolChanged,
  handleUndo,
  handleResetDrawing,
  handleImageToggle,
  header,
  imageToggle = true,
  handleRedo,
  canUndo,
}) => {
  // state for tool
  const [tool, setTool] = React.useState("brush");
  const [showImage, setShowImage] = React.useState(true);
  const [showResetDialog, setShowResetDialog] = React.useState(false);

  const handleCloseResetDialog = () => {
    setShowResetDialog(false);
  };

  const handleConfirmResetDialog = () => {
    setShowResetDialog(false);
    handleResetDrawing();
  };

  const handleOpenResetDialog = () => {
    setShowResetDialog(true);
  };

  const handlePaintBrush = () => {
    toolChanged("brush");
    setTool("brush");
  };
  const handlePanTool = () => {
    toolChanged("pan");
    setTool("pan");
  };

  const handleEraserTool = () => {
    toolChanged("eraser");
    setTool("eraser");
  };

  const handleToggleShowImage = () => {
    // TODO
    setShowImage(!showImage);
    handleImageToggle();
  };

  // CHeck if using a phone
  const isPhone = window.innerWidth < 600;

  const selectedToolTopStyle = {
    borderRadius: ".875rem 0 0 .875rem",
    backgroundColor: green[400],
  };
  const selectedToolBottomStyle = {
    borderRadius: "0 0 0 0",
    backgroundColor: green[400],
  };

  useEffect(() => {
    handlePaintBrush();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        pointerEvents: "none",
        padding: "24px",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {header}
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          pointerEvents: "auto",
          WebkitUserSelect: "none",
          background: "rgb(255,255,255)",
          boxSizing: "border-box",
          boxShadow:
            "0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017",
          borderRadius: "14px",
          position: "relative",
          alignSelf: "center",
        }}
      >
        <Box
          style={{
            position: "relative",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"}>
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "brush" ? selectedToolTopStyle : {}}
              onClick={handlePaintBrush}
            >
              <IconButtonWithLabel label="Pen" icon={<BrushIcon />} />
            </Box>
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "eraser" ? selectedToolBottomStyle : {}}
              onClick={handleEraserTool}
            >
              <IconButtonWithLabel label="Eraser" icon={<HomeMiniIcon />} />
            </Box>

            <Divider orientation="horizontal" flexItem />
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "pan" ? selectedToolBottomStyle : {}}
              onClick={handlePanTool}
            >
              <IconButtonWithLabel label="Move" icon={<OpenWith />} />
            </Box>

            <Divider orientation="vertical" flexItem />

            {imageToggle && (
              <Box onClick={handleToggleShowImage}>
                <IconButtonWithLabel
                  label="Backdrop"
                  icon={showImage ? <HideImageIcon /> : <ImageIcon />}
                />
              </Box>
            )}
            <Box onClick={handleUndo}>
              <IconButtonWithLabel label="Undo" icon={<UndoIcon />} />
            </Box>
            {/* <Divider orientation="horizontal" flexItem />
            <Box sx={{ pl: 1, pr: 1 }} onClick={handleOpenResetDialog}>
              <IconButton size="large">
                <DeleteSweep />
              </IconButton>
            </Box> */}
          </Stack>
        </Box>
        <AreYouSure
          open={showResetDialog}
          onClose={handleCloseResetDialog}
          onConfirm={handleConfirmResetDialog}
          title="Are you sure you want to reset the drawing?"
          contentText="This will delete all of your work."
          confirmButtonText="Reset Drawing"
          cancelButtonText="Cancel"
        />
      </Box>
    </Box>
  );
};

export default ToolbarOutliner;
