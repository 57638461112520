import IconBack from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { DialogDownloadPdf } from "../Dialog/DownloadPdf";
import Instructions from "../InstructionDialog";
import AppBarRoot from "./Root";

function AppBarAccount({ title, label, onCompare, onBack, onSavePdf }) {
  const navigate = useNavigateToTop();

  // const handleOnCompare = () => {
  //   navigate("/compare/" + label);
  // };
  return (
    <AppBarRoot
      startAction={
        <Stack direction={"row"} spacing={1}>
          <Typography
            variant="h5"
            style={{ alignSelf: "center", color: "white" }}
          >
            This is you
          </Typography>
        </Stack>
      }
      // endAction={
      //   <Stack direction={"row"} spacing={1}>
      //     <Instructions label={label} />
      //     <DialogDownloadPdf onSavePdf={onSavePdf} />
      //   </Stack>
      // }
    />
  );
}

export default AppBarAccount;
