import { Button, Snackbar } from "@mui/material";
import React, { Fragment } from "react";
import { makeBonsaiPublic } from "../../libs/makeBonsaiPublic";
import { LoadingButton } from "@mui/lab";
import { Share } from "@mui/icons-material";

export default function LoadingButtonShareBonsai({ user, bonsai, onShare }) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const vertical = "top";
  const horizontal = "center";
  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const handleShare = () => {
    setLoading(true);

    makeBonsaiPublic(user, bonsai, (address) => {
      let link = `https://thebonsaiproject.com/bonsaidesigner/${address}`;
      if (link) {
        try {
          navigator.clipboard.writeText(link);
          setTimeout(() => {
            setLoading(false);

            runSnackbar("Copied link");
          }, 1000);
        } catch (e) {
          setLoading(false);
        }
        onShare();

        // Set loading to false afer atleast 1 second
      }
    });
  };
  return (
    <Fragment>
      <LoadingButton
        startIcon={<Share />}
        loading={loading}
        onClick={handleShare}
        variant="outlined"
      >
        Share with others
      </LoadingButton>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}
