// React component that returns a grid of two grids. In the first gird the image is drawn and a list of bonsai pots. In the second grid are tools to manipulate the image
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { Fragment, useEffect } from "react";
import BonsaiAppBar from "./components/elements/BonsaiAppBar";
import DrawerWithControls from "./components/elements/DrawerWithControls";

import BonsaiImageTemplate from "./libs/bonsai/bonsai-template.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import ReleaseNotes from "./components/elements/ReleaseNotes";
import DesignProcessAppBar from "./components/elements/DesignProcessAppBar";
import MultiImageCarousel from "./components/MultiImageCarousel";
import BonsaiMasonryDesignList from "./components/BonsaiMasonryDesignList";
import ScreenBonsaiProfile from "./screens/BonsaiProfile";

import { bonsai } from "./libs/fakeDesignData";
import ScreenLogin from "./screens/Login";
import ScreenRoot from "./screens/Root";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ScreenDashboard } from "./screens/Dashboard";
import BetaReleaseInfo from "./components/BetaReleaseInfo";
// function that takes the p5 canvas and writes it to png
function saveCanvas(p, backgroundSelected, foregroundSelected) {
  window.plausible("Save Image Clicked", {
    props: { location: "bonsaidesigner" },
  });
  p.saveCanvas("bonsai", "png");
}

// Redirect page to www.thebonsaiproject.com
function handleGoToBonsaiProject() {
  window.location.href = "https://www.thebonsaiproject.com";
}

const BonsaiDesignerApp = () => {
  // Createref for p5 element
  // const sketchRef = React.createRef();

  // Create a sketchref state variable
  const [sketchRef, setSketchRef] = React.useState(null);

  // State to store an image
  const [foregroundImage, setForegroundImage] = React.useState(null);

  // State to store the selected bonsai bot
  const [selectedBonsaiPot, setSelectedBonsaiPot] = React.useState(null);

  // State to store an image
  const [backgroundImage, setBackgroundImage] = React.useState({
    src: null,
  });

  // state for guideline scale

  // Variables that determine how things are rendered
  const exportDisabled = false;

  // Theme and style stuff
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  function handleImageUploaded() {
    window.plausible("Bonsai Image Uploaded", {
      props: { location: "bonsaidesigner" },
    });
    localStorage.setItem("bonsai-design_edited", true);
  }

  const handleDesignMenuItemClicked = (process, item) => {
    if (process === "outline") {
      const { parent, src } = item;
      setBackgroundImage({ src: parent });
      setForegroundImage({ src: src });
    } else {
      setBackgroundImage({ src: item });
    }
  };

  const handleImageClicked = (item) => {
    console.error("havet done it");
  };

  useEffect(() => {
    // Listen to message and alert what is received
    window.addEventListener("message", (event) => {
      let data = event.data;
      if (data.action === "import") {
        setBackgroundImage({ src: data.data });
      } else if (data.action === "save") {
        sketchRef.canvas.toBlob((blob) => {
          let message = {
            action: "save",
            data: blob,
          };
          window.parent.postMessage(message, "*");
        });
      }
    });
  }, [sketchRef]);

  const isEmbed = window.location !== window.parent.location;

  return (
    <div display="flex">
      {/* <ReleaseNotes /> */}
      <BetaReleaseInfo />
      {/* <MultiImageCarousel /> */}
      {/* <Grid style={{ height: "100vh" }} container> */}
      <Router basename="/bonsaidesigner">
        <ScreenRoot />
      </Router>
      {/* <DrawerWithControls
          backgroundImage={backgroundImage}
          sketchRef={sketchRef}
          setSketchRef={setSketchRef}
          foregroundImage={foregroundImage}
          selectedBonsaiPot={selectedBonsaiPot}
        /> */}
      {/* <BonsaiMasonryDesignList onClick={handleImageClicked} data={bonsai} /> */}
      {/* <ScreenLogin /> */}
      {/* <ScreenBonsaiProfile /> */}
      {/* </Grid> */}
    </div>
  );
};

export default BonsaiDesignerApp;
