import { Button } from "@mui/material";
import React from "react";

export default function IconButtonWithLabel({ icon, label }) {
  return (
    <Button
      style={{
        flexDirection: "column",
        padding: 0,
        height: "100%",
        fontSize: "0.5rem",
        color: "black",
        padding: ".5rem 0rem",
      }}
      //   startIcon={icon}
    >
      {icon}
      {label}
    </Button>
  );
}
