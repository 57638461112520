export const withToolbar = {
  toolbar() {
    return this._toolbar;
  },
  setToolbar(toolbar) {
    this._toolbar = toolbar;
  },
  ORIENTATION_TOOLBAR: "orientation",
  FOLIAGE_TOOLBAR: "foliage",
  COLOURING_TOOLBAR: "colouring",
  OUTLINER_TOOLBAR: "outliner",
};
