import { useTheme } from "@emotion/react";
import { Close, Save, SaveAs } from "@mui/icons-material";
import IconBack from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { alertUserUnsavedChanges } from "../../libs/alertUserUnsavedChanges";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { SaveButtonFull } from "../SaveButton";
import AppBarRoot from "./Root";
import SnackbarCustom from "../SnackbarCustom";

function SaveMenu({ onClick, ...props }) {
  /**
   * MUI menu with two options to save. Save as new or save as draft.
   */
  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={true}
      {...props}
    >
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <Save fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <SaveAs fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save as new design</ListItemText>
      </MenuItem>
    </Menu>
  );
}

function AppBarDesignRequest({
  title,
  onDecline,
  onSave,
  onInformation,
  onSaveNew,
  property,
}) {
  // state for snackbar
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const vertical = "top";
  const horizontal = "center";
  const navigate = useNavigateToTop();

  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const onDeclineSuccess = () => {
    runSnackbar("Changes declined");
    setIsSaving(false);
  };

  const onDeclineFailed = (e) => {
    runSnackbar("Failed to decline changes: " + e + ". Please try again.");
    setIsSaving(false);
  };

  const onAcceptSuccess = () => {
    runSnackbar("Changes accepted");
    setIsSaving(false);
  };

  const onAcceptFailed = (e) => {
    runSnackbar("Failed to accept changes: " + e + ". Please try again.");
    setIsSaving(false);
  };

  const handleOnAccept = () => {
    onSave(onAcceptSuccess, onAcceptFailed);
    setIsSaving(true);
  };

  const handleOnDecline = () => {
    onDecline(onDeclineSuccess, onDeclineFailed);
    setIsSaving(true);
  };

  // Check is mobile  size
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

  const acceptMessage = isXsBreakPoint ? "Accept" : "Accept";
  const declineMessage = isXsBreakPoint ? "Decline" : "Decline";
  const titleMessage = isXsBreakPoint ? "" : title;

  useEffect(() => {
    window.addEventListener("beforeunload", alertUserUnsavedChanges);
    return () => {
      window.removeEventListener("beforeunload", alertUserUnsavedChanges);
    };
  }, []);
  return (
    <Fragment>
      <AppBarRoot
        startAction={
          <Stack direction={"row"} spacing={1}>
            <IconButton
              color="secondary"
              onClick={(e) => {
                alertUserUnsavedChanges(e, () => {
                  navigate(-1);
                });
              }}
            >
              <IconBack />
            </IconButton>
            <Typography
              variant="h5"
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "1.1rem",
              }}
            >
              {titleMessage}
            </Typography>
          </Stack>
        }
        endAction={
          <Stack direction={"row"} spacing={1}>
            {/* Accept and decline buttons */}
            <SaveButtonFull
              title={acceptMessage}
              onClick={handleOnAccept}
              saving={isSaving}
            />
            <SaveButtonFull
              title={declineMessage}
              onClick={handleOnDecline}
              saving={isSaving}
              startIcon={<Close />}
            />
          </Stack>
        }
      />
      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}

export default AppBarDesignRequest;
