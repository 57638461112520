import { Grid } from "@mui/material";
import React from "react";
import TitleDescriptionCompareImage from "../components/TitleDescriptionCompareImage";
import TitleDescriptionImageMosaic from "../components/TitleDescriptionImageMosaic";
const BonsaiProfileChanges = ({ originalBonsai, suggestion }) => {
  if (!suggestion) {
    return null;
  }
  const newOutline = suggestion?.outlines?.[0];
  let newStructures = suggestion?.solutions;
  let newFoliages = suggestion?.variations;

  // if newFoliages is empty then set to false
  newFoliages = newFoliages && newFoliages.length > 0 ? newFoliages : false;
  // if structures is empty then set to false
  newStructures =
    newStructures && newStructures.length > 0 ? newStructures : false;

  return (
    <Grid container spacing={2} style={{ width: "100%" }}>
      {newOutline && (
        <Grid item xs={12} sm={12} md={12}>
          <TitleDescriptionCompareImage
            title="We will change the outline"
            description=""
            oldImage={originalBonsai.outlines[0]}
            newImage={newOutline}
            style={{ marginBottom: "1rem" }}
          />
        </Grid>
      )}
      {newStructures && (
        <Grid item xs={12} sm={12} md={12}>
          <TitleDescriptionImageMosaic
            title={`We will add these ${newStructures.length} ${""}structure/s`}
            description=""
            style={{ marginBottom: "1rem" }}
            images={newStructures}
            onClick={(item) => {
              //   onClick("solutions", item);
            }}
          />
        </Grid>
      )}
      {newFoliages && (
        <Grid item xs={12} sm={12} md={12}>
          <TitleDescriptionImageMosaic
            title={`We will add these ${newFoliages.length} ${""}foliage/s`}
            description="These are the variations of your tree. You will consider these and select your final design for the tree."
            style={{ marginBottom: "1rem" }}
            images={newFoliages}
            onClick={(item) => {
              //   onClick("variations", item);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BonsaiProfileChanges;
