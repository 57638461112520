import { pipeMixins } from "../components/elements/imageEditorVisualisation";
import { withColour } from "../mixins/colour";
import { withWidthHeight } from "../mixins/heightWidth";
import { withPosition } from "../mixins/position";
import { withRotation } from "../mixins/rotation";
import { withTransormable } from "../mixins/transformable";
import { withTransformingRectangle } from "../mixins/transformingRectangle";
import { actualMousePosition } from "./actualMousePosition";

export function createTransformativeObject(
  drawMethod,
  x = 0,
  y = 0,
  height = 50,
  width = 100,
  colour = "#964B00"
) {
  const transformImage = pipeMixins(
    withWidthHeight,
    withPosition,
    withRotation,
    withTransormable,
    withTransformingRectangle,
    withColour
  );

  const handleSize = 10;

  transformImage.setWidth(width);
  transformImage.setHeight(height);
  transformImage.setX(x);
  transformImage.setY(y);
  transformImage.setColour(colour);
  transformImage.startTransforming();

  transformImage.draw = function (drawingLibrary, ...args) {
    drawingLibrary.push();
    drawingLibrary.translate(this.x(), this.y());
    drawingLibrary.rotate(this.rotation());

    drawMethod(drawingLibrary, this, ...args);

    // If transforming, draw the transform handles
    // Translate from center to top left
    drawingLibrary.translate(-this.width() / 2, -this.height() / 2);

    if (this.isBeingTransformed()) {
      // Draw the transform handles
      drawingLibrary.stroke(255, 0, 0);
      drawingLibrary.strokeWeight(1);
      drawingLibrary.fill(255, 0, 0, 0);
      drawingLibrary.rect(0, 0, this.width(), this.height());

      drawingLibrary.fill(255, 0, 0, 255);
      drawingLibrary.rect(
        -handleSize * 0.5,
        -handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        this.width() - handleSize * 0.5,
        -handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        -handleSize * 0.5,
        this.height() - handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        this.width() - handleSize * 0.5,
        this.height() - handleSize * 0.5,
        handleSize,
        handleSize
      );
    }
    drawingLibrary.pop();
  };
  transformImage.onMousePressed = function (vis, drawingLibrary) {
    // Get mouse position
    // const mouseX = drawingLibrary.mouseX;
    // const mouseY = drawingLibrary.mouseY;
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);

    const handleHitBoxScale = 3;

    // If the mouse is over the image, image is drawn from center, then start transforming

    if (
      // Clicking the top left handle
      mouseX >
        this.x() - this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() - this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() - this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() - this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("topLeft");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the top right handle
      mouseX >
        this.x() + this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() + this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() - this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() - this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("topRight");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the bottom left handle
      mouseX >
        this.x() - this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() - this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() + this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() + this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("bottomLeft");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the bottom right handle
      mouseX >
        this.x() + this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() + this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() + this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() + this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("bottomRight");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      mouseX > this.x() - this.width() / 2 &&
      mouseX < this.x() + this.width() / 2 &&
      mouseY > this.y() - this.height() / 2 &&
      mouseY < this.y() + this.height() / 2
    ) {
      this.startTransforming();
      this.setDragPointName("center");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      // if (this.isBeingTransformed()) {
      //   this.setDragPointName("center");
      //   // start drag
      //   this.startDragging();
      //   this.setDragStartX(mouseX);
      //   this.setDragStartY(mouseY);
      // } else {
      //   this.startTransforming();
      // }
    } else {
      this.stopTransforming();
      return false;
    }
    return true;
  };

  transformImage.onMouseDragged = function (vis, drawingLibrary) {
    // Get mouse position
    // const mouseX = drawingLibrary.mouseX;
    // const mouseY = drawingLibrary.mouseY;
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);

    if (this.isBeingTransformed()) {
      if (this.isDragging()) {
        // Dragging
        const deltaX = mouseX - this._dragStartX;
        const deltaY = mouseY - this._dragStartY;
        if (this.dragPointName() === "center") {
          this.setX(this.x() + deltaX);
          this.setY(this.y() + deltaY);
          this.setDragStartX(mouseX);
          this.setDragStartY(mouseY);
        } else if (this.dragPointName() === "topLeft") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() - deltaX * 2);
          this.setHeight(this.dragHeightStart() - deltaY * 2);
        } else if (this.dragPointName() === "topRight") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() + deltaX * 2);
          this.setHeight(this.dragHeightStart() - deltaY * 2);
        } else if (this.dragPointName() === "bottomLeft") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() - deltaX * 2);
          this.setHeight(this.dragHeightStart() + deltaY * 2);
        } else if (this.dragPointName() === "bottomRight") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() + deltaX * 2);
          this.setHeight(this.dragHeightStart() + deltaY * 2);
        }
      }
    } else return false;
  };

  transformImage.onMouseReleased = function (vis, drawingLibrary) {
    if (this.isBeingTransformed()) {
      if (this.isDragging()) {
        this.stopDragging();
      }
    }
  };

  return transformImage;
}
