import { Box, Grid } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarSaveDiscard from "../../components/AppBar/SaveDiscard";
import DrawerWithControls from "../../components/elements/DrawerWithControls";
import { getData } from "../../libs/firebase/firestore/getData";
import { decodeHexToURL } from "../../utilities/decodeHexToUrl";
import { saveCanvas } from "../../utilities/saveCanvas";
import { toolbarSettings } from "../../libs/toolbarSettings";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

const ScreenEditOutline = ({ image = { src: null }, getDataLocation }) => {
  // Create a sketchref state variable
  const [sketchRef, setSketchRef] = React.useState(null);

  // State to store an image
  const [foregroundImage, setForegroundImage] = React.useState(null);

  // State to store the selected bonsai bot
  const [selectedBonsaiPot, setSelectedBonsaiPot] = React.useState(null);

  // State to store an image
  const [backgroundImage, setBackgroundImage] = React.useState(image);

  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const { bonsaiId, outlineImage } = useParams();

  let currentUser = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };

  if (!foregroundImage || foregroundImage.src === null) {
    let decodedImage = decodeHexToURL(outlineImage);
    setForegroundImage({ src: decodedImage });
  }
  if (selectedBonsai) {
    // check slseccted bonsai has fronts
    if (selectedBonsai.fronts) {
      // set the first to be the background image
      if (!backgroundImage || backgroundImage.src === null) {
        setBackgroundImage({
          src: selectedBonsai.fronts[0],
        });
      }
    }
  }

  // get the bonsai data
  useEffect(() => {
    /**
     * TODO:
     * If it is normal then get the data from the current user
     * If it is shared then get the data in a two step process
     * 1. Get the initial data from the shared collection
     * 2. Get the original data from the user.
     */

    if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  }, []);

  const handleOnSave = (successCb, failCb) => {
    // saveCanvas(sketchRef, bonsaiId, "outlines");
    saveCanvas(
      sketchRef,
      bonsaiId,
      "outlines",
      {},
      handleDataSaveSuccess.bind(this, successCb),
      failCb
    );
  };
  const handleDataSaveSuccess = (cb) => {
    // Reget the bonsai data
    getData(`users/${currentUser.uid}/bonsai/${bonsaiId}`, (data) => {
      handleGotBonsaiData(data);
      cb();
    });
  };

  const handleOnInformation = () => {
    navigate("/instruction/outlines");
  };

  return (
    <Box>
      <AppBarSaveDiscard
        title="Edit outline"
        label={"outlines"}
        onSave={handleOnSave}
        onInformation={handleOnInformation}
      />
      <Box>
        <Grid style={{ height: "90vh" }} container>
          <DrawerWithControls
            backgroundImage={backgroundImage}
            sketchRef={sketchRef}
            setSketchRef={setSketchRef}
            foregroundImage={foregroundImage}
            selectedBonsaiPot={selectedBonsaiPot}
            toolbars={toolbarSettings.OUTLINE}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ScreenEditOutline;
