// React component that draws a foreground and background image on a p5.js canvas.
// The foreground image is drawn on top of the background image.
import { Box } from "@mui/material";
import React, { useEffect } from "react";

import { createImageEditorVisualisation } from "./imageEditorVisualisation";
import { createImageComparisonVisualisation } from "./visualisation/imageComparison";

const ImageComparison = ({
  leftImage,
  rightImage,
  onCanvasChange,
  style,
  ...props
}) => {
  const p5Container = React.createRef();
  // state to store the visualisation
  const [visualisation, setVisualisation] = React.useState(null);
  // store visualisation as ref
  const visualisationRef = React.useRef(visualisation);
  if (visualisation) {
    // set the background image
    visualisation.setBackgroundImage({ src: leftImage });
    // set the foreground image
    visualisation.setForegroundImage({ src: rightImage });
  }

  useEffect(() => {
    const newVis = createImageComparisonVisualisation(p5Container.current, {
      onCanvasChange,
    });
    setVisualisation(newVis);
    visualisationRef.current = newVis;
    return () => {
      if (visualisationRef.current) {
        visualisationRef.current.destroy();
      }
    };
  }, []);

  return (
    <Box style={style} position="relative">
      <div
        style={{ height: "100%", width: "100%", touchAction: "none" }}
        ref={p5Container}
      />
    </Box>
  );
};

export default ImageComparison;
