// function that takes a list of design elements with x,y and height, width and calculates the panx, pany and scale to fit them all in the viewport

export function designElementsToPanScale(
  designElements,
  canvasWidth,
  canvasHeight
) {
  const padding = 10;
  var minX = 0;
  var minY = 0;
  var maxX = 0;
  var maxY = 0;
  designElements.forEach(function (designElement) {
    // if .x is a function then call it to get the value if not then use the value
    var x =
      typeof designElement.x === "function"
        ? designElement.x()
        : designElement.x;
    var y =
      typeof designElement.y === "function"
        ? designElement.y()
        : designElement.y;
    var width =
      typeof designElement.width === "function"
        ? designElement.width()
        : designElement.width;
    var height =
      typeof designElement.height === "function"
        ? designElement.height()
        : designElement.height;

    // consider x and y are the center not the top left corner
    var halfWidth = width / 2;
    var halfHeight = height / 2;

    // calculate the min and max x and y
    minX = Math.min(minX, x - halfWidth);
    minY = Math.min(minY, y - halfHeight);
    maxX = Math.max(maxX, x + halfWidth);
    maxY = Math.max(maxY, y + halfHeight);
  });

  // add the padding
  minX -= padding;
  minY -= padding;
  maxX += padding;
  maxY += padding;

  // pan to the middle
  var panX = (minX + maxX) / 2;
  var panY = (minY + maxY) / 2;

  // calculate how much have to zoom out to fit the design elements in the viewport
  var scale = Math.min(
    canvasWidth / (maxX - minX),
    canvasHeight / (maxY - minY)
  );
  return {
    panX: panX,
    panY: panY,
    scale: scale,
  };
} // Path: src\libs\designElementsToPanScale.js
