import { Box, Grid } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarMultiSaveDiscard from "../../components/AppBar/MultiSaveDiscard";
import DrawerWithControls from "../../components/elements/DrawerWithControls";
import { getData } from "../../libs/firebase/firestore/getData";
import { toolbarSettings } from "../../libs/toolbarSettings";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { decodeHexToURL } from "../../utilities/decodeHexToUrl";
import { saveCanvas } from "../../utilities/saveCanvas";
import { uuidv4 } from "../../utilities/uuidv4";

const ScreenEditVariation = ({ image = { src: null } }) => {
  // State to store save uuid
  const [saveUuid, setSaveUuid] = React.useState(uuidv4());
  // Create a sketchref state variable
  const [sketchRef, setSketchRef] = React.useState(null);

  // State to store an image
  const [foregroundImage, setForegroundImage] = React.useState(null);

  // State to store the selected bonsai bot
  const [selectedBonsaiPot, setSelectedBonsaiPot] = React.useState(null);

  // State to store an image
  const [backgroundImage, setBackgroundImage] = React.useState(image);

  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const { bonsaiId, variationImage } = useParams();

  let currentUser = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };
  if (!foregroundImage || foregroundImage.src === null) {
    let decodedImage = decodeHexToURL(variationImage);
    setForegroundImage({ src: decodedImage });
  }

  // get the bonsai data
  useEffect(() => {
    if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  }, []);

  const handleOnSave = (successCb, failCb) => {
    saveCanvas(
      sketchRef,
      bonsaiId,
      "variations",
      selectedBonsai,
      handleDataSaveSuccess.bind(this, successCb),
      failCb,
      saveUuid
    );
  };

  const handleOnSaveNew = (successCb, failCb) => {
    // Create a uuid
    const newSaveUuid = uuidv4();
    saveCanvas(
      sketchRef,
      bonsaiId,
      "variations",
      selectedBonsai,
      handleDataSaveSuccess.bind(this, successCb),
      failCb,
      newSaveUuid
    );
    // Set the new uuid
    setSaveUuid(newSaveUuid);
  };
  const handleDataSaveSuccess = (cb) => {
    // Reget the bonsai data
    getData(`users/${currentUser.uid}/bonsai/${bonsaiId}`, (data) => {
      handleGotBonsaiData(data);
      cb();
    });
  };

  const handleOnInformation = () => {
    navigate("/instruction/variations");
  };

  return (
    <Box>
      <AppBarMultiSaveDiscard
        title="Edit foliage"
        property="variations"
        onSave={handleOnSave}
        onSaveNew={handleOnSaveNew}
        onInformation={handleOnInformation}
      />
      <Box>
        <Grid style={{ height: "90vh" }} container>
          <DrawerWithControls
            backgroundImage={backgroundImage}
            sketchRef={sketchRef}
            setSketchRef={setSketchRef}
            foregroundImage={foregroundImage}
            selectedBonsaiPot={selectedBonsaiPot}
            toolbars={toolbarSettings.VARIATION}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ScreenEditVariation;
