// React component using MUI components for email textfield
import { Person } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

export default function TextFieldName({ ...props }) {
  return (
    <TextField
      label="Name"
      type="name"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Person />
          </InputAdornment>
        ),
      }}
      required
      {...props}
    />
  );
}
