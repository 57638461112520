import { List, ListItem, Typography } from "@mui/material";
import InstructionRoot from "./Root";
import HeadlineImage from "../../images/artwork/tree_pencil_simple.jpg";
import { Box } from "@mui/system";

import OutlineImage from "../../images/designs/outline.png";
import BranchBehindAndInFrontImage from "../../images/designs/branchBehindAndInfront.png";
import SolutionImage1 from "../../images/designs/solution (1).png";
import SolutionImage2 from "../../images/designs/solution (2).png";
import SolutionImage3 from "../../images/designs/solution (3).png";
import SolutionImage4 from "../../images/designs/solution (4).png";
import PhotoImage1 from "../../images/designs/photo (1).jpg";
import MultiImageCarousel from "../../components/MultiImageCarousel";
import { InstructionSection } from "../../components/InstructionSection";

export default function ScreenInstructionOutline({ onClose }) {
  return (
    <InstructionRoot
      title="Outlining your tree"
      startTime="26"
      onClose={onClose}
    >
      {/* <Box
        style={{
          height: "10rem",
          width: "100%",
          overflow: "hidden",
          borderRadius: ".5rem",
        }}
      >
        <img
          src={HeadlineImage}
          alt="Tree wrapped in fabric"
          style={{ width: "100%", height: "auto", marginTop: "-4rem" }}
        />
      </Box> */}
      <InstructionSection heading="Why?">
        <Typography gutterBottom>
          Outlining your bonsai tree clarifies its shape and allows you to
          create digital designs. This outline will form the basis for all your
          design work.
        </Typography>
      </InstructionSection>
      <InstructionSection heading="How?">
        <Typography gutterBottom>
          Here are some simple rules to follow:
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Outline the roots, trunk and start of branches, not the foliage.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            2. Only draw the first inch/cm of each branch. We just want an idea
            of where the branches are. We draw the ones we want later in the
            structure step.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            3. If a branch is behind the trunk, close the trunk then draw the
            branch.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            4. Do what works for you. You will only figure out the best way by
            finding what doesn't work.
          </ListItem>
        </List>
      </InstructionSection>
      <InstructionSection heading="What's next?">
        <Typography gutterBottom>
          We will create a couple structure that solve your design problem. We
          will remove branches, create branches, add deadwood, rotate your tree
          and put it in a pot.
        </Typography>
      </InstructionSection>

      <InstructionSection heading="Example of a good outline">
        <Typography gutterBottom>
          Here is my current front of this tree. The background helps me see the
          structure. I should have wrapped cloth around the foliage on the right
          so I could see the structure easier... next time.
        </Typography>
        <Box>
          <img
            src={PhotoImage1}
            alt="Tree wrapped in fabric"
            style={{ width: "100%", height: "auto", borderRadius: "1rem" }}
          />
          <Typography gutterBottom>
            This is my outline. Notice how it is much easier to understand the
            branch positioning, taper and overall aesthetic potential.
          </Typography>
          <img
            src={OutlineImage}
            alt="Tree wrapped in fabric"
            style={{ width: "70%", height: "auto" }}
          />
          <Typography gutterBottom>
            Not that it matters too much, but look at the image below. The
            branch on the left is behind the trunk so I closed the trunk and
            drew the branch. The branch on the right is in front of the trunk.
            Notice also that I only drew the first inches/cm's.
          </Typography>
          <img
            src={BranchBehindAndInFrontImage}
            alt="Tree wrapped in fabric"
            style={{ width: "70%", height: "auto" }}
          />
          <Typography gutterBottom></Typography>
        </Box>
      </InstructionSection>
    </InstructionRoot>
  );
}
