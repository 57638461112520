import { createTheme } from "@mui/material/styles";

export const themeObject = {
  palette: {
    primary: { main: "#E46540" },
    secondary: { main: "#fff" },
  },
  //   backgroundColor: {
  //     primary: { main: "#FEF8F8" },
  //     secondary: { main: "#FFFFFF" },
  //   },
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 600,
  //       md: 960,
  //       lg: 1280,
  //       xl: 1920,
  //     },
  //   },
  //   overrides: {
  //     MuiDivider: {
  //       root: {
  //         backgroundColor: "#FFFFFF",
  //       },
  //     },
  //   },
};

export default createTheme(themeObject);
