import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import React from "react";
import AppBarSaveDiscard from "../../components/AppBar/SaveDiscard";
import UploadImage from "../../components/UploadImage";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { useParams } from "react-router-dom";

const ScreenEditFront = () => {
  const user = getAuth().currentUser;
  // get param
  const { bonsaiId } = useParams();
  const navigate = useNavigateToTop();

  const handleOnClose = () => {
    navigate(-1);
  };
  const handleOnInformation = () => {
    navigate("/instruction/fronts");
  };
  return (
    <Box>
      <AppBarSaveDiscard
        title="Front Image"
        label="fronts"
        onSave={handleOnClose}
        onInformation={handleOnInformation}
      />
      <Box style={{}}>
        <UploadImage
          bonsaiUuid={bonsaiId}
          user={user}
          handleOnClose={handleOnClose}
          title={null}
          actionTitle={"Upload image"}
          property={"fronts"}
        />
      </Box>
    </Box>
  );
};

export default ScreenEditFront;
