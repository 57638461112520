import IconBack from "@mui/icons-material/ArrowBack";
import IconSave from "@mui/icons-material/Save";
import IconInformation from "@mui/icons-material/Info";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBarRoot from "./Root";
import PrimaryIconButton from "../PrimaryIconButton";
import { Add, Save, SaveAs } from "@mui/icons-material";
import { alertUserUnsavedChanges } from "../../libs/alertUserUnsavedChanges";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import SaveButton, { SaveButtonFull } from "../SaveButton";
import Instructions from "../InstructionDialog";
import PrimaryButton from "../PrimaryButton";
import { getAllData } from "../../libs/firebase/firestore/getAllData";
import { getAuth } from "firebase/auth";
import { getDataFirebaseRecursion } from "../../utilities/getAllDataRecursion";
import ImageSelectModal from "../ImageSelectModal";
import { makeBonsaiPublic } from "../../libs/makeBonsaiPublic";
import SnackbarCustom from "../SnackbarCustom";

function SaveMenu({ onClick, ...props }) {
  /**
   * MUI menu with two options to save. Save as new or save as draft.
   */
  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={true}
      {...props}
    >
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <Save fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <SaveAs fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save as new design</ListItemText>
      </MenuItem>
    </Menu>
  );
}

function AppBarCommunity({
  title,
  onSave,
  onInformation,
  onSaveNew,
  onAdd,
  property,
}) {
  // state for snackbar
  const [bonsai, setBonsai] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const saveMenuOpen = Boolean(anchorEl);

  const currentUser = getAuth().currentUser;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const vertical = "top";
  const horizontal = "center";
  const navigate = useNavigateToTop();

  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const saveSuccess = () => {
    runSnackbar("Image saved");
    setIsSaving(false);
  };
  const saveNewSuccess = () => {
    runSnackbar("Image saved as new design");
    setIsSaving(false);
  };
  const saveFailed = (e) => {
    runSnackbar("Failed to save image: " + e + ". Please try again.");
    setIsSaving(false);
  };
  const handleSaveMenuClick = (event) => {
    handleClose();
    const option = event.currentTarget.textContent;
    if (option === "Save") {
      onSave(saveSuccess, saveFailed);
    } else if (option === "Save as new design") {
      onSaveNew(saveNewSuccess, saveFailed);
    }
    setIsSaving(true);
  };

  const handleGotBonsaiData = (data) => {
    // remove any where isSuggestion = true
    data = data.filter((d) => !d.isSuggestion);

    // remove data that doesnt have fronts[0]
    data = data.filter((d) => d.fronts && d.fronts[0]);

    setBonsai(data);
  };

  const handleOnAdd = () => {
    /**
     * TODO:
     *  Get a list of all the owners trees
     *
     *  Show a dialog with a list of all the owners trees (images as fronts) to choose from
     *
     *  When a tree is selected make the tree public and add it to the community page
     *
     *  Let the user know the tree is now public
     */

    getDataFirebaseRecursion(
      currentUser.uid,
      (data) => {
        handleGotBonsaiData(data);
        setModalOpen(true);
      },
      getAllData,
      `bonsai`
    );
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUserUnsavedChanges);
    return () => {
      window.removeEventListener("beforeunload", alertUserUnsavedChanges);
    };
  }, []);

  const handleModalImageClick = (image, index) => {
    const selectedBonsai = bonsai[index];

    // make the bonsai public
    makeBonsaiPublic(currentUser, selectedBonsai, (address) => {
      // close the modal
      setModalOpen(false);
      setTimeout(() => {
        // setLoading(false);

        runSnackbar("Bonsai is now public!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }, 1000);
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // if bonsai then get the front images from each
  const modalImages = bonsai ? bonsai.map((b) => b.fronts[0]) : [];

  return (
    <Fragment>
      <AppBarRoot
        startAction={
          <Stack direction={"row"} spacing={1}>
            <Typography
              variant="h5"
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "1.1rem",
              }}
            >
              Help others design
            </Typography>
          </Stack>
        }
        endAction={
          <Stack direction={"row"} spacing={1}>
            <PrimaryButton startIcon={<Add />} onClick={handleOnAdd}>
              {" "}
              my tree
            </PrimaryButton>
          </Stack>
        }
      />
      {modalOpen && (
        <ImageSelectModal
          title={"Select a bonsai"}
          images={modalImages}
          onClick={handleModalImageClick}
          onClose={handleCloseModal}
          description={
            "This will make the selected bonsai viewable to the community, so they can send you design ideas. This is a good idea especially if you are stuck!"
          }
        />
      )}

      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}

export default AppBarCommunity;
