import React from "react";
import { Box } from "@mui/system";
import AppBarBack from "../../components/AppBar/Back";
import AppBarClose from "../../components/AppBar/Close";
import YoutubeVideo from "../../components/Video";
import { Typography } from "@mui/material";

export default function InstructionRoot({
  title,
  children,
  onClose,
  startTime,
}) {
  return (
    <Box
      style={{
        minHeight: "100vh",
      }}
    >
      <AppBarClose title={title} onClose={onClose} />

      <Box style={{ padding: "1rem" }}>
        <Typography gutterBottom>
          Watch the video below to learn how to use the tool.
        </Typography>
        <YoutubeVideo startTime={startTime} />
        {children}
      </Box>
    </Box>
  );
}
