export const toolbars = {
  OUTLINER: "outliner",
  OUTLINER_NO_IMAGE_TOGGLE: "outliner_no_image_toggle",
  FOLIAGE: "foliage",
  ORIENTATION: "orientation",
  ORIENTATION_NO_POT: "orientation_no_pot",
  COLOURING_NO_POT: "colouring_no_pot",
  COLOURING: "colouring",
};

export function toolbarsToDrawingMode(tools) {
  let drawingMode = [];

  // for each toolbar
  for (let toolbar of tools) {
    // add the drawing mode for that toolbar
    switch (toolbar) {
      case toolbars.OUTLINER:
        drawingMode.push("brush");
        break;
      case toolbars.OUTLINER_NO_IMAGE_TOGGLE:
        drawingMode.push("brush");
        break;
      case toolbars.FOLIAGE:
        drawingMode.push("pan");
        break;
      case toolbars.ORIENTATION:
        drawingMode.push("pan");
        break;
      case toolbars.COLOURING:
        drawingMode.push("brush");
        break;

      case toolbars.ORIENTATION_NO_POT:
        drawingMode.push("pan");
        break;
      case toolbars.COLOURING_NO_POT:
        drawingMode.push("brush");
        break;
      default:
        break;
    }
  }

  return drawingMode;
}

export const toolbarSettings = {
  OUTLINE: [toolbars.OUTLINER],
  SOLUTION: [
    toolbars.OUTLINER_NO_IMAGE_TOGGLE,
    toolbars.COLOURING,
    toolbars.ORIENTATION,
  ],
  SOLUTION_EDIT: [
    toolbars.OUTLINER_NO_IMAGE_TOGGLE,
    toolbars.COLOURING_NO_POT,
    toolbars.ORIENTATION_NO_POT,
  ],
  VARIATION: [toolbars.FOLIAGE],
};

export const isOutline = (tools) => {
  return tools.length === 1 && tools[0] === toolbars.OUTLINER;
};

export const isSolution = (tools) => {
  return (
    tools.length === 3 &&
    tools.includes(toolbars.OUTLINER_NO_IMAGE_TOGGLE) &&
    tools.includes(toolbars.ORIENTATION) &&
    tools.includes(toolbars.COLOURING)
  );
};

export const isVariation = (tools) => {
  return tools.length === 1 && tools[0] === toolbars.FOLIAGE;
};

let toolbarTitles = {};
toolbarTitles[toolbars.OUTLINER] = "Pen and Eraser";
toolbarTitles[toolbars.OUTLINER_NO_IMAGE_TOGGLE] = "Pen and Eraser";
toolbarTitles[toolbars.ORIENTATION] = "Potting";
toolbarTitles[toolbars.COLOURING] = "Colour";
toolbarTitles[toolbars.FOLIAGE] = "Foliage Design";
toolbarTitles[toolbars.ORIENTATION_NO_POT] = "Potting";
toolbarTitles[toolbars.COLOURING_NO_POT] = "Colour";

export default toolbarTitles;
