import { pipeMixins } from "../components/elements/imageEditorVisualisation";
import { drawBonsaiPot } from "../utilities/draw/bonsaiPot";
import { createTransformativeObject } from "../utilities/transformativeDrawing";
import { withColour } from "./colour";
import { withWidthHeight } from "./heightWidth";

export const withBonsaiPot = {
  createBonsaiPot(x, y) {
    const pot = createBonsaiPot();
    pot.setX(x);
    pot.setY(y);
    pot.setMinHeight(20);
    pot.setMinWidth(20);
    this._bonsaiPot = pot;
    return pot;
  },
  bonsaiPot() {
    if (this._bonsaiPot === undefined) {
      this._bonsaiPot = createBonsaiPot();
    }
    return this._bonsaiPot;
  },
  setBonsaiPot(bonsaiPot) {
    this._bonsaiPot = bonsaiPot;
  },
  isBonsaiPotVisible() {
    if (this._isBonsaiPotVisible === undefined) {
      this._isBonsaiPotVisible = true;
    }
    return this._isBonsaiPotVisible;
  },
  toggleBonsaiPotVisibility() {
    this._isBonsaiPotVisible = !this._isBonsaiPotVisible;
  },
  setBonsaiPotVisibility(isVisible) {
    this._isBonsaiPotVisible = isVisible;
  },
};

export function createBonsaiPot(height = 50, width = 100, colour = "#6e5042") {
  const pot = createTransformativeObject(
    drawBonsaiPot,
    0,
    0,
    height,
    width,
    colour
  );
  return pot;
}
