export const appleTreeBonsai = {
  finalDesign:
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F09e90b36-df47-4ec0-bd36-108fc4210e13?alt=media&token=9ea2fab6-1557-4d0f-a249-15f63f95cae6",
  variations: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F254573d3-f8c9-449d-abf4-9d603de9bc1b?alt=media&token=b6538554-b163-4b52-bc34-7734532dcedc",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F09e90b36-df47-4ec0-bd36-108fc4210e13?alt=media&token=9ea2fab6-1557-4d0f-a249-15f63f95cae6",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fabf38dc7-7a3e-45f6-8fb4-63e3ef6e2628?alt=media&token=038b5726-70ee-41e2-b7ad-da60c2e0a857",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F44378744-985a-4fd6-ba32-233b4f07fab0?alt=media&token=2ec7225e-60da-4d3b-a015-52f32cbc7c60",
  ],
  outlines: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F8f007007-9d1b-4df0-87e2-a5e1cf154f2d?alt=media&token=104d789b-2692-44db-bf12-d28db0f080d3",
  ],
  fronts: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F2881a0ee-b894-4a6b-afbe-19e4a298ea33?alt=media&token=f0c161b8-761b-4f62-b6db-295a0c42cf61",
  ],
  solutions: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F5d8d7510-997c-46ad-a7d5-0443d71078d6?alt=media&token=c4ce356b-38da-4222-a8a8-0232fb205eb5",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F930d1a1f-757b-451d-808b-b024e2c7b7fe?alt=media&token=62e5cc33-2f8a-47ba-b612-b7e53f2570bc",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F429244dd-0620-4e26-a3fe-4124730387a7?alt=media&token=1e4387cc-2942-43a9-851c-65dccbcd3fb7",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F6d3d3805-9a84-4cb1-a5e1-d90cf9c5766e?alt=media&token=2690968d-9628-4353-a5c8-1e0c1d6d10fc",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2F254573d3-f8c9-449d-abf4-9d603de9bc1b?alt=media&token=805db4c3-d765-4aef-93e8-1ba1cc8f2e83",
  ],
  storageDocumentId: "6ORwVsJl6U4FRN2ZEBci",
  user: "KkF7tJ40KeR3orKJCusXVLiKNbq2",
};

export const tutorialBonsai = {
  outlines: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Foutline.png?alt=media&token=b5286de6-6233-4ad5-8f53-0108e2379e16",
  ],
  fronts: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fphoto%20(1).jpg?alt=media&token=a8fbbef5-acdf-4086-ab58-7a9dd3b69c1a",
  ],
  finalDesign:
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fvariation%20(1).png?alt=media&token=8cfe0c6f-2910-4aef-9c42-89bb0d792f31",
  variations: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fvariation%20(1).png?alt=media&token=8cfe0c6f-2910-4aef-9c42-89bb0d792f31",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fvariation%20(2).png?alt=media&token=546ab5aa-f979-4d2c-b8e7-fe95d2e4d043",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fvariation%20(3).png?alt=media&token=bb3a62c2-fcba-47ee-8fa0-9ec113241174",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fvariation%20(4).png?alt=media&token=a8d534fe-fccd-4e17-976c-90639592cc86",
  ],
  solutions: [
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fstructure%20(1).png?alt=media&token=38a9ad6b-fb5f-4975-8f31-a5c00b253112",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fstructure%20(2).png?alt=media&token=3502836c-71a9-414a-b0f6-bf72fa7d9641",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fstructure%20(3).png?alt=media&token=d116acf8-e778-4a94-8982-27fae3f2fc32",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fstructure%20(4).png?alt=media&token=d2f0f9cf-7096-4d1d-97e1-d3fae7b24397",
    "https://firebasestorage.googleapis.com/v0/b/thebonsaiprojectonline.appspot.com/o/KkF7tJ40KeR3orKJCusXVLiKNbq2%2Fstructure%20(5).png?alt=media&token=5abcc991-5ccc-4014-b234-85d4967fc601",
  ],
  storageDocumentId: "uJzyGQDnDK1AmkouAIbG",
  user: "KkF7tJ40KeR3orKJCusXVLiKNbq2",
};
