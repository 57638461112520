import { Box, IconButton, Slider, Stack } from "@mui/material";
import React, { useEffect } from "react";

import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";

import { RotateLeft, VisibilityOff } from "@mui/icons-material";
import PanToolIcon from "@mui/icons-material/PanToolOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HeightIcon from "@mui/icons-material/Height";
import WidthIcon from "@mui/icons-material/SettingsEthernet";
import TransformIcon from "@mui/icons-material/Transform";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LayersIcon from "@mui/icons-material/Layers";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import ChangeHistoryTwoToneIcon from "@mui/icons-material/ChangeHistoryTwoTone";
import IconButtonWithLabel from "../IconButtonWithLabel";

const ToolbarFoliage = ({
  toolChanged,
  handlePotToggle,
  handleAddFoliage,
  handleNextFoliage,
  handleDeleteFoliage,
  handleNextFoliageVariant,
  handleFoliageTriangleToggle,
  header,
  potHeightChanged,
  potWidthChanged,
}) => {
  // state for tool
  const [tool, setTool] = React.useState("pan");
  const [showImage, setShowImage] = React.useState(false);

  const [potHeight, setPotHeight] = React.useState(25);
  const [potWidth, setPotWidth] = React.useState(100);

  const handlePanTool = () => {
    toolChanged("pan");
    setTool("pan");
  };

  const handleRotateTool = () => {
    toolChanged("rotate");
    setTool("rotate");
  };

  const handleToggleFoliageTriangle = () => {
    // TODO
    setShowImage(!showImage);
    handleFoliageTriangleToggle();
  };

  const handleDeleteSelectedFoliage = () => {
    handleDeleteFoliage();
  };

  // CHeck if using a phone
  const isPhone = window.innerWidth < 600;

  const selectedToolTopStyle = {
    borderRadius: ".875rem 0 0 .875rem",
    backgroundColor: green[400],
  };
  const selectedToolBottomStyle = {
    borderRadius: "0 0 0 0",
    backgroundColor: green[400],
  };

  useEffect(() => {
    // Set tool to pan
    toolChanged("pan");
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        pointerEvents: "none",
        padding: "24px",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {header}
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          pointerEvents: "auto",
          background: "rgb(255,255,255)",
          boxSizing: "border-box",
          boxShadow:
            "0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017",
          borderRadius: "14px",
          position: "relative",
          WebkitUserSelect: "none",
          alignSelf: "center",
        }}
      >
        <Box
          style={{
            position: "relative",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"}>
            <Divider orientation="horizontal" flexItem />
            <Box onClick={handleAddFoliage}>
              <IconButtonWithLabel label="Add" icon={<AddIcon />} />
            </Box>

            <Box onClick={handleNextFoliage}>
              <IconButtonWithLabel label="Change" icon={<SwapHorizIcon />} />
            </Box>
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "resizePot" ? selectedToolTopStyle : {}}
              onClick={handleNextFoliageVariant}
            >
              <IconButtonWithLabel label="Closeness" icon={<LayersIcon />} />
            </Box>
            <Divider orientation="vertical" flexItem />

            <Box onClick={handleToggleFoliageTriangle}>
              <IconButtonWithLabel
                label="Guide"
                icon={
                  showImage ? (
                    <ChangeHistoryIcon />
                  ) : (
                    <ChangeHistoryTwoToneIcon />
                  )
                }
              />
            </Box>

            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "resizePot" ? selectedToolTopStyle : {}}
              onClick={handleDeleteSelectedFoliage}
            >
              <IconButtonWithLabel label="Delete" icon={<DeleteIcon />} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ToolbarFoliage;
