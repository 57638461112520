// React router that changes between home, workbook and createbonsai
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import BonsaiMasonryDesignList from "../components/BonsaiMasonryDesignList";
import { bonsai } from "../libs/fakeDesignData";
import ScreenBonsaiProfile from "./BonsaiProfile";
import ScreenCreateBonsai from "./CreateBonsai";
import { ScreenDashboard } from "./Dashboard";
import ScreenLogin from "./Login";
import ScreenSignUp from "./SignUp";
import { ScreenDesignFronts } from "./design/Fronts";
import { ScreenDesignOutlines } from "./design/Outlines";
import { ScreenDesignSolutions } from "./design/Solutions";
import { ScreenDesignVariations } from "./design/Variations";
import ScreenCreateOutline from "./create/Outline";
import ScreenCreateFront from "./create/Front";
import ScreenInstructionFront from "./instruction/Front";
import ScreenInstructionOutline from "./instruction/Oultine";
import ScreenInstructionSolution from "./instruction/Solution";
import ScreenCreateSolution from "./create/Solution";
import ScreenEditSolution from "./edit/Solution";
import ScreenEditVariation from "./edit/Variation";
import ScreenCreateVariation from "./create/Variation";
import ScreenEditOutline from "./edit/Outline";
import ScreenCompare from "./Compare";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";
import ScreenBonsaiTutorial from "./BonsaiTutorial";
import ScreenEditFront from "./edit/Front";
import { ScreenCommunityDashboard } from "./CommunityDashboard";
import ScreenBonsaiSuggestionProfile from "./BonsaiSuggestionProfile";
import ScreenDesignRequest from "./DesignRequest";
import { ScreenDesignRequests } from "./design/Requests";
import { ScreenAccount } from "./Account";

let fakeData = [];

const rememberEditMade = () => {
  localStorage.setItem("bonsai-workbook_edited", true);
};

function ScreenRoot() {
  //stat for the data
  const [data, setData] = React.useState(bonsai);

  // state to hole what bonsai is selected
  const [selectedBonsai, setSelectedBonsai] = React.useState(data[0]);
  // state to hold what tab is selected
  const [selectedTab, setSelectedTab] = React.useState("progress");

  useEffect(() => {
    localStorage.setItem("bonsai-workbook_edited", false);
  }, []);
  const navigate = useNavigateToTop();

  const handleOpenBonsaiProfile = (bonsai, userId, currentUser) => {
    if (
      currentUser.uid != tutorialBonsai.user &&
      bonsai.storageDocumentId === tutorialBonsai.storageDocumentId &&
      !bonsai.isSuggestion
    ) {
      // navigate(`/bonsai/tutorial`);
      navigate(`/${bonsai.user}/bonsai/${bonsai.storageDocumentId}`);
    } else {
      navigate(`/bonsai/${bonsai.storageDocumentId}`);
    }
  };

  const handleOnRequestClick = (requestId) => {
    navigate(`/bonsai/designRequest/${requestId}`);
  };

  const handleOpenBonsaiDesignSuggestion = (bonsaiId, userId) => {
    navigate(`/designs/${bonsaiId}`);
  };

  const handleOpenBonsaiProfileOtherUser = (bonsai, userId) => {
    navigate(`/${userId}/bonsai/${bonsai.storageDocumentId}`);
  };

  const handleLoginSuccess = () => {
    navigate("/");
  };

  const handleCreateBonsai = () => {
    navigate("/bonsai/create");
  };
  const handleSignUpSuccess = () => {
    navigate("/");
  };
  let currentUser = getAuth().currentUser;

  if (!currentUser) {
    // navigate to login
    setTimeout(() => {
      currentUser = getAuth().currentUser;
      if (!currentUser) {
        // check if the route contains signup
        // if it does not contain signup then navigate to login
        // if it does contain signup then do nothing
        if (
          window.location.href.indexOf("signup") === -1 &&
          window.location.href.indexOf("login") === -1
        ) {
          navigate("/login");
        }
      } else {
        // navigate("/");
      }
    }, 1000);
  }

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <ScreenDashboard
              onCreate={handleCreateBonsai}
              data={data}
              onClick={handleOpenBonsaiProfile}
              onSuggestionClick={handleOpenBonsaiDesignSuggestion}
              onRequestClick={handleOnRequestClick}
            />
          }
        />
        <Route
          path="login"
          element={<ScreenLogin onLoginSuccess={handleLoginSuccess} />}
        />
        <Route
          path="signUp"
          element={<ScreenSignUp onSignUpSuccess={handleSignUpSuccess} />}
        />
        {/* <Route path="instruction">
          <Route path="fronts" element={<ScreenInstructionFront />} />
          <Route path="outlines" element={<ScreenInstructionOutline />} />
          <Route path="solutions" element={<ScreenInstructionSolution />} />
        </Route> */}
        <Route path=":userId">
          <Route
            index
            element={
              <ScreenDashboard onClick={handleOpenBonsaiProfileOtherUser} />
            }
          />
          <Route path="bonsai">
            <Route path="designRequest">
              <Route path=":requestId" element={<ScreenDesignRequest />} />
            </Route>
            <Route path=":bonsaiId">
              <Route index element={<ScreenBonsaiProfile />} />
              <Route path="variations" element={<ScreenDesignVariations />} />
              <Route path="fronts" element={<ScreenDesignFronts />} />
              <Route path="outlines" element={<ScreenDesignOutlines />} />
              <Route path="solutions" element={<ScreenDesignSolutions />} />
            </Route>
          </Route>
        </Route>
        <Route path="account" element={<ScreenAccount />}></Route>
        <Route path="community">
          <Route
            index
            element={
              <ScreenCommunityDashboard
                onCreate={handleCreateBonsai}
                data={data}
                onClick={handleOpenBonsaiProfile}
              />
            }
          />
        </Route>
        <Route path="designs">
          <Route path=":bonsaiId">
            <Route index element={<ScreenBonsaiSuggestionProfile />} />
            <Route path="profile" element={<ScreenBonsaiSuggestionProfile />} />
            <Route
              path="variations"
              element={<ScreenDesignVariations root={"designs"} />}
            />
            <Route
              path="fronts"
              element={<ScreenDesignFronts root={"designs"} />}
            />
            <Route
              path="outlines"
              element={<ScreenDesignOutlines root={"designs"} />}
            />
            <Route
              path="solutions"
              element={<ScreenDesignSolutions root={"designs"} />}
            />
            <Route path="edit">
              <Route path="fronts" element={<ScreenEditFront />} />
              <Route
                path="outlines/:outlineImage"
                element={<ScreenEditOutline />}
              />
              <Route
                path="solutions/:solutionImage"
                element={<ScreenEditSolution />}
              />
              <Route
                path="variations/:variationImage"
                element={<ScreenEditVariation />}
              />
            </Route>
            <Route path="create">
              <Route
                path="outlines/:frontImage"
                element={<ScreenCreateOutline />}
              />
              <Route
                path="solutions/:outlineImage"
                element={<ScreenCreateSolution />}
              />
              <Route
                path="variations/:solutionImage"
                element={<ScreenCreateVariation />}
              />
              <Route path="fronts" element={<ScreenCreateFront />} />
            </Route>
          </Route>
        </Route>

        <Route path="bonsai">
          <Route path="designRequest">
            <Route path=":requestId" element={<ScreenDesignRequest />} />
          </Route>
          <Route path="tutorial" element={<ScreenBonsaiTutorial />} />
          <Route path="create" element={<ScreenCreateBonsai />} />
          <Route path=":bonsaiId">
            <Route index element={<ScreenBonsaiProfile />} />
            <Route path="designRequests" element={<ScreenDesignRequests />} />
            <Route path="profile" element={<ScreenBonsaiProfile />} />
            <Route path="variations" element={<ScreenDesignVariations />} />
            <Route path="fronts" element={<ScreenDesignFronts />} />
            <Route path="outlines" element={<ScreenDesignOutlines />} />
            <Route path="solutions" element={<ScreenDesignSolutions />} />
            <Route path="compare" element={<ScreenCompare />} />
            <Route path="edit">
              <Route path="fronts" element={<ScreenEditFront />} />
              <Route
                path="outlines/:outlineImage"
                element={<ScreenEditOutline />}
              />
              <Route
                path="solutions/:solutionImage"
                element={<ScreenEditSolution />}
              />
              <Route
                path="variations/:variationImage"
                element={<ScreenEditVariation />}
              />
            </Route>
            <Route path="create">
              <Route
                path="outlines/:frontImage"
                element={<ScreenCreateOutline />}
              />
              <Route
                path="solutions/:outlineImage"
                element={<ScreenCreateSolution />}
              />
              <Route
                path="variations/:solutionImage"
                element={<ScreenCreateVariation />}
              />
              <Route path="fronts" element={<ScreenCreateFront />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default ScreenRoot;
