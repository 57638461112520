export const withTransormable = {
  isBeingTransformed() {
    return this._transforming;
  },
  startTransforming() {
    this._transforming = true;
  },
  stopTransforming() {
    this._transforming = false;
  },
};
