import InstructionRoot from "./Root";

import { InstructionSection } from "../../components/InstructionSection";

export default function ScreenInstructionDashboard({ onClose }) {
  return (
    <InstructionRoot
      title="Bonsai Designer"
      onClose={onClose}
      startTime="0"
    ></InstructionRoot>
  );
}
