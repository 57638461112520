import { List, ListItem, Typography } from "@mui/material";
import { InstructionSection } from "../../components/InstructionSection";
import InstructionRoot from "./Root";

export default function ScreenInstructionDesign({ onClose }) {
  return (
    <InstructionRoot title="The design process" startTime="0" onClose={onClose}>
      <InstructionSection heading="Why?">
        <Typography>
          If we want to get better at design then we should practice doing it.
          This tool helps the process and wants to encourage trying things and
          failing, but getting better in the process.
        </Typography>
      </InstructionSection>

      <InstructionSection heading="How?">
        <Typography>
          The process people use to design can vary, however the steps are
          usually the same. This tool will guide you through five steps that are
          saved online and can be revisited later.
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Take a photo - The front of your tree
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            2. Outline the tree - Draw the roots, trunk and branches
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            3. Develop ideas for the structure - Edit and expand on the outline
            to create lots of possible designs
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            4. Develop ideas for the foliage - Try different foliage layouts on
            the structures
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            5. Choose your favourite - Save your favourite design and revisit
            later
          </ListItem>
        </List>
      </InstructionSection>

      <InstructionSection heading="How to use this tool?">
        <Typography>
          Here are some tips I have learnt from my own experience using this
          tool.
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Don't worry if your design isn't perfect, it won't be the first
            time - A lot of the power of this tool is in the ability to revisit
            your designs and improve them over time.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            2. Try to create lots of designs - The more designs you create the
            more you will learn about design and have to choose from.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            3. Action over inaction - Don't get hung up on not having the
            perfect front photo or perfect something else. Try to use/do it and
            you will figure out what you need next time.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            4. Use it how it works for you - I don't know the best way. This was
            a stab. I am hoping to learn from everyone who uses it and improve
            it over time. So feel free to give me all the bad news.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            5. Create something better than last time - If you put in some
            design effort and the tree looks better than before. That is all we
            can ask for.
          </ListItem>
        </List>
      </InstructionSection>
      <InstructionSection heading="What's next?">
        <Typography>
          We will prepare your tree and take a photo of the front.
        </Typography>
      </InstructionSection>
    </InstructionRoot>
  );
}
