import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import AppBarRoot from "./Root";
import IconAdd from "@mui/icons-material/Add";
import PrimaryButton from "../PrimaryButton";
import Instructions from "../InstructionDialog";

function AppBarDashboard({ onCreate, isOwner }) {
  const title = isOwner ? "My Designs" : "Designs";
  const endAction = onCreate ? (
    <PrimaryButton startIcon={<IconAdd />} onClick={onCreate}>
      {" "}
      Bonsai
    </PrimaryButton>
  ) : null;
  return (
    <AppBarRoot
      startAction={
        <Typography
          variant="h5"
          style={{ alignSelf: "center", color: "white" }}
        >
          {title}
        </Typography>
      }
      endAction={
        <Stack direction={"row"} spacing={1}>
          <Instructions label={"dashboard"} />
          {endAction}
        </Stack>
      }
    />
  );
}

export default AppBarDashboard;
