import IconBack from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import Instructions from "../InstructionDialog";
import AppBarRoot from "./Root";
import PrimaryButton from "../PrimaryButton";

function AppBarOthersProfile({
  title,
  label,
  onCompare,
  onBack,
  onAdviceRequest,
}) {
  const navigate = useNavigateToTop();

  title = "I need advice";

  // const handleOnCompare = () => {
  //   navigate("/compare/" + label);
  // };
  return (
    <AppBarRoot
      startAction={
        <Stack direction={"row"} spacing={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconBack />
          </IconButton>
          <Typography
            variant="h5"
            style={{ alignSelf: "center", color: "white" }}
          >
            {title}
          </Typography>
        </Stack>
      }
      endAction={
        <Stack direction={"row"} spacing={1}>
          <PrimaryButton onClick={onAdviceRequest}>I'll help</PrimaryButton>
          {/* <Instructions label={label} /> */}
        </Stack>
      }
    />
  );
}

export default AppBarOthersProfile;
