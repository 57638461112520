import FoliageImage2Back from "../../images/foliage/foliage2_bg.png";

export function drawBonsaiPot(drawingLibrary, bonsaiPot, foreground = true) {
  if (!drawingLibrary.isLoadingFoliageImage2Back) {
    drawingLibrary.isLoadingFoliageImage2Back = true;

    drawingLibrary.loadImage(FoliageImage2Back, (img) => {
      drawingLibrary.foliageImage2Back = img;
    });
  }

  let x = -bonsaiPot.width() / 2;
  let y = -bonsaiPot.height() / 2;
  // Heights
  const lipHeight = bonsaiPot.height() * 0.15;
  const feetHeight = bonsaiPot.height() * 0.1;
  const middleHeight = bonsaiPot.height() - lipHeight - feetHeight;

  // Widths
  const lipHang = bonsaiPot.width() * 0.05;
  const lipWidth = bonsaiPot.width();
  const feetWidth = bonsaiPot.width() * 0.15;
  const middleWidth = lipWidth - lipHang * 2;
  if (foreground) {
    // + bonsaiPot.height() * 0.1
    // Stroke slightly darker than fill
    // drawingLibrary.stroke(bonsaiPot.colour().darker(0.5));
    // drawingLibrary.noStroke();
    drawingLibrary.stroke("#583c2f");
    // set the fill colour to pot colour
    drawingLibrary.fill(bonsaiPot.colour());

    // x is center of pot
    // draw a rectangle at x and y the height and width of the bonsai pot
    drawingLibrary.rect(x + lipHang, y + lipHeight, middleWidth, middleHeight);

    // Draw a slightly larger and curved rectangle at the top of the pot
    drawingLibrary.rect(x, y, lipWidth, lipHeight, bonsaiPot.width() * 0.1);

    // draw two small rectangles ad the bottom corners of the pot
    drawingLibrary.rect(
      x + lipHang,
      y + lipHeight + middleHeight,
      feetWidth,
      feetHeight
    );
    drawingLibrary.rect(
      x + lipHang + middleWidth - feetWidth,
      y + lipHeight + middleHeight,
      feetWidth,
      feetHeight
    );
  } else {
    // draw the foliageImage2Back just above the pot
    if (drawingLibrary.foliageImage2Back) {
      drawingLibrary.image(
        drawingLibrary.foliageImage2Back,
        x + bonsaiPot.width() * 0.45,
        y - bonsaiPot.height() * 0.05,
        bonsaiPot.width(),
        bonsaiPot.height() * 0.7
      );
    }
  }
}
