import { Share } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Snackbar } from "@mui/material";
import React, { Fragment } from "react";
import SnackbarCustom from "../SnackbarCustom";

export default function LoadingButtonCopyLinkBonsai({}) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const vertical = "top";
  const horizontal = "center";
  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const handleShare = () => {
    setLoading(true);

    // get the current window address
    let link = window.location.href;
    navigator.clipboard.writeText(link);

    // Set loading to false afer atleast 1 second
    setTimeout(() => {
      setLoading(false);

      runSnackbar("Copied link");
    }, 1000);
  };
  return (
    <Fragment>
      <LoadingButton
        startIcon={<Share />}
        loading={loading}
        onClick={handleShare}
        variant="outlined"
      >
        Copy link to bonsai
      </LoadingButton>
      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}
