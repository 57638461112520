// using p5js, replace a colour in an image with another colour
// takes in a p5js image object, a colour to replace, and a colour to replace it with

import { colorMatch } from "./colourMatch";
import { hexToRgba } from "./hexToRgba";

// returns a new image object with the replaced colour
export function replaceColourInImage(
  img,
  colourToReplaceHex,
  colourToReplaceWithHex
) {
  let colourToReplace = hexToRgba(colourToReplaceHex);
  let colourToReplaceWith = hexToRgba(colourToReplaceWithHex);
  //   let img = img.get();
  img.loadPixels();
  for (let i = 0; i < img.pixels.length; i += 4) {
    let r = img.pixels[i];
    let g = img.pixels[i + 1];
    let b = img.pixels[i + 2];
    let a = img.pixels[i + 3];
    if (colorMatch({ r, g, b, a }, colourToReplace, 100)) {
      img.pixels[i] = colourToReplaceWith.r;
      img.pixels[i + 1] = colourToReplaceWith.g;
      img.pixels[i + 2] = colourToReplaceWith.b;
      img.pixels[i + 3] = colourToReplaceWith.a;
    }
  }
  img.updatePixels();
  return img;
}

// replace everything that isn't the colour with the new colour
export function replaceEverythingButColourInImage(
  img,
  colourToReplace,
  colourToReplaceWithHex,
  tolerance = 100
) {
  let colourToReplaceWith = colourToReplaceWithHex;
  if (typeof colourToReplaceWithHex === "string") {
    colourToReplaceWith = hexToRgba(colourToReplaceWithHex);
  }
  //   let img = img.get();
  img.loadPixels();
  for (let i = 0; i < img.pixels.length; i += 4) {
    let r = img.pixels[i];
    let g = img.pixels[i + 1];
    let b = img.pixels[i + 2];
    let a = img.pixels[i + 3];
    if (!colorMatch({ r, g, b, a }, colourToReplace, tolerance)) {
      img.pixels[i] = colourToReplaceWith.r;
      img.pixels[i + 1] = colourToReplaceWith.g;
      img.pixels[i + 2] = colourToReplaceWith.b;
      img.pixels[i + 3] = colourToReplaceWith.a;
    }
  }
  img.updatePixels();
  return img;
}
