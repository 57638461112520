import ImageListItem from "@mui/material/ImageListItem";
import * as React from "react";
export function ElevatedImage({ src, onClick, style }) {
  return (
    <ImageListItem
      onClick={onClick}
      style={{
        height: "auto",
        width: "auto",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
        border: "1px solid #ccc",
        ...style,
      }}
    >
      <img
        src={`${src}?w=248&fit=crop&auto=format`}
        // srcSet={`${src}?w=248&fit=crop&auto=format&dpr=2 2x`}
        loading="lazy"
        style={{
          borderRadius: "10px",
        }}
      />
    </ImageListItem>
  );
}
