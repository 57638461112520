import { collection, getDocs, getFirestore } from "firebase/firestore";

export async function getAllData(dataName, successCb = null, failCb = null) {
  const db = getFirestore();
  const querySnapshot = await getDocs(collection(db, dataName));
  return new Promise((resolve, reject) => {
    let allDocs = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.storageDocumentId = doc.id;
      return allDocs.push(data);
    });
    if (successCb) {
      successCb(allDocs);
    }
    resolve(allDocs);
  });
}
