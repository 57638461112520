export function rotationAboutAPoint(
  currentRotation,
  x,
  y,
  prevX,
  prevY,
  centerOfRotationX,
  centerOfRotationY,
  scale
) {
  // get thie difference in the y
  let yDiff = y - prevY;

  // get the difference in the x
  let xDiff = x - prevX;

  // get the biggest difference
  let biggestDiff = Math.abs(yDiff) > Math.abs(xDiff) ? yDiff : xDiff;

  let rotateDirection = 1;

  // if y is the biggest difference then find if on the left or right
  if (Math.abs(yDiff) > Math.abs(xDiff)) {
    // if on the left of center of rotation then rotate clockwise
    if (x < centerOfRotationX) {
      rotateDirection = -1;
    } else {
      rotateDirection = 1;
    }
  } else {
    // if on the top of center of rotation then rotate clockwise
    if (y < centerOfRotationY) {
      rotateDirection = 1;
    } else {
      rotateDirection = -1;
    }
  }

  return currentRotation + biggestDiff * rotateDirection * scale;
}
