import { Box } from "@mui/system";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import BottomBarRoot from "../components/BottomBar/Root";
import { getData } from "../libs/firebase/firestore/getData";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { Avatar, Grid, Skeleton, Stack, Typography } from "@mui/material";
import TitleDescriptionImageMosaic from "../components/TitleDescriptionImageMosaic";
import TitleDescriptionImageDashboard from "../components/TitleDescriptionImageDashboard";
import AppBarAccount from "../components/AppBar/Account";
import { stringAvatar } from "../utilities/stringToAvatar";
import TitleDescriptionAction from "../components/TitleDescription/Action";
import { getAllData } from "../libs/firebase/firestore/getAllData";

function dataToDashboardList(data) {
  /**
   * Returns data as an array of objects with the following properties:
   * image: string
   * title: string
   * subtitle: string
   * badge: string
   *
   */

  let dataToDisplay = data.map((x) => {
    if (!x.fronts || !x.fronts[0]) {
      return null;
    }
    let badge = null;
    let title = x.status || "Not sent";
    let subtitle = "";

    let data = {
      image: x.fronts[0],
      title: title,
      subtitle: subtitle,
      badge: badge,
    };
    return data;
  });

  // remove all null values
  dataToDisplay = dataToDisplay.filter((x) => x);

  return dataToDisplay;
}

function acceptedCount(suggestions) {
  if (!suggestions) {
    return null;
  }

  let accepted = suggestions.filter((x) => x.status === "accepted");
  let acceptedCount = accepted.length;
  if (acceptedCount === 0) {
    return null;
  }

  let person = acceptedCount === 1 ? "person" : "people";

  return (
    <Typography variant="subtitle2">
      You've helped {acceptedCount} {person} with their designs!
    </Typography>
  );

  return accepted.length;
}

function user(currentUserInfo) {
  let userName = currentUserInfo ? currentUserInfo.name : null;
  if (userName) {
    return (
      <Stack direction={"row"} spacing={2}>
        <Avatar {...stringAvatar(userName)} />
        <Typography variant="h5" alignSelf="center">
          {userName}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack direction={"row"} spacing={2}>
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={200} />
    </Stack>
  );
}

function designsForMe(requests, onClick) {
  if (requests === null) {
    return null;
  }
  if (requests.length) {
    return (
      <TitleDescriptionImageDashboard
        title={"Designs for me"}
        data={dataToDashboardList(requests)}
        onClick={onClick}
      />
    );
  }
  return <Skeleton variant="rounded" width={"100%"} height={300} />;
}

export function ScreenAccount({}) {
  const [data, setData] = React.useState([]);
  const [currentUserInfo, setCurrentUserInfo] = React.useState(null);
  const [requests, setRequests] = React.useState(null);

  const { userId } = useParams();
  const navigate = useNavigateToTop();

  let currentUser = getAuth().currentUser;

  const handleClick = (image, index) => {
    let request = data[index];

    navigate(
      `/${request.ownerId || request.ownerID}/bonsai/${
        request.bonsaiId || request.bonsaiID
      }`
    );
  };

  const getCurrentUserInfo = () => {
    getData(
      `/users/${getAuth().currentUser.uid}`,
      (data) => {
        setCurrentUserInfo(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleGotRequestsData = (data) => {
    if (data && data.length) {
      setRequests(data);
    }

    // getCurrentUserInfo();
  };

  const handleGotSuggestionsData = (data) => {
    setData(data);

    getAllData(
      `users/${getAuth().currentUser.uid}/designRequests/`,
      handleGotRequestsData,
      (err) => {
        console.log(err);
      }
    );
    getCurrentUserInfo();
  };

  useEffect(() => {
    if (!currentUser) {
      // reload element in 1 second
      setTimeout(() => {
        getAllData(
          `users/${getAuth().currentUser.uid}/designSuggestions/`,
          handleGotSuggestionsData,
          (err) => {
            console.log(err);
          }
        );
      }, 1000);
    } else {
      getAllData(
        `users/${currentUser.uid}/designSuggestions/`,
        handleGotSuggestionsData,
        (err) => {
          console.log(err);
        }
      );
    }
  }, []);

  const handleOnSignOut = () => {
    getAuth()
      .signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDesignForMeClick = (data, index) => {
    let bonsaiId = requests[index].bonsaiId;
    let requestId = requests[index].storageDocumentId;
    // return;
    navigate(`/bonsai/designRequest/${requestId}`);
  };

  const handleDesignForOthersClick = (snippet, index) => {
    let bonsaiId = data[index].bonsaiId;
    navigate(`/bonsai/${bonsaiId}`);
  };

  return (
    <Box style={{ overflow: "hidden", paddingBottom: "4em" }}>
      <AppBarAccount />
      <Box
        style={{
          padding: "1rem",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{ overflowX: "hidden", width: "100%" }}
        >
          <Grid item xs={12}>
            {user(currentUserInfo)}
            {acceptedCount(data)}
          </Grid>
          {/* <Grid item xs={12}>
            <TitleDescriptionImageDashboard
              title={"Designs for others"}
              data={dataToDashboardList(data)}
              onClick={handleDesignForOthersClick}
            />
          </Grid> */}

          <Grid item xs={12}>
            {designsForMe(requests, handleDesignForMeClick)}
          </Grid>
          <Grid item xs={12}>
            <TitleDescriptionAction
              actionText="Sign Out"
              onClick={handleOnSignOut}
            />
          </Grid>
        </Grid>
      </Box>

      <BottomBarRoot startValue={2} />
    </Box>
  );
}
