import { isOwnerOfUrl } from "./isOwnerOfUrl";

export function isOwnerOfBonsai(bonsai, currentUser) {
  if (!bonsai || !currentUser) {
    return false;
  }

  if (bonsai.isSuggestion) {
    return false;
  }

  if (!isOwnerOfUrl(bonsai.fronts[0], currentUser.uid)) {
    return false;
  }

  return true;
}
