// React component that draws a foreground and background image on a p5.js canvas.
// The foreground image is drawn on top of the background image.
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { createImageEditorVisualisation } from "./imageEditorVisualisation";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import toolbarTitles, {
  toolbarsToDrawingMode,
} from "../../libs/toolbarSettings";
import ToolbarColouring from "../toolbar/Colouring";
import ToolbarFoliage from "../toolbar/Foliage";
import ToolbarOrientator from "../toolbar/Orientator";
import ToolbarOutliner from "../toolbar/Outliner";

// function that draws a horzontal line on a p5.js canvas at y-coordinate y.
function drawHorizontalLine(p, y, x1, x2) {
  p.line(x1, y, x2, y);
}

// function that draws a vertical line on a p5.js canvas at x-coordinate x.
function drawVerticalLine(p, x, y1, y2) {
  // p.line(x, y1, x, y2);
  // draw a rectangle as the line
  p.stroke(255);
  p.fill(0);
  p.strokeWeight(0.5);
  p.rect(x - 2, y1, 3, y2 - y1);
}

// Draws a horizontal line with a tick on either end and a label on the middle.
function drawHorizontalMeasurement(p, y, x1, x2, label) {
  drawHorizontalLine(p, y, x1, x2);
  // Drive the tick mark outwards from the line.
  const tickLength = 20;
  const tickOffset = 0;
  const labelOffset = 20;
  // drawVerticalLine(p, x1 + tickOffset, y - tickLength, y + tickLength);
  // drawVerticalLine(p, x2 - tickOffset, y - tickLength, y + tickLength);
  p.stroke(255);
  p.fill(0);
  p.strokeWeight(2);
  p.text(label, (x1 + x2) / 2, y + labelOffset);
}

// Draws a vertical line with a tick on either end and a label on the middle.
function drawVerticalMeasurement(p, x, y1, y2, label) {
  drawVerticalLine(p, x, y1, y2);
  // Drive the tick mark outwards from the line.
  const tickLength = 20;
  const tickOffset = 0;
  const labelOffset = 40;
  drawHorizontalLine(p, y1 + tickOffset, x - tickLength, x + tickLength);
  drawHorizontalLine(p, y2 - tickOffset, x - tickLength, x + tickLength);
  p.text(label, x + labelOffset, (y1 + y2) / 2);
}

function drawImageScaledAtPosition(p5, image, x, y, scale) {
  // Scale image to fit in the canvas
  let xPos = p5.width / 2 + (x * p5.width) / 2;
  let yPos = p5.height / 2 + (y * p5.height) / 2;

  // get scaled image width and height
  let scaledWidth = image.width * scale;
  let scaledHeight = image.height * scale;

  p5.image(image, xPos, yPos, scaledWidth, scaledHeight);
}

const ImageEditor = ({
  backgroundImage,
  foregroundImage,
  backgroundImageScale,
  backgroundImagePosition,
  onCanvasChange,
  brushSize,
  primaryColor,
  style,
  toolbars,
  ...props
}) => {
  const p5Container = React.createRef();
  // state to store the visualisation
  const [visualisation, setVisualisation] = React.useState(null);
  const [infoAlert, setInfoAlert] = React.useState(null);
  const [toolbar, setToolbar] = React.useState(toolbars[0]);
  // store visualisation as ref
  const visualisationRef = React.useRef(visualisation);

  const getPrimaryColor = () => {
    return primaryColor;
  };

  const handleOnInfoAlert = (message) => {
    setInfoAlert(message);
  };

  if (visualisation) {
    // set the background image
    visualisation.setBackgroundImage(backgroundImage);
    // set the foreground image
    visualisation.setForegroundImage(foregroundImage);
  }

  useEffect(() => {
    let newVis = createImageEditorVisualisation(p5Container.current, {
      backgroundImage,
      backgroundImageScale,
      backgroundImagePosition,
      foregroundImage,
      onCanvasChange,
      onInfoAlert: handleOnInfoAlert,
      toolbars,
      // getBrushSize,
      // getPrimaryColor
    });
    setVisualisation(newVis);
    visualisationRef.current = newVis;

    // wait 2 seconds
    handleToolChange(toolbarsToDrawingMode(toolbars)[0]);
    return () => {
      if (visualisationRef.current) {
        visualisationRef.current.destroy();
        visualisationRef.current = null;
        newVis = null;
      }
    };
  }, []);

  const handleBrushSizeChange = (value) => {
    if (visualisation) {
      visualisation.setBrushSize(value);
    }
  };
  const handleBrushTextureChange = (name, image) => {
    if (visualisationRef.current) {
      visualisationRef.current.setBrushTexture(
        name,
        image,
        visualisationRef.current
      );
    }
  };
  const handleBranchTextureChange = (name, image) => {
    if (visualisationRef.current) {
      visualisationRef.current.setBranchBrushTexture(
        name,
        image,
        visualisationRef.current
      );
    }
  };

  const handleColorChange = (color) => {
    if (visualisationRef.current) {
      visualisationRef.current.setBrushColor(color);
    }
  };
  const handleImageToggle = () => {
    if (visualisationRef.current) {
      visualisationRef.current.toggleVisibilityBackgroundImage();
    }
  };

  const handlePotToggle = () => {
    if (visualisationRef.current) {
      visualisationRef.current.toggleBonsaiPotVisibility();
    }
  };

  // const handleBrushSizeGet = () => {
  //     if (visualisation) {
  //         return visualisation.brushSize();
  //     }
  //     return 10;
  // }
  const handleToolChange = (tool) => {
    if (visualisationRef.current) {
      visualisationRef.current.setTool(tool);
    }
  };
  const handleUndo = () => {
    if (visualisation) {
      visualisation.undo();
    }
  };
  const handleResetDrawing = () => {
    if (visualisation) {
      visualisation.resetCanvasHistory();
      visualisation.resetBranches();
      visualisation.resetCurrentBranch();
    }
  };

  const handleRedo = () => {
    if (visualisation) {
      visualisation.redo();
    }
  };
  const handleCanUndo = () => {
    if (visualisation) {
      return visualisation.canUndo();
    }
    return false;
  };
  const handleAddFoliage = () => {
    if (visualisation) {
      visualisation.foliageImageManager().createImage(visualisation);
    }
  };

  const handleNextFoliage = () => {
    if (visualisation) {
      visualisation.foliageImageManager().incrementSourceImage();
    }
  };

  const handleDeleteFoliage = () => {
    if (visualisation) {
      visualisation.foliageImageManager().deleteSelectedImage();
    }
  };
  const handleNextFoliageVariant = () => {
    if (visualisation) {
      visualisation.foliageImageManager().incrementVariantImage();
    }
  };
  const handleFoliageTriangleToggle = () => {
    if (visualisation) {
      visualisation.foliageImageManager().toggleShowTriangle();
    }
  };
  const handleLeftToolbar = () => {
    // get current index in toolbars array
    const index = toolbars.indexOf(toolbar);
    // get previous toolbar
    if (index === 0) {
      // set to last toolbar
      setToolbar(toolbars[toolbars.length - 1]);
      visualisation.setToolbar(toolbars[toolbars.length - 1]);
    } else {
      const previousToolbar = toolbars[index - 1];
      // set toolbar
      setToolbar(previousToolbar);
      visualisation.setToolbar(previousToolbar);
    }

    // if (toolbar === "outliner") {
    //   setToolbar("foliage");
    //   visualisation.setToolbar(visualisation.FOLIAGE_TOOLBAR);
    // } else if (toolbar === "foliage") {
    //   setToolbar("colouring");
    //   visualisation.setToolbar(visualisation.COLOURING_TOOLBAR);
    // } else if (toolbar === "orientation") {
    //   setToolbar("outliner");
    //   visualisation.setToolbar(visualisation.OUTLINER_TOOLBAR);
    // } else if (toolbar === "colouring") {
    //   setToolbar("orientation");
    //   visualisation.setToolbar(visualisation.ORIENTATION_TOOLBAR);
    // }
  };
  const handleRightToolbar = () => {
    // get current index in toolbars array
    const index = toolbars.indexOf(toolbar);

    // if last toolbar
    if (index === toolbars.length - 1) {
      // set to first toolbar
      setToolbar(toolbars[0]);
      visualisation.setToolbar(toolbars[0]);
    } else {
      // get next toolbar
      const nextToolbar = toolbars[index + 1];
      // set toolbar
      setToolbar(nextToolbar);
      visualisation.setToolbar(nextToolbar);
    }

    // if (toolbar === "outliner") {
    //   setToolbar("orientation");
    //   visualisation.setToolbar(visualisation.ORIENTATION_TOOLBAR);
    // } else if (toolbar === "foliage") {
    //   setToolbar("outliner");
    //   visualisation.setToolbar(visualisation.OUTLINER_TOOLBAR);
    // } else if (toolbar === "orientation") {
    //   setToolbar("colouring");
    //   visualisation.setToolbar(visualisation.COLOURING_TOOLBAR);
    // } else if (toolbar === "colouring") {
    //   setToolbar("foliage");
    //   visualisation.setToolbar(visualisation.FOLIAGE_TOOLBAR);
    // }
  };

  const showNextToolbar = toolbars.length > 1;

  const getToolbar = (props) => {
    if (toolbar === "outliner") {
      return <ToolbarOutliner {...props} />;
    } else if (toolbar === "outliner_no_image_toggle") {
      return <ToolbarOutliner imageToggle={false} {...props} />;
    } else if (toolbar === "foliage") {
      return <ToolbarFoliage {...props} />;
    } else if (toolbar === "orientation") {
      return <ToolbarOrientator {...props} />;
    } else if (toolbar === "orientation_no_pot") {
      return <ToolbarOrientator {...props} />;
    } else if (toolbar === "colouring_no_pot") {
      return <ToolbarColouring {...props} />;
    } else if (toolbar === "colouring") {
      return <ToolbarColouring {...props} />;
    }
  };

  const getHeader = () => {
    if (!showNextToolbar) return null;
    return (
      <Box
        style={{
          // position: "absolute",
          width: "100%",
          maxWidth: "20rem",
          display: "flex",
          alignItems: "center",
          placeContent: "center",
          position: "relative",
          pointerEvents: "auto",
          WebkitUserSelect: "none",
          background: "white",
          borderRadius: ".5rem",
          outline: "rgba(0, 0, 0, 0.2) solid 1px",
          marginBottom: ".2rem",
          alignSelf: "center",
          // top: "1rem",
          // right: 0,
        }}
      >
        {infoAlert}
        {/* Left and right arrow icon buttons */}
        {showNextToolbar && (
          <IconButton size="large" onClick={handleLeftToolbar}>
            <ArrowCircleLeftOutlinedIcon />
          </IconButton>
        )}
        <div style={{ flexGrow: 1 }} />
        {/* Name of toolbar */}
        <Typography
          variant="h6"
          style={{ display: "inline-block", margin: "0 1rem" }}
        >
          {toolbarTitles[toolbar]}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        {showNextToolbar && (
          <IconButton onClick={handleRightToolbar} size="large">
            <ArrowCircleRightOutlinedIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box style={style} position="relative">
      <div
        style={{
          height: "100%",
          width: "100%",
          touchAction: "none",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={p5Container}
      />

      {getToolbar({
        header: getHeader(),
        branchTextureChanged: handleBranchTextureChange,
        brushTextureChanged: handleBrushTextureChange,
        brushSizeChanged: handleBrushSizeChange,
        brushColorChanged: handleColorChange,
        handleUndo: handleUndo,
        handleRedo: handleRedo,
        handleResetDrawing: handleResetDrawing,
        canUndo: handleCanUndo,
        toolChanged: handleToolChange,
        handleImageToggle: handleImageToggle,
        handlePotToggle: handlePotToggle,
        handleAddFoliage: handleAddFoliage,
        handleNextFoliage: handleNextFoliage,
        handleDeleteFoliage: handleDeleteFoliage,
        handleNextFoliageVariant: handleNextFoliageVariant,
        handleFoliageTriangleToggle: handleFoliageTriangleToggle,
      })}
    </Box>
  );
};

export default ImageEditor;
