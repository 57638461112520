function interpolateBetweenTwoPoints(startPoint, endPoint, numberOfPoints) {
  // curve between two points
  var points = [];
  var xDiff = endPoint.x - startPoint.x;
  var yDiff = endPoint.y - startPoint.y;
  var xIncrement = xDiff / numberOfPoints;
  var yIncrement = yDiff / numberOfPoints;
  for (var i = 0; i < numberOfPoints; i++) {
    points.push({
      x: startPoint.x + xIncrement * i,
      y: startPoint.y + yIncrement * i,
    });
  }

  return points;
}

// Check point intersects with array of points withi 5% tolerance
export function pointIntersectsArray(point, points) {
  const tolerance = 30;
  for (let i = 0; i < points.length; i++) {
    const pointInArray = points[i];
    if (
      point.x > pointInArray.x - tolerance &&
      point.x < pointInArray.x + tolerance &&
      point.y > pointInArray.y - tolerance &&
      point.y < pointInArray.y + tolerance
    ) {
      return pointInArray;
    }
  }
  return false;
}

// Creates an array of 100 points with values from 1 to 0;
function arrayOfTaperedSegmentsPerPercentage() {
  const numberOfPoints = 100; // each whole %
  const segments = [
    { percentage: 20, type: "noloid", start: 1, end: 0.8 },
    { percentage: 35, type: "parabaloid", start: 0.8, end: 0.6 },
    { percentage: 15, type: "parabaloid", start: 0.6, end: 0.3 },
    { percentage: 20, type: "conoid", start: 0.3, end: 0 },
  ];

  const points = [];
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const countPoints = (segment.percentage / 100) * numberOfPoints;
    // console.log("countPoints", countPoints);
    const { start, end } = segment;

    // Array of interpolated points between start and end of length countPoints
    const xArray = interpolateBetweenTwoPoints(
      { x: start, y: 1 },
      { x: end, y: 1 },
      countPoints
    );
    // console.log("xArray", xArray);

    const pointsForSegment = [];
    for (let j = 0; j < countPoints; j++) {
      let k,
        b,
        x = xArray[j].x;
      if (segment.type === "noloid") {
        k = 1;
        b = 6;
      } else if (segment.type === "parabaloid") {
        k = 0.3;
        b = 1;
      } else if (segment.type === "conoid") {
        k = 0.3;
        b = 1.6;
      }
      // console.log("k", k, "b", b, "x", x);
      let taper = taperPolynomial(k, b, x);
      pointsForSegment.push(taper);
    }
    points.push(...pointsForSegment);
  }
  return points;
}

export function applyArrayToTaperSegments(points, maxTaper, minTaper) {
  // if no points return
  if (!points || !points.length || points[0] === undefined) return;

  const pointsWithTaper = [...points];

  if (maxTaper === minTaper) {
    for (let i = 0; i < pointsWithTaper.length; i++) {
      pointsWithTaper[i].taper = maxTaper;
    }
    return pointsWithTaper;
  }

  // Get the taper table
  const taperTable = arrayOfTaperedSegmentsPerPercentage();

  // Normalize the taper table
  const maxTaperTable = Math.max(...taperTable);
  const minTaperTable = Math.min(...taperTable);
  const normalizedTaperTable = taperTable.map((taper) => {
    return (taper - minTaperTable) / (maxTaperTable - minTaperTable);
  });

  // Scale the taper table
  const scaledTaperTable = normalizedTaperTable.map((taper) => {
    return taper * (maxTaper - minTaper) + minTaper;
  });

  // Count the points
  const countPoints = pointsWithTaper.length;

  // Loop through the points
  for (let i = 0; i < countPoints; i++) {
    // Calculate the percentage of the point
    const percentage = (i / countPoints) * 100;

    // Round the percentage to the nearest 1
    const roundedPercentage = Math.round(percentage);
    // Get the taper value for the percentage
    pointsWithTaper[i].taper = scaledTaperTable[roundedPercentage];
  }
  return pointsWithTaper;
}

export function createArrayOfPointsBetweenPoints(
  startPoint,
  endPoint,
  numberOfPoints,
  options
) {
  let {
    noise = [0, 0],
    startAngle = 0,
    startAngleXPercent = 0,
    startAngleYPercent = 0,
  } = options;

  // Create points to interpolate between
  // Calculate x distance and y distance from start to end
  var yDistance = endPoint.y - startPoint.y;
  var xDistance = endPoint.x - startPoint.x;

  // Clculate the new x and y position of the startAngleIndex using startAngleYPercent
  var startAngleY = startPoint.y + yDistance * startAngleYPercent;
  var startAngleX = startPoint.x + xDistance * startAngleXPercent;

  // Create array of startPoint, startAngleIndex, and endPoint
  var startingArrayOfPoints = [
    startPoint,
    { x: startAngleX, y: startAngleY },
    endPoint,
  ];

  // Create array of points interpolating between the points in startingArrayOfPoints
  var points = [];
  for (var i = 0; i < startingArrayOfPoints.length - 1; i++) {
    var startPoint = startingArrayOfPoints[i];
    var endPoint = startingArrayOfPoints[i + 1];
    var newPoints = interpolateBetweenTwoPoints(
      startPoint,
      endPoint,
      numberOfPoints
    );
    points = points.concat(newPoints);
  }

  // Add noise to points
  points = points.map((point) => {
    var xNoise = Math.random() * noise[0] * 2 - noise[0];
    var yNoise = Math.random() * noise[1] * 2 - noise[1];
    return {
      x: point.x + xNoise,
      y: point.y + yNoise,
    };
  });
  return points;
}
// window.b = 1;
// window.lineBetweenPoints = () => {
//   let start = { x: 0, y: 0 };
//   let target = { x: 100, y: 100 };
//   let stepSize = [20, 20];
//   let bounds = [0, 0, 200, 200];
//   let gravity = 5;
//   let steps = 200;
//   //   let path = createArrayOfPointsBetweenPoints(start, target, steps, {
//   //     noise: [0, 5],
//   //     startAngle: 90,
//   //     startAngleXPercent: 0.5,
//   //     startAngleYPercent: 0.2,
//   //   });
//   let path = taperLine(start, target, steps, 100, 50, (window.b += 0.1));
//   window.randomPath = path;
//   window.randomStart = start;
//   window.randomTarget = target;
//   setTimeout(() => {
//     window.lineBetweenPoints();
//   }, 500);
// };

function taperPolynomial(k, b, x) {
  return Math.sqrt(k) * Math.sqrt(Math.pow(x, b));
}

function taperLine(start, end, steps, startThickness, endThickness, b) {
  // Use taper polynomial to create a line that tapers from startSize to 0
  // startSize is the size of the line at the start point
  // endSize is the size of the line at the end point
  // steps is the number of points to create
  // start and end are the start and end points of the line
  // returns an array of points
  var points = [];
  var xDiff = end.x - start.x;
  var yDiff = end.y - start.y;
  var xIncrement = xDiff / steps;
  var yIncrement = yDiff / steps;

  const k = 0.1;
  //   const b = 1;
  b = 1.2;

  // FOr each step, calculate the yIncrement
  for (var i = 0; i < steps; i++) {
    var x = start.x + xIncrement * i;
    var y = start.y + yIncrement * i;
    var percentage = i / steps;
    // console.log(percentage);
    var taper = taperPolynomial(k, b, percentage);
    points.push({
      x: x,
      y: y,
      taper,
    });
  }

  // get the max taper
  var maxTaper = Math.max(...points.map((point) => point.taper));
  var minTaper = Math.min(...points.map((point) => point.taper));

  // normalize the taper

  points = points.map((point) => {
    return {
      ...point,
      taper: (point.taper - minTaper) / (maxTaper - minTaper),
    };
  });
  // Scale the taper
  points = points.map((point) => {
    return {
      ...point,
      taper: point.taper * (endThickness - startThickness) + startThickness,
    };
  });
  //   console.log(points);

  return points;
}
