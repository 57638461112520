import { isOwnerOfUrl } from "../utilities/isOwnerOfUrl";
import { uuidv4 } from "../utilities/uuidv4";
import { uploadData } from "./firebase/firestore/uploadData";
import { uploadDataWithName } from "./firebase/firestore/uploadDataWithName";

export function sendDesignSuggestion(
  bonsai,
  ownerId,
  currentUserUid,
  bonsaiId,
  callback
) {
  /**
   * TODO:
   *  + Create file in owners request folder
   *  + Create file in designers suggestion folder
   */
  // create a uuid for the doc
  const docName = uuidv4();

  makeRequestToOwner(bonsai, ownerId, currentUserUid, bonsaiId, docName, () => {
    makeSuggestionToDesigner(
      bonsai,
      ownerId,
      currentUserUid,
      bonsaiId,
      docName,
      () => {
        callback();
      }
    );
  });
}

function makeRequestToOwner(
  bonsai,
  ownerId,
  currentUserUid,
  bonsaiId,
  docName,
  callback
) {
  // loop through the bonsai properties fronts, outlines, solutions, variations and return a new object that only has the urls that contain the currentUserUid. If a property is empty then remove it from the object
  const request = bonsaiToSuggetionBonsai(bonsai, currentUserUid);
  request.designerId = currentUserUid;
  request.bonsaiId = bonsaiId;
  request.status = "pending";

  uploadDataWithName(
    request,
    `users/${ownerId}/designRequests`,
    docName,
    callback,
    (e) => {
      console.error("couldn't update, something went wrong", e);
    }
  );
}

function makeSuggestionToDesigner(
  bonsai,
  ownerId,
  currentUserUid,
  bonsaiId,
  docName,
  callback
) {
  const request = bonsaiToSuggetionBonsai(bonsai, currentUserUid);
  request.designerId = currentUserUid;
  request.bonsaiId = bonsaiId;
  request.ownerId = ownerId;
  request.status = "pending";
  uploadDataWithName(
    request,
    `users/${currentUserUid}/designSuggestions`,
    docName,
    callback,
    (e) => {
      console.error("couldn't update, something went wrong", e);
    }
  );
}

function bonsaiToSuggetionBonsai(bonsai, currentUserUid) {
  let request = {};
  if (bonsai.fronts) {
    request.fronts = bonsai.fronts;
    // request.fronts = bonsai.fronts.filter((front) => {
    //   return isOwnerOfUrl(front, currentUserUid);
    // });
  }
  if (bonsai.outlines) {
    request.outlines = bonsai.outlines.filter((outline) => {
      return isOwnerOfUrl(outline, currentUserUid);
    });
  }
  if (bonsai.solutions) {
    request.solutions = bonsai.solutions.filter((solution) => {
      return isOwnerOfUrl(solution, currentUserUid);
    });
  }
  if (bonsai.variations) {
    request.variations = bonsai.variations.filter((variation) => {
      return isOwnerOfUrl(variation, currentUserUid);
    });
  }
  return request;
}
