import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import MultiImageCarousel from "./MultiImageCarousel";
import ImageMasonry from "./ImageMasonry";

export default function ImageSelectModal({
  title = "Select image",
  description,
  images,
  onClick,
  onClose,
}) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>

        <ImageMasonry
          data={images}
          onClick={onClick}
          // allowNew={false}
        />
      </DialogContent>
    </Dialog>
  );
}
