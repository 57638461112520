import { IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/system";
import * as React from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";

export function ImageCard({
  image,
  index,
  isFirstElement,
  isLastElement,
  handleChangeOrderUp,
  handleChangeOrderDown,
  handleDeleteImage,
  changeImageField,
}) {
  let imageURL = null;
  if (typeof image === "string") {
    imageURL = image;
  } else if (image.file) {
    imageURL = URL.createObjectURL(image.file);
  }
  return (
    <Card>
      <CardHeader
        action={
          <Box>
            {!isFirstElement && (
              <IconButton
                aria-label="Image up"
                onClick={() => handleChangeOrderUp(index)}
              >
                <ArrowUpwardIcon />
              </IconButton>
            )}
            {!isLastElement && (
              <IconButton
                aria-label="Image down"
                onClick={() => handleChangeOrderDown(index)}
              >
                <ArrowDownwardIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="Delete Image"
              onClick={() => handleDeleteImage(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        }
        title={index == null ? "" : `Image ${index + 1}`}
        // subheader={event.dateTimeActioned.toDate().toDateString()}
      />
      {imageURL && (
        <CardMedia
          component="img"
          // height="194"
          image={imageURL}
          alt="Event Image"
        />
      )}
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          Test area
        </Typography>
      </CardContent> */}
      {/* <CardActions disableSpacing>
        
      {!isFirstElement && (
                        <IconButton
                           aria-label="Image up"
                           onClick={() => handleChangeOrderUp(index)}
                        >
                           <ArrowUpwardIcon />
                        </IconButton>
                     )}
                     {!isLastElement && (
                        <IconButton
                           aria-label="Image down"
                           onClick={() => handleChangeOrderDown(index)}
                        >
                           <ArrowDownwardIcon />
                        </IconButton>
                     )}
                      <IconButton
                        aria-label="Delete Image"
                        onClick={() => handleDeleteImage(index)}
                     >
                        <DeleteIcon />
                     </IconButton>
      </CardActions> */}
    </Card>
  );
}

// export default function EventCards({events, eventType, editCb, deleteCb}) {
// return (
//   <Box sx={{ pb:"1rem",flexGrow: 1 }}>
//   <Grid container spacing={2}>
//     {events.map((event, index)=> {
//       return (
//         <Grid item key={index} xs={12} md={6}><EventCard

//         event={event}
//         eventType={eventType}
//         editCb={editCb}
//         deleteCb={(eventId)=>{deleteCb(eventId,eventType)}}
//       />
//         </Grid>
//       )
//     })}

//   </Grid>

//   </Box>
// );
// }
