export function alertUserUnsavedChanges(e, callback) {
  if (
    localStorage.getItem("bonsai-design_edited") == "false" ||
    localStorage.getItem("bonsai-design_edited") == null
  ) {
    if (callback) {
      callback();
    }
    return null;
  }

  // Prevent default behavior of leaving the screen
  e.preventDefault();

  // Prompt the user before leaving the screen

  let confirmed = window.confirm(
    "You will lose any unsaved changes if you leave. Are you sure you want to leave?"
  );
  if (confirmed == true) {
    localStorage.setItem("bonsai-design_edited", false);
    if (e.data) {
      window.dispatch(e.data.action);
    }
    if (callback) {
      callback();
    }
  }

  return (e.returnValue = "Test return.");
}
