/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Typography } from "@mui/material";
import React from "react";
import MultiImageCarousel from "./MultiImageCarousel";
import BonsaiMasonryDesignList from "./BonsaiMasonryDesignList";
import DashboardList from "./DashboardList";

export default function TitleDescriptionImageDashboard(props) {
  const { title, description, data, style, onClick } = props;
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        height: "100%",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "center",
          // paddingRight: ".5rem",
          // height: "100%",
          flex: 1,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant="caption" align="left">
          {description}
        </Typography>
      </Box>
      <Box style={{ flex: 3, height: "100%", width: "100%" }}>
        <DashboardList data={data} onClick={onClick} />
      </Box>
    </Box>
  );
}
