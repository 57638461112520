import { isOwnerOfUrl } from "./isOwnerOfUrl";

export function bonsaiToSuggestedImages(bonsai, currentUserUid) {
  if (!bonsai) return [];
  /**
   * Loop through the bonsai properties fronts, outlines, solutions, variations and create an array if they urls contain the currentUserUid
   */
  let suggestedImages = [];
  if (bonsai.fronts) {
    bonsai.fronts.forEach((front) => {
      if (isOwnerOfUrl(front, currentUserUid)) {
        suggestedImages.push(front);
      }
    });
  }
  if (bonsai.outlines) {
    bonsai.outlines.forEach((outline) => {
      if (isOwnerOfUrl(outline, currentUserUid)) {
        suggestedImages.push(outline);
      }
    });
  }
  if (bonsai.solutions) {
    bonsai.solutions.forEach((solution) => {
      if (isOwnerOfUrl(solution, currentUserUid)) {
        suggestedImages.push(solution);
      }
    });
  }
  if (bonsai.variations) {
    bonsai.variations.forEach((variation) => {
      if (isOwnerOfUrl(variation, currentUserUid)) {
        suggestedImages.push(variation);
      }
    });
  }
  return suggestedImages;
}

export function bonsaiToSuggestedImagesObject(bonsai, currentUserUid) {
  if (!bonsai) return {};
  let suggestedImages = {};
  if (bonsai.fronts) {
    suggestedImages.fronts = bonsai.fronts.filter((front) =>
      isOwnerOfUrl(front, currentUserUid)
    );
  }
  if (bonsai.outlines) {
    suggestedImages.outlines = bonsai.outlines.filter((outline) =>
      isOwnerOfUrl(outline, currentUserUid)
    );
  }
  if (bonsai.solutions) {
    suggestedImages.solutions = bonsai.solutions.filter((solution) =>
      isOwnerOfUrl(solution, currentUserUid)
    );
  }
  if (bonsai.variations) {
    suggestedImages.variations = bonsai.variations.filter((variation) =>
      isOwnerOfUrl(variation, currentUserUid)
    );
  }
  return suggestedImages;
}
