import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect } from "react";
import AppBarDashboard from "../components/AppBar/Dashboard";
import BonsaiMasonryDesignList from "../components/BonsaiMasonryDesignList";
import { getAllData } from "../libs/firebase/firestore/getAllData";
import { getAuth } from "firebase/auth";
import LoadingScreen from "../components/elements/LoadingScreen";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";
import { useParams } from "react-router-dom";
import { getDataFirebaseRecursion } from "../utilities/getAllDataRecursion";
import BottomBarRoot from "../components/BottomBar/Root";
import { isOwnerOfUrl } from "../utilities/isOwnerOfUrl";
import DashboardList from "../components/DashboardList";
import {
  BugReport,
  Create,
  People,
  Share,
  VideoCall,
} from "@mui/icons-material";
import DashboardListStable from "../components/DashboardListStable";

//  no data component
function NoDataMessage({}) {
  return (
    <Box
      style={{
        padding: "1rem",
        background: "white",
        borderRadius: "1rem",
        margin: "1.5rem",
        border: "1px solid #ccc",
      }}
    >
      <Typography variant="h5" component="h1" gutterBottom>
        Welcome
      </Typography>
      <Typography variant="h6" component="h4" gutterBottom>
        It seems you don't have any trees yet. No worries. From here you can:
      </Typography>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <VideoCall />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link href="https://youtu.be/PRncN2ulfFg">
                Check out the video tutorial
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <People />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="See others designs on the community page" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Create />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Get stuck in and add your first bonsai" />
        </ListItem>
      </List>
    </Box>
  );
}

export function ScreenDashboard({
  onClick,
  onCreate,
  onSuggestionClick,
  onRequestClick,
}) {
  const [data, setData] = React.useState([]);
  const [suggestionData, setSuggestionData] = React.useState([]);
  const [designRequests, setDesignRequests] = React.useState([]);
  const [noData, setNoData] = React.useState(false);

  const { userId } = useParams();

  let currentUser = getAuth().currentUser;

  const handleGotBonsaiData = (data) => {
    // remove any data without the field "front"
    data = data.filter((x) => x.fronts);
    //only include the front filed
    if (currentUser && currentUser.id === userId) {
      setData(data);
    } else {
      setData([...data]);
    }
    if (data.length === 0) setNoData(true);
  };
  const handleClick = (image, index) => {
    let dataToDisplay = [...data];
    onClick(dataToDisplay[index], userId, currentUser);
  };

  const handleGotDesignSuggestions = (data) => {
    setSuggestionData(data);
  };

  const handleSuggestionClick = (image, index) => {
    onSuggestionClick(suggestionData[index].bonsaiID);
  };

  const handleRequestClick = (image, index) => {
    onRequestClick(designRequests[index].storageDocumentId);
  };

  const hangleGotDesignRequests = (data) => {
    setDesignRequests(data);
  };

  useEffect(() => {
    getDataFirebaseRecursion(userId, handleGotBonsaiData, getAllData, `bonsai`);
    // Get designRequests
    getDataFirebaseRecursion(
      userId,
      hangleGotDesignRequests,
      getAllData,
      `designRequests`
    );

    // Get designSuggestions
    // getDataFirebaseRecursion(
    //   userId,
    //   handleGotDesignSuggestions,
    //   getAllData,
    //   `designSuggestions`
    // );
  }, []);
  if (!getAuth().currentUser && !userId)
    return <LoadingScreen title={"finding trees..."} />;

  const showData = (data && data.length) || noData;

  let dataToDisplay = [...data];
  let hasDesignSuggestions = suggestionData && suggestionData.length > 0;

  let hasDesignRequests = designRequests && designRequests.length > 0;

  function dataToDashboardList(data) {
    /**
     * Returns data as an array of objects with the following properties:
     * image: string
     * title: string
     * subtitle: string
     * badge: string
     *
     */

    // get badge number from data requests
    // loop through data requests and add up the number of requests with the same bonsaiID
    // create an object with the bonsaiID as the key and the number of requests as the value

    let badgeNumber = {};
    designRequests.forEach((x) => {
      let bonsaiId = x.bonsaiId || x.bonsaiID;
      if (x.status !== "pending") return;
      if (bonsaiId in badgeNumber) {
        badgeNumber[bonsaiId] += 1;
      } else {
        badgeNumber[bonsaiId] = 1;
      }
    });

    let dataToDisplay = data.map((x) => {
      let badge = badgeNumber[x.storageDocumentId]
        ? badgeNumber[x.storageDocumentId]
        : null;

      let title = "";
      let subtitle = "";
      let ownerId = x.ownerId || x.ownerID;
      // If user is not the owner then set the title and subtitle to the owner's name
      if (ownerId && ownerId !== currentUser.uid) {
        title = x.ownerName;
        subtitle = `${x.ownerName}'s bonsai`;
      }

      let data = {
        image: x.fronts[0],
        title: title,
        subtitle: subtitle,
        badge: badge,
      };
      return data;
    });
    return dataToDisplay;
  }

  return (
    <Box style={{ overflow: "hidden", paddingBottom: "4em" }}>
      <AppBarDashboard onCreate={onCreate} isOwner={!userId} />
      {/* If no data then show message */}
      {/* If data then show masonry list */}
      {!showData && <LoadingScreen title={"finding your trees..."} />}
      {noData && <NoDataMessage />}
      {showData && (
        <DashboardListStable
          data={dataToDashboardList(dataToDisplay)}
          onClick={handleClick}
        />
      )}
      {/* {hasDesignSuggestions && (
        <Fragment>
          <Box style={{ padding: "0rem 1rem" }}>
            <Typography variant="h6" component="h4" gutterBottom>
              Design Suggestions
            </Typography>
            <Divider />
          </Box>
          <BonsaiMasonryDesignList
            data={suggestionData.map((x) => (x.fronts ? x.fronts[0] : null))}
            onClick={handleSuggestionClick}
          />
        </Fragment>
      )}
      {hasDesignRequests && (
        <Fragment>
          <Box style={{ padding: "0rem 1rem" }}>
            <Typography variant="h6" component="h4" gutterBottom>
              Design Requests
            </Typography>
            <Divider />
          </Box>
          <BonsaiMasonryDesignList
            data={designRequests.map((x) => (x.fronts ? x.fronts[0] : null))}
            onClick={handleRequestClick}
          />
        </Fragment>
      )} */}

      <BottomBarRoot startValue={0} />
    </Box>
  );
}
