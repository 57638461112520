// takes a p5js Image object and returns a new Image object with the same pixels

export function copyImageToNew(img, p5) {
  let newImg = p5.createImage(img.width, img.height);
  img.loadPixels();
  newImg.loadPixels();
  for (let i = 0; i < img.pixels.length; i++) {
    newImg.pixels[i] = img.pixels[i];
  }
  newImg.updatePixels();
  return newImg;
}

export function copyCanvasToNewCanvas(canvas, p5) {
  // get density of canvas
  let newCanvas = p5.createGraphics(canvas.width, canvas.height);
  newCanvas.copy(
    // source
    canvas,
    // source x, y, w, h
    0,
    0,
    canvas.width,
    canvas.height,
    // destination x, y, w, h
    0,
    0,
    newCanvas.width,
    newCanvas.height
  );
  newCanvas.updatePixels();

  return newCanvas;
}

export function copyPixelsToNew(pixels, height, width, p5, density) {
  let newImg = p5.createImage(width * 3, height * 3);
  newImg.loadPixels();

  // copy pixels to new image scaling down by density
  for (let i = 0; i < pixels.length; i++) {
    newImg.pixels[i] = pixels[i];
  }

  newImg.updatePixels();
  return newImg;
}

export function copyCanvasToImage(canvas, p5) {
  // create new iamge
  let newImg = p5.createImage(canvas.width, canvas.height);
  newImg.loadPixels();
  // scale factor is density of canvas
  let scaleFactor = p5.pixelDensity();
  // copy canvas to image
  newImg.copy(
    // source
    canvas,
    // source x, y, w, h
    0,
    0,
    canvas.width * scaleFactor,
    canvas.height * scaleFactor,
    // destination x, y, w, h
    0,
    0,
    newImg.width,
    newImg.height
  );
  newImg.updatePixels();

  return newImg;
}
