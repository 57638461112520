import React from "react";
import { useDropzone } from "react-dropzone";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import PrimaryButton from "./PrimaryButton";
// import { getStorage, ref } from "firebase/storage";

export default function SingleImageUpload({ setImageList }) {
  const onDrop = (acceptedFiles) => {
    // const storage = getStorage();
    if (acceptedFiles.length > 0) {
      const newImages = Array.from(acceptedFiles).map((file) => {
        return {
          file: file,
          fileName: file.name,
          status: "CREATED",
          // storageRef: ref(storage, file.name),
          downloadURL: "",
          description: "",
        };
      });

      setImageList((prevState) => [...prevState, ...newImages]);
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps()} style={{ border: "none" }}>
      <input {...getInputProps()} />
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <PrimaryButton
              startIcon={<ImageIcon />}
              onClick={open}
              variant="contained"
              // color="secondary"
            >
              Select Image
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
