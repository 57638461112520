import { addDoc, collection, getFirestore } from "firebase/firestore";

// Dont have to specify ID
export async function uploadData(jsonData, collectionName, cb, errorCb) {
  const db = getFirestore();
  const collectionRef = collection(db, collectionName);
  try {
    const docRef = await addDoc(collectionRef, jsonData);
    if (cb) {
      cb(docRef);
    }
  } catch (e) {
    if (errorCb) {
      errorCb(e);
    }
  }
}
