import { Box, IconButton, Slider, Stack } from "@mui/material";
import React, { useEffect } from "react";

import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";

import {
  OpenWith,
  RotateLeft,
  ThreeSixty,
  VisibilityOff,
} from "@mui/icons-material";
import PanToolIcon from "@mui/icons-material/PanToolOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HeightIcon from "@mui/icons-material/Height";
import WidthIcon from "@mui/icons-material/SettingsEthernet";
import TransformIcon from "@mui/icons-material/Transform";
import IconButtonWithLabel from "../IconButtonWithLabel";

const ToolbarOrientator = ({
  toolChanged,
  handlePotToggle,
  header,
  potHeightChanged,
  potWidthChanged,
}) => {
  // state for tool
  const [tool, setTool] = React.useState("pan");
  const [showImage, setShowImage] = React.useState(false);

  const [potHeight, setPotHeight] = React.useState(25);
  const [potWidth, setPotWidth] = React.useState(100);

  const handlePanTool = () => {
    toolChanged("pan");
    setTool("pan");
  };

  const handleRotateTool = () => {
    toolChanged("rotate");
    setTool("rotate");
  };

  const handleToggleShowPot = () => {
    // TODO
    setShowImage(!showImage);
    handlePotToggle();
  };

  const handleToggleResizePot = () => {
    // TODO
    toolChanged("resizePot");
    setTool("resizePot");
  };

  // const handlePotHeightChange = (event, value) => {
  //   setPotHeight(value);
  //   potHeightChanged(value);
  // };

  // const handlePotWidthChange = (event, value) => {
  //   setPotWidth(value);
  //   potWidthChanged(value);
  // };

  // CHeck if using a phone
  const isPhone = window.innerWidth < 600;

  const selectedToolTopStyle = {
    borderRadius: ".875rem 0 0 .875rem",
    backgroundColor: green[400],
  };
  const selectedToolBottomStyle = {
    borderRadius: "0 .875rem .875rem 0",
    backgroundColor: green[400],
  };

  useEffect(() => {
    // Set tool to pan
    toolChanged("pan");
  }, []);
  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        pointerEvents: "none",
        padding: "24px",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {header}
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          pointerEvents: "auto",
          background: "rgb(255,255,255)",
          boxSizing: "border-box",
          boxShadow:
            "0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017",
          borderRadius: "14px",
          position: "relative",
          WebkitUserSelect: "none",
          alignSelf: "center",
        }}
      >
        <Box
          style={{
            position: "relative",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"}>
            <Divider orientation="horizontal" flexItem />
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "pan" ? selectedToolTopStyle : {}}
              onClick={handlePanTool}
            >
              <IconButtonWithLabel label="Move" icon={<OpenWith />} />
            </Box>

            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "rotate" ? selectedToolBottomStyle : {}}
              onClick={handleRotateTool}
            >
              <IconButtonWithLabel label="Rotate" icon={<ThreeSixty />} />
            </Box>
            {/* <Box
              sx={{ pl: 1, pr: 1 }}
              style={tool === "resizePot" ? selectedToolTopStyle : {}}
              onClick={handleToggleResizePot}
            >
              <IconButton size="large">
                <TransformIcon />
              </IconButton>
            </Box> */}

            {/* <Divider orientation="vertical" flexItem />

            <Box sx={{ pl: 1, pr: 1 }} onClick={handleToggleShowPot}>
              <IconButton size="large">
                {showImage ? <VisibilityOff /> : <VisibilityIcon />}
              </IconButton>
            </Box> */}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

{
  /* <Stack
spacing={0}
direction="row"
sx={{ pl: 1, pr: 1 }}
alignItems="center"
>
<HeightIcon />
<Slider
  disabled={!showImage}
  sx={{ width: "5rem" }}
  aria-label="Brush Size"
  min={1}
  max={100}
  value={potHeight}
  onChange={handlePotHeightChange}
/>
</Stack>

<Stack
spacing={0}
direction="row"
sx={{ pl: 1, pr: 1 }}
alignItems="center"
>
<WidthIcon />
<Slider
  disabled={!showImage}
  sx={{ width: "5rem" }}
  aria-label="Brush Size"
  min={1}
  max={100}
  value={potHeight}
  onChange={handlePotWidthChange}
/>
</Stack> */
}

export default ToolbarOrientator;
