import React from "react";
import { Box, Typography } from "@mui/material";

export default function DashboardCard({ children }) {
  return (
    <Box
      style={{
        padding: "1rem",
        background: "white",
        borderRadius: "1rem",
        margin: "1rem",
        border: "1px solid #ccc",
      }}
    >
      <Typography variant="h6" component="h4" gutterBottom>
        {children}
      </Typography>
    </Box>
  );
}
