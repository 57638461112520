import React from "react";
import { ScreenDesignRoot } from "./Root";
import { useNavigate, useParams } from "react-router-dom";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

export function ScreenDesignFronts({ root = "bonsai" }) {
  let navigate = useNavigateToTop();
  let { bonsaiId, userId } = useParams();
  const handleOnEdit = (imageUrl) => {
    navigate(`/${root}/${bonsaiId}/edit/fronts`);
  };

  return (
    <ScreenDesignRoot
      title="Front images"
      property="fronts"
      onEdit={handleOnEdit}
      enableDelete={false}
      userId={userId}
    />
  );
}
