import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import { uploadDataWithName } from "../firestore/uploadDataWithName";
import { validateEmailAndPassword } from "../../../utilities/validateEmailAndPassword";

export function signUp(email, password, name, cb, failCb, experience) {
  const auth = getAuth();
  const isValid = validateEmailAndPassword(email, password);
  if (!isValid) {
    return;
  }
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in

      // Add the data to the database
      createUserIfNotExist(name, experience, userCredential, failCb);
      if (cb) {
        cb(userCredential);
      }

      // if(userCredential && userCredential.emailVerified === false){
      //     userCredential.sendEmailVerification().then(function(){
      //       console.log("email verification sent to user");
      //       // TODO: Might not want to do this here
      //     });
      // }
      // sendEmailVerification(auth.currentUser).then(() => {
      // Email verification sent!
      // ...

      // });
    })
    .catch((error) => {
      if (failCb) {
        failCb(error);
      }
    });
}

const createUserIfNotExist = (name, experience, userCredentials, failCb) => {
  uploadDataWithName(
    { name, experience },
    `users/`,
    userCredentials.user.uid,
    () => {
      // console.log("uploaded user");
    },
    failCb
  );
};
