// function using p5js takes in a canvas, copies it to a new image, flood fills the new image, removes everything but the flood fill, and returns the flood fill as a new image
import { copyPixelsToNew, copyCanvasToimage } from "../libs/copyImageToNew";
import { colorMatch } from "./colourMatch";
import { floodFillCanvas } from "./filltool";
import { hexToRgba } from "./hexToRgba";
import { replaceEverythingButColourInImage } from "./replaceColourInImage";

/**
 * Fills the image with the colour in the top and leftmost corner.
 * Then runs a check if there is any other colour on the canvas besides the filled colour.
 * If there is, there is a closed shape on the canvas. If not, there is not a closed shape
 * on the canvas.
 *
 * @param {p5js} drawingLibrary
 * @param {p5js image} originalImage the image to fill
 * @returns Returns true if there is a shape on the canvas, false if not
 */
export function isThereClosedShapeOnCanvas(drawingLibrary, originalImage) {
  // Create a copy of the image
  originalImage.loadPixels();
  let newImage = copyPixelsToNew(
    originalImage._pInst.imageData.data,
    originalImage.height,
    originalImage.width,
    drawingLibrary
  );

  let density = drawingLibrary.pixelDensity();
  newImage.resize(newImage.width / density, newImage.height / density);

  // Load the pixels of the new image so ready for updates
  newImage.loadPixels();

  // Fill colour with red
  let fillColourHex = "#ff0000";
  let x = 0;
  let y = 0;

  // Fill canvas with colour at top left corner
  floodFillCanvas(newImage, 0, 0, 1, fillColourHex);
  // newImage.updatePixels(); //Dont think i need to do this as floodFillCanvas does it

  // Get all the colours in the image
  let { colours, colourAmounts } = getColoursInImage(newImage);

  // convert all colours to rgb
  for (let i = 0; i < colours.length; i++) {
    colours[i] = rgbaToRgb(colours[i]);
  }

  let finalColours = [];
  let finalColourAmounts = [];

  for (let i = 0; i < colours.length; i++) {
    let colour = colours[i];

    let pixelLimit = 2000;

    if (colour.r == 255 && colour.g == 0 && colour.b == 0) {
      continue;
    }
    // if yellow
    else if (colour.r == 255 && colour.g == 255 && colour.b < 240) {
      continue;
    }
    // if black
    else if (colour.r == 0 && colour.g == 0 && colour.b == 0) {
      continue;
    } else if (colourAmounts[i] < pixelLimit) {
      continue;
    } else {
      finalColours.push(colour);
      finalColourAmounts.push(colourAmounts[i]);
    }
  }

  // if final colours is empty, there is no closed shape on the canvas
  if (finalColours.length == 0) {
    return false;
  }
  return true;
}

/**
 * A function that goes through an image and keeps an array of all the unique colours in the image
 * @param {p5js image} image
 * @returns Returns an array of all the colours in the image, and another array on the amount of times each colour appears
 */

function getColoursInImage(image) {
  let colours = [];
  let colourAmounts = [];
  for (let x = 0; x < image.width; x++) {
    for (let y = 0; y < image.height; y++) {
      let colour = getColourAtPixel(image, x, y);
      let indexOfColour = colourInArray(colour, colours);
      if (indexOfColour === false) {
        colours.push(colour);
        colourAmounts.push(1);
      } else {
        colourAmounts[indexOfColour]++;
      }
    }
  }
  return { colours, colourAmounts };
}

function getColourAtPixel(image, x, y) {
  let index = (x + y * image.width) * 4;
  let r = image.pixels[index];
  let g = image.pixels[index + 1];
  let b = image.pixels[index + 2];
  let a = image.pixels[index + 3];
  return { r, g, b, a };
}

// RGBA to RGB where alpha is 0 - 255
function rgbaToRgb(rgba) {
  let r = rgba.r;
  let g = rgba.g;
  let b = rgba.b;
  let a = rgba.a;
  let newR = Math.round((1 - a / 255) * 255 + (a / 255) * r);
  let newG = Math.round((1 - a / 255) * 255 + (a / 255) * g);
  let newB = Math.round((1 - a / 255) * 255 + (a / 255) * b);
  return { r: newR, g: newG, b: newB };
}

function colourInArray(colour, colours) {
  for (let i = 0; i < colours.length; i++) {
    if (colorMatch(colour, colours[i])) {
      return i;
    }
  }
  return false;
}
