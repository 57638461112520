import { Masonry } from "@mui/lab";
import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";

export default function BonsaiMasonryDesignListStable({
  data,
  onClick,
  padding = "1rem",
  isOwner = false,
  size = "small",
  style,
}) {
  // get break points
  const theme = useTheme();
  // set the number of columns based on the current breakpoint

  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const isMdBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgBreakPoint = useMediaQuery(theme.breakpoints.down("xl"));

  if (!data) {
    return null;
  }
  let smallColumns = 2;
  let mediumColumns = 4;
  let largeColumns = 6;

  if (size == "large") {
    smallColumns = 2;
    mediumColumns = 2;
    largeColumns = 3;
  }

  // set the number of columns based on the current breakpoint
  let columns = mediumColumns;
  if (isXsBreakPoint) {
    columns = smallColumns;
  } else if (isSmBreakPoint) {
    columns = mediumColumns;
  } else if (isMdBreakPoint) {
    columns = mediumColumns;
  } else if (isLgBreakPoint) {
    columns = largeColumns;
  } else {
    columns = largeColumns;
  }

  // remove all null, undefined, and empty string values
  // data = data.filter((item) => item);
  function getBorderStyle(index) {
    if (isOwner.length > 0 && isOwner[index]) {
      return "1px solid #ccc";
    } else {
      return "1px solid green";
    }
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: padding,
        overflowX: "hidden",
        overflowY: "hidden",
        textAlign: "-webkit-center",
        ...style,
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <ImageList
          variant="woven"
          cols={columns}
          gap={8}
          style={{
            padding: "1rem",
            // paddingRight: "2rem",
            // paddingBottom: "1rem",
            overflow: "hidden",
          }}
        >
          {/* <Masonry columns={columns} spacing={2} style={{ padding: "0rem" }}> */}
          {data.map((item, index) => (
            <ImageListItem
              key={index}
              onClick={() => (onClick ? onClick(item, index) : null)}
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
                border:
                  item == tutorialBonsai.fronts[0]
                    ? "1px solid #E46540"
                    : "1px solid #ccc",
              }}
            >
              <img
                src={`${item}?w=162&auto=format`}
                // srcSet={`${item}?w=162&auto=format&  dpr=2 2x`}
                alt={index}
                loading="lazy"
                style={{
                  display: "block",
                  width: "100%",
                  borderRadius: "10px",
                  border: getBorderStyle(index),
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}
