// React component using MUI components and MUI styles for login screen
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signIn } from "../libs/firebase/auth/signInEmail";

import PrimaryButton from "../components/PrimaryButton";
import TextFieldEmail from "../components/textfield/Email";
import TextFieldPassword from "../components/textfield/Password";
import JapanArtImage from "../images/artwork/japan_art (2).jpg";

export default function ScreenLogin({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  //   const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    signIn(email, password, onLoginSuccess, failCb);

    try {
      //   await login(email, password, remember);
      //   history.push("/");
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  const failCb = (error) => {
    setError(error.message);
    // if (error.code === "auth/invalid-email" || error.code === "auth/user-not-found") {
    //   setInvalidEmail(true);
    // }
    // else if (error.code === "auth/wrong-password") {
    //   setInvalidCredentials(true);
    // }
    // else {
    //   setOtherIssue(true);
    // }
  };
  const theme = useTheme();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        background: `url("${JapanArtImage}")`,
      }}
    >
      {/* <Box>
        <img src={JapanArtImage} alt="logo" />
      </Box> */}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px",
          padding: "1rem",
          gap: ".5rem",
          backgroundColor: theme.palette.background.paper,
          borderRadius: "10px",
          boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h5" component="h2">
          Welcome Back
        </Typography>
        <Typography variant="subtitle2" component="p" gutterBottom>
          Log in to your account
        </Typography>
        <FormControl
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          <TextFieldEmail
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl
          style={{
            marginBottom: "1rem",
          }}
        >
          <TextFieldPassword
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        {/* <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              checked={remember}
              onChange={(e) => setRemember(e.target.checked)}
            />
          }
          label="Remember me"
        /> */}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <PrimaryButton
          style={{
            width: "100%",
            maxWidth: "15rem",
            marginTop: "2rem",
          }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Login
        </PrimaryButton>
        <Typography variant="subtitle2" component={"p"}>
          Don't have an account?{" "}
          <Link
            style={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            to="/signup"
          >
            Sign up
          </Link>
        </Typography>
      </form>
    </Box>
  );
}
