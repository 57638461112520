// React component that returns a grid of two grids. In the first gird the image is drawn and a list of bonsai pots. In the second grid are tools to manipulate the image
import {
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ImageComparison from "../ImageComparison";
import ImageSelectModal from "../../ImageSelectModal";
import { Download } from "@mui/icons-material";

const ContainerCompareImages = ({
  sketchRef,
  setSketchRef,
  firstImage = null,
  secondImage = null,
  images = [],
}) => {
  // state for leftImage
  // const [leftImage, setLeftImage] = React.useState(firstImage);
  // state for rightImage
  const [rightImage, setRightImage] = React.useState(secondImage);

  // state for image select modal
  const [showImageSelectModal, setShowImageSelectModal] = React.useState(false);

  // Theme and style stuff
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      {showImageSelectModal && (
        <ImageSelectModal
          images={images}
          onClick={(image) => {
            setRightImage(image);
            setShowImageSelectModal(false);
          }}
          onClose={() => {
            setShowImageSelectModal(false);
          }}
        />
      )}
      <Stack>
        <Button
          onClick={() => {
            setShowImageSelectModal(true);
          }}
        >
          Select image
        </Button>

        {/* Download IconButton to saveCanvas */}
        <IconButton
          onClick={() => {
            if (sketchRef) {
              sketchRef.saveCanvas("image", "png");
            }
          }}
        >
          <Download />
        </IconButton>
      </Stack>
      <Box style={{ overflow: "hidden", height: "100%" }}>
        <ImageComparison
          leftImage={firstImage}
          rightImage={rightImage}
          onCanvasChange={(canvas) => {
            setSketchRef(canvas);
          }}
          style={{
            flex: isXsBreakPoint ? 5 : 5,
            height: "100%",
            width: "100%",
            minHeight: "200px",
          }}
        />
      </Box>
    </Box>
  );
};

export default ContainerCompareImages;
