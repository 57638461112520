import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";

import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import PanToolIcon from "@mui/icons-material/PanToolOutlined";
import { OpenWith, Undo } from "@mui/icons-material";
import IconButtonWithLabel from "../IconButtonWithLabel";

const trunkColour = "#bf975a";
const deadwoodColour = "#b8bab7";

const TrunkSwatch = (props) => {
  return <Swatch background={trunkColour} label="TRUNK" {...props} />;
};

const DeadwoodSwatch = (props) => {
  return <Swatch background={deadwoodColour} label="JIN" {...props} />;
};

const Swatch = ({ selectedColor, background, onClick, label }) => {
  const isSelected = selectedColor === background;
  const border = isSelected ? "solid 2px purple" : "solid 2px transparent";
  return (
    <Box
      style={{
        padding: ".5rem",
      }}
    >
      <Box
        onClick={(event) => {
          onClick(background, event);
        }}
        style={{
          position: "relative",
          borderRadius: "50%",
          width: "28px",
          height: "28px",
          border,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "-1rem",
        }}
      >
        <Box
          style={{
            boxShadow: "rgb(0 0 0 / 20%) 0px 0px 0px 1px inset",
            background,
            borderRadius: "50%",
            width: "24px",
            height: "24px",
          }}
        ></Box>
        <Typography
          style={{
            fontSize: "0.5rem",
            color: "black",
            padding: ".5rem 0rem",
            position: "absolute",
            bottom: "-1.4rem",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

const ToolbarColouring = ({
  toolChanged,
  brushColorChanged,
  header,
  handleUndo,
  handleRedo,
  canUndo,
}) => {
  // state for tool
  const [tool, setTool] = React.useState("pan");
  const [brushColor, setBrushColor] = React.useState(trunkColour);

  // state for knowing if bush mode is color or texture
  const [brushMode, setBrushMode] = React.useState("color");

  const handleColorChange = (color) => {
    setBrushColor(color);
    brushColorChanged(color);
    setBrushMode("color");
    handleFloodFill();
  };
  const handlePanTool = () => {
    toolChanged("pan");
    setTool("pan");
  };

  const handleFloodFill = () => {
    toolChanged("floodFill");
    setTool("floodFill");
  };

  // CHeck if using a phone
  const isPhone = window.innerWidth < 600;

  const selectedToolTopStyle = {
    borderRadius: ".875rem 0 0 0",
    backgroundColor: green[400],
  };
  const selectedToolBottomStyle = {
    borderRadius: "0 0 0 .875rem",
    backgroundColor: green[400],
  };
  const selectedToolLeftEdgeStyle = {
    borderRadius: ".875rem 0 0 .875rem",
    backgroundColor: green[400],
  };
  const selectedToolMiddleStyle = {
    backgroundColor: green[400],
  };

  useEffect(() => {
    // Set tool to pan
    toolChanged("pan");
    setBrushColor(trunkColour);
    brushColorChanged(trunkColour);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        pointerEvents: "none",
        padding: "24px",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {header}
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          pointerEvents: "auto",
          background: "rgb(255,255,255)",
          boxSizing: "border-box",
          boxShadow:
            "0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017",
          borderRadius: "14px",
          position: "relative",
          WebkitUserSelect: "none",
          alignSelf: "center",
        }}
      >
        <Box
          style={{
            position: "relative",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"}>
            <Box
              // sx={{ pl: 1, pr: 1 }}
              style={tool === "pan" ? selectedToolLeftEdgeStyle : {}}
              onClick={handlePanTool}
            >
              <IconButtonWithLabel label="Move" icon={<OpenWith />} />
            </Box>
            <Box
              style={tool === "floodFill" ? selectedToolMiddleStyle : {}}
              onClick={handleFloodFill}
            >
              <IconButtonWithLabel
                label="Fill"
                icon={<FormatColorFillIcon />}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Stack
              spacing={2}
              direction="row"
              sx={{ pl: 1, pr: 1 }}
              alignItems="center"
            >
              <TrunkSwatch
                selectedColor={brushMode === "color" ? brushColor : false}
                onClick={handleColorChange}
              />
              <DeadwoodSwatch
                selectedColor={brushMode === "color" ? brushColor : false}
                onClick={handleColorChange}
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Box onClick={handleUndo}>
              <IconButtonWithLabel label="Undo" icon={<Undo />} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ToolbarColouring;
