export function createTransformedImage(
  image,
  x = 0,
  y = 0,
  scale = 1,
  rotation = 0
) {
  // Apply the fields from transformImage to the image

  image.setX = function (x) {
    this._x = x;
  };
  image.setY = function (y) {
    this._y = y;
  };

  image.x = function () {
    return this._x;
  };
  image.y = function () {
    return this._y;
  };

  image.setScale = function (scale) {
    this._scale = scale;
  };

  image.scale = function () {
    return this._scale;
  };

  image.setRotation = function (rotation) {
    this._rotation = rotation;
  };

  image.rotation = function () {
    return this._rotation;
  };

  image.setX(x);
  image.setY(y);
  image.setScale(scale);
  image.setRotation(rotation);

  return image;
}
