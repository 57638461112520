/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../PrimaryButton";

export default function TitleDescriptionAction(props) {
  const {
    title,
    description,
    style,
    onClick,
    actionText,
    size = "small",
  } = props;
  let subVariant = "caption";
  if (size === "large") {
    subVariant = "body1";
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        marginBottom: "1rem",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          //   justifyContent: "center",
          paddingRight: ".5rem",
          flex: 3,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant={subVariant} align="left" gutterBottom>
          {description}
        </Typography>
        <PrimaryButton onClick={onClick}>{actionText}</PrimaryButton>
      </Box>
    </Box>
  );
}
