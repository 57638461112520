// function that checks if the two urls are for the same file
// returns true if they are, false if they aren't
export function isUrlForSameFile(url1, url2) {
  // Get everything before ?alt
  const url1WithoutAlt = url1.split("?alt")[0];
  const url2WithoutAlt = url2.split("?alt")[0];

  // Check if they are the same
  return url1WithoutAlt === url2WithoutAlt;
}

export function isUrlInArray(url, array) {
  return array.some((arrayItem) => isUrlForSameFile(url, arrayItem));
}
