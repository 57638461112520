export const withWidthHeight = {
  width() {
    return this._width;
  },
  height() {
    return this._height;
  },
  setWidth(width) {
    if (
      width > this._minWidth ||
      width > this._width ||
      this._minWidth === undefined
    ) {
      this._width = width;
    }
  },
  setHeight(height) {
    if (
      height > this._minHeight ||
      height > this._height ||
      this._minHeight === undefined
    ) {
      this._height = height;
    }
  },
  minHeight() {
    return this._minHeight;
  },
  minWidth() {
    return this._minWidth;
  },
  setMinHeight(minHeight) {
    this._minHeight = minHeight;
  },
  setMinWidth(minWidth) {
    this._minWidth = minWidth;
  },
};
