export function brushStrokeAtLocation(
  yDraw,
  brushSize,
  vis,
  drawingLibrary,
  canvas,
  stroke = "black"
) {
  canvas.push();
  let transX = vis._panX + drawingLibrary.width / 2 / vis.scale();
  let transY = vis._panY + drawingLibrary.height / 2 / vis.scale();
  // canvas.scale(vis.scale());
  canvas.translate(-transX, -transY);

  let lineStart = vis.optimisedLastDrawnPosition();
  lineStart.x = lineStart.x / vis.scale();
  lineStart.y = lineStart.y / vis.scale();

  let lineEnd = {
    x: drawingLibrary.mouseX / vis.scale(),
    y: yDraw / vis.scale(),
  };
  canvas.noFill();
  canvas.stroke(stroke);
  canvas.strokeWeight(brushSize);
  canvas.line(lineStart.x, lineStart.y, lineEnd.x, lineEnd.y);
  //   canvas.circle(0, 0, brushSize);

  // setoptimisedLastDrawnPosition
  vis.setOptimisedLastDrawnPosition(drawingLibrary.mouseX, yDraw);

  canvas.pop();
}

function unOptimisedBrushStrokeAtLocation(
  yDraw,
  brushSize,
  vis,
  drawingLibrary,
  canvas
) {
  canvas.push();
  let transX = vis._panX + drawingLibrary.width / 2 / vis.scale();
  let transY = vis._panY + drawingLibrary.height / 2 / vis.scale();
  // canvas.scale(vis.scale());
  canvas.translate(-transX, -transY);
  canvas.translate(drawingLibrary.mouseX / vis.scale(), yDraw / vis.scale());
  canvas.fill(vis.brushColor());
  canvas.noStroke();
  canvas.circle(0, 0, brushSize);
  canvas.pop();
}
