import { Typography } from "@mui/material";
import React from "react";

export const FormError = ({ title }) => {
  // if title is not a string
  if (typeof title !== "string") {
    // return nothing
    return null;
  }
  return (
    <div
      style={{
        padding: "10px",
        marginTop: "1rem",
        borderRadius: "2px",
        backgroundColor: "#eddbc2",
      }}
    >
      {/* <Typography
        style={{ display: "block" }}
        color="warning.main"
        variant="subtitle1"
      >
        {title}
      </Typography> */}
    </div>
  );
};
