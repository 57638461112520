import { CardHeader, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import * as React from "react";
import { uploadImage } from "../libs/firebase/cloudStorage/uploadImage";
import { updateData } from "../libs/firebase/firestore/updateData";

import BackdropExecutingAction from "./BackdropExecutingAction";
import { FormError } from "./FormError";
import { ImageCard } from "./ImageCard";
import { uuidv4 } from "../utilities/uuidv4";
import MultiImageUpload from "./MutliImageUpload";
import { uploadFile } from "../libs/firebase/cloudStorage/uploadFile";
import { uploadData } from "../libs/firebase/firestore/uploadData";
import SingleImageUpload from "./SingleImageUpload";
import PrimaryButton from "./PrimaryButton";

const theme = createTheme();

export default function UploadImage({
  user,
  handleOnClose,
  title,
  actionTitle,
  property,
  bonsaiUuid = uuidv4(),
}) {
  const [loadBackdrop, setLoadBackdrop] = React.useState(false);
  const [image, setImageList] = React.useState([]);

  const changeImageField = (index, parameter, value) => {
    const newArray = [...image];
    newArray[index][parameter] = value;
    setImageList(newArray);
  };

  const imageUploadCb = (url, imageIndex, resolve) => {
    changeImageField(imageIndex, "downloadURL", url);
    changeImageField(imageIndex, "status", "FINISH");
    resolve(url);
  };

  let dataInvalid = !image.length;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dataInvalid) return;
    setLoadBackdrop("Processing the Image");

    const data = new FormData(event.currentTarget);
    uploadImage(image[0], imageUploadCb, `${user.uid}/`, errorCallback) //,
      .catch((e) => {
        errorCallback(e, "Maximum 5MB file size allowed.");
      })
      .then((uploadedImageUrls) => {
        if (!uploadedImageUrls) return;
        setLoadBackdrop("Finding your image a home");
        let updatedData = {};
        // TODO - need to add some sort of functionality to take current data and add onto it?
        updatedData[property] = [image[0].downloadURL];
        updateData(
          updatedData,
          `users/${user.uid}/bonsai`,
          bonsaiUuid,
          callback,
          (e) => {
            uploadData(
              updatedData,
              `users/${user.uid}/bonsai`,
              callback,
              errorCallback
            );
          }
        );
        return uploadedImageUrls;
      });
  };

  const callback = (stats) => {
    console.trace();
    setLoadBackdrop("Image Uploaded");
    setTimeout(handleOnClose, 2000);
  };
  const errorCallback = (err, str = "") => {
    console.trace();
    setLoadBackdrop(`Image upload failed.${str} Try again later.`);
    setTimeout(() => {
      setLoadBackdrop(false);
    }, 2000);
  };

  return (
    <Container sx={{ mb: "4.5rem" }} component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {image.length === 0 && (
          <Box
            style={{ marginTop: "2rem" }}
            border={"1px solid rgba(0,0,0,0.25)"}
            borderRadius={1}
            padding={3}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              xs={12}
              spacing={1}
            >
              <Grid item container xs={12} justifyContent="center">
                <SingleImageUpload setImageList={setImageList} />
              </Grid>
            </Grid>
          </Box>
        )}

        {dataInvalid && <FormError title={dataInvalid} />}
        {!dataInvalid && (
          <PrimaryButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={dataInvalid}
          >
            {actionTitle}
          </PrimaryButton>
        )}
        {image.length > 0 && (
          // <Card sx={{ mt: "1rem" }}>
          //   <CardContent>
          <Box sx={{ pt: "1rem", pb: "1rem", flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ImageCard
                  image={image[0]}
                  isFirstElement={true}
                  isLastElement={true}
                  handleChangeOrderUp={() => {}}
                  handleChangeOrderDown={() => {}}
                  handleDeleteImage={() => {
                    setImageList([]);
                  }}
                  changeImageField={changeImageField}
                />
              </Grid>
            </Grid>
          </Box>
          //   </CardContent>
          // </Card>
        )}
        <BackdropExecutingAction
          status={loadBackdrop}
          open={!!loadBackdrop}
          loader={loadBackdrop !== "Bonsai has been created"}
          handleClose={() => {}}
        />
      </Box>
    </Container>
  );
}
