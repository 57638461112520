import IconBack from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { DialogDownloadPdf } from "../Dialog/DownloadPdf";
import Instructions from "../InstructionDialog";
import AppBarRoot from "./Root";

function AppBarProfile({ title, label, onCompare, onBack, onSavePdf }) {
  const navigate = useNavigateToTop();
  const handleOnInformation = () => {
    navigate("/instruction/" + label);
  };

  // const handleOnCompare = () => {
  //   navigate("/compare/" + label);
  // };
  return (
    <AppBarRoot
      startAction={
        <Stack direction={"row"} spacing={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconBack />
          </IconButton>
          <Typography
            variant="h5"
            style={{ alignSelf: "center", color: "white" }}
          >
            {title}
          </Typography>
        </Stack>
      }
      endAction={
        <Stack direction={"row"} spacing={1}>
          <Instructions label={label} />
          <DialogDownloadPdf onSavePdf={onSavePdf} />
        </Stack>
      }
    />
  );
}

export default AppBarProfile;
