import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { encodeURLtoHex } from "../utilities/encodeUrlToHex";
import ImageSelectModal from "./ImageSelectModal";
import MultiImageCarousel from "./MultiImageCarousel";
import TitleDescriptionImage from "./TitleDescriptionImage";
import TitleDescriptionMultiImage from "./TitleDescriptionMultiImage";
import TitleDescription from "./TitleDescription";
import { updateData } from "../libs/firebase/firestore/updateData";
import PrimaryButton from "./PrimaryButton";
import { Grid } from "@mui/material";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { isOwnerOfUrl } from "../utilities/isOwnerOfUrl";
import { getAuth } from "firebase/auth";
import TitleDescriptionImageMosaic from "./TitleDescriptionImageMosaic";

const modalModes = {
  NEW_OUTLINE: "NEW_OUTLINE",
  NEW_SOLUTION: "NEW_SOLUTION",
  NEW_VARIATION: "NEW_VARIATION",
  FINAL_DESIGN: "FINAL_DESIGN",
  CLOSE: false,
};

const modalModeDescriptions = {
  NEW_OUTLINE: "Select the front image you want to use for your outline.",
  NEW_SOLUTION: "Select the outline image you want to use for your structure.",
  NEW_VARIATION:
    "Select the structure image you want to use for your foliage design.",
  FINAL_DESIGN:
    "Select the foliage image you currently think is the best design.",
};

function isOwner(images, currentUser) {
  if (!images) {
    return false;
  }
  return images.map((item) => isOwnerOfUrl(item, currentUser.uid));
}
export default function DesignSuggestionProfile({
  bonsai,
  bonsaiId,
  onClick,
  onNewClick,
  onUpdateData,
  user,
  userId,
}) {
  // Modal state
  const [modalOpen, setModalOpen] = React.useState(modalModes.CLOSE);
  const [modalImages, setModalImages] = React.useState([]);

  const navigate = useNavigateToTop();
  if (!bonsai) {
    return null;
  }
  const canEdit = !userId || (user && user.uid === userId);
  const currentUser = getAuth().currentUser;

  const outlinesAvailable = bonsai.fronts && bonsai.fronts.length > 0;
  const solutionsAvailable =
    outlinesAvailable && bonsai.outlines && bonsai.outlines.length > 0;
  const variationsAvailable =
    solutionsAvailable && bonsai.solutions && bonsai.solutions.length > 0;
  const finalDesignAvailable =
    bonsai.variations && bonsai.variations.length > 0;

  const handleNewOutlineClicked = () => {
    // Open the modal
    // setModalImages(bonsai.fronts);
    // setModalOpen(modalModes.NEW_OUTLINE);
    let encodedItem = encodeURLtoHex(bonsai.fronts[0]);
    navigate(`/designs/${bonsaiId}/create/outlines/${encodedItem}`);
  };

  const handleNewSolutionClicked = () => {
    // Open the modal
    // setModalImages(bonsai.outlines);
    // setModalOpen(modalModes.NEW_SOLUTION);

    let encodedItem = encodeURLtoHex(bonsai.outlines[0]);
    navigate(`/designs/${bonsaiId}/create/solutions/${encodedItem}`);
  };

  const handleNewVariationClicked = () => {
    // Open the modal
    setModalImages(bonsai.solutions);
    setModalOpen(modalModes.NEW_VARIATION);
  };

  const handleNewFinalDesignClicked = () => {
    setModalImages(bonsai.variations);
    setModalOpen(modalModes.FINAL_DESIGN);
  };

  const handleCloseModal = () => {
    setModalOpen(modalModes.CLOSE);
  };

  const handleModalImageClick = (item) => {
    // Make user select a front
    // then navigate to the outline screen with the front in the url
    // encode the item
    let encodedItem = encodeURLtoHex(item);
    if (modalOpen === modalModes.NEW_OUTLINE) {
      navigate(`/designs/${bonsaiId}/create/outlines/${encodedItem}`);
    } else if (modalOpen === modalModes.NEW_SOLUTION) {
      navigate(`/designs/${bonsaiId}/create/solutions/${encodedItem}`);
    } else if (modalOpen === modalModes.NEW_VARIATION) {
      navigate(`/designs/${bonsaiId}/create/variations/${encodedItem}`);
    } else if (modalOpen === modalModes.FINAL_DESIGN) {
      // Set as the new final design
      setFinalDesign(item);
    }
  };
  const setFinalDesign = (item) => {
    let updatedData = {
      finalDesign: item,
    };
    updateData(
      updatedData,
      `users/${user.uid}/bonsai`,
      bonsaiId,
      () => {
        onUpdateData();
      },
      (e) => {
        console.error("couldn't update, something went wrong");
      }
    );
    //close modal
    handleCloseModal();
  };

  return (
    <Box>
      {modalOpen && (
        <ImageSelectModal
          images={modalImages}
          onClick={handleModalImageClick}
          onClose={handleCloseModal}
          description={modalModeDescriptions[modalOpen]}
        />
      )}
      {bonsai && (
        <Grid
          container
          spacing={2}
          style={{ overflow: "hidden", width: "100%" }}
        >
          <Grid item xs={12} sm={4} md={3}>
            <TitleDescriptionImage
              title="Front Photo"
              description="This is the front of your tree. You will sketch over it to create an outline."
              image={bonsai.fronts[0]}
              // style={{ marginBottom: "1rem" }}
              onClick={(item) => {
                onClick("fronts", item);
              }}
              onNewItemClick={(item) => {
                onNewClick("fronts", item);
              }}
              canAction={canEdit}
              isOwner={isOwnerOfUrl(
                bonsai.fronts ? bonsai.fronts[0] : null,
                currentUser.uid
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {outlinesAvailable ? (
              <TitleDescriptionImage
                title="Outline"
                description="This is the outline of your tree. You will modify it later to create new structures."
                image={bonsai.outlines ? bonsai.outlines[0] : null}
                // style={{ marginBottom: "1rem" }}
                onNewItemClick={handleNewOutlineClicked}
                onClick={(item) => {
                  onClick("outlines", item);
                }}
                canAction={canEdit}
                isOwner={isOwnerOfUrl(
                  bonsai.outlines ? bonsai.outlines[0] : null,
                  currentUser.uid
                )}
              />
            ) : (
              <Typography variant="body2" color="text.secondary" gutterBottom>
                You need a front first!
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {solutionsAvailable ? (
              <TitleDescriptionImageMosaic
                title="Structure"
                description="These are the potential structures of your tree. Different styles, orientations, taper, primary branching and deadwood. You will add foliage later to create variations of your tree."
                style={{ marginBottom: "1rem" }}
                images={bonsai.solutions}
                onClick={(item) => {
                  onClick("solutions", item);
                }}
                onNewItemClick={handleNewSolutionClicked}
                canAction={canEdit}
                isOwner={isOwner(bonsai.solutions, currentUser)}
              />
            ) : (
              <TitleDescription
                title="Structure"
                description="These are the potential structures of your tree. Different styles, orientations, taper, primary branching and deadwood. You will add foliage later to create variations of your tree."
                note="Complete the above steps first!"
                style={{ opacity: "0.5", marginBottom: "1rem" }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {variationsAvailable ? (
              <TitleDescriptionImageMosaic
                title="Foliage"
                description="These are the variations of your tree. You will consider these and select your final design for the tree."
                style={{ marginBottom: "1rem" }}
                images={bonsai.variations}
                onClick={(item) => {
                  onClick("variations", item);
                }}
                onNewItemClick={handleNewVariationClicked}
                canAction={canEdit}
                isOwner={isOwner(bonsai.variations, currentUser)}
              />
            ) : (
              <TitleDescription
                title="Foliage"
                description="These are the variations of your tree. You will consider these and select your final design for the tree."
                note="Complete the above steps first!"
                style={{ opacity: "0.5", marginBottom: "1rem" }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {finalDesignAvailable ? (
              <TitleDescriptionImage
                title="Final Design"
                description="This is the final design of your tree. You will execute this design on your tree."
                image={bonsai.finalDesign}
                style={{ marginBottom: "1rem" }}
                imageAlt={"Choose"}
                onClick={(item) => {
                  // open image in browser
                  window.open(bonsai.finalDesign, "_blank");
                }}
                action={
                  <PrimaryButton onClick={handleNewFinalDesignClicked}>
                    Choose
                  </PrimaryButton>
                }
                onNewItemClick={handleNewFinalDesignClicked}
                canAction={canEdit}
                isOwner={isOwnerOfUrl(bonsai.finalDesign, currentUser.uid)}
              />
            ) : (
              <TitleDescription
                title="Final Design"
                description="This is the final design of your tree. You will execute this design on your tree."
                note="Complete the above steps first!"
                style={{ opacity: "0.5", marginBottom: "1rem" }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
