import { doc, getDoc, getFirestore } from "firebase/firestore";

export async function getData(dataName, successCb, failCb) {
  const db = getFirestore();
  const docRef = doc(db, dataName);
  const querySnapshot = await getDoc(docRef).catch((error) => {
    if (failCb) {
      failCb(error);
    }
  });
  if (querySnapshot && querySnapshot.exists()) {
    let data = querySnapshot.data();
    data.storageDocumentId = querySnapshot.id;

    successCb(data);
  } else {
    console.error("failed to read data:", dataName);
    if (failCb) {
      failCb();
    }
  }
}
