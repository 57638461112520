export const withPosition = {
  x() {
    return this._x;
  },
  y() {
    return this._y;
  },
  setX(x) {
    this._x = x;
  },
  setY(y) {
    this._y = y;
  },
};
