import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
  DialogActions,
  Button,
} from "@mui/material";
import { Close, Delete, Download, Edit } from "@mui/icons-material";
import { isOwnerOfUrl } from "../utilities/isOwnerOfUrl";
import { getAuth } from "firebase/auth";

function DeleteMenu({ open, onDelete, onClose }) {
  // ask user to confirm delete
  // if yes, delete
  // if no, do nothing

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete this image?</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this image?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onDelete();
            onClose();
          }}
        >
          <Typography>Delete image</Typography>
        </Button>
        <Button onClick={onClose} variant="contained">
          <Typography>Cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ImageViewDialog({ image, onEdit, onDelete, onClose, isOwner }) {
  // state for delete menu
  const [deleteMenuOpen, setDeleteMenuOpen] = React.useState(false);

  if (!image) return null;
  const borderStyle = isOwner ? "1px solid #ccc" : "1px solid green";
  // if (!isOwner) {
  //   onDelete = false;
  // }
  onDelete = onDelete || false;

  let title = "Edit or delete";
  if (onEdit && !onDelete) {
    title = "Edit the image";
  } else if (!onEdit && onDelete) {
    title = "Delete the image";
  } else if (!onEdit && !onDelete) {
    title = "View the image";
  }

  const handleOnDelete = () => {
    setDeleteMenuOpen(true);
  };

  function toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  async function handleOnDownload(image) {
    const a = document.createElement("a");
    a.href = await toDataURL(image);
    a.download = "thebonsaiproject.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Dialog open={!!image} onClose={onClose} style={{ height: "100%" }}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DeleteMenu
            open={deleteMenuOpen}
            onDelete={onDelete}
            onClose={() => {
              setDeleteMenuOpen(false);
            }}
          />
          <Typography variant="h6">{title}</Typography>
          <Box>
            {onEdit && (
              <IconButton
                onClick={() => {
                  onEdit(image);
                  onClose();
                }}
              >
                <Edit />
              </IconButton>
            )}
            {onDelete && (
              <IconButton onClick={handleOnDelete}>
                <Delete />
              </IconButton>
            )}
            {/* Download button */}
            <IconButton
              onClick={() => {
                handleOnDownload(image);
                onClose();
              }}
            >
              <Download />
            </IconButton>
            {/* close button */}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "6rem",
            height: "30rem",
            border: borderStyle,
            borderRadius: "1rem",
          }}
        >
          <img src={image} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default function ImageMasonryViewEditDelete({ data, onDelete, onEdit }) {
  const [image, setImage] = React.useState(null);

  const currentUser = getAuth().currentUser;

  const handleItemClick = (item, index) => {
    setImage(item);
  };

  const handleClose = () => {
    setImage(null);
  };

  const handleDelete = () => {
    onDelete(image);
    setImage(null);
  };

  const imageElements = data.map((item, index) => {
    if (!item) return null;

    const borderStyle = isOwnerOfUrl(item, currentUser.uid)
      ? "1px solid #ccc"
      : "1px solid green";
    return (
      <ImageListItem
        key={index}
        onClick={() => {
          handleItemClick(item, index);
        }}
        style={{
          height: "auto",
          width: "auto",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
          border: borderStyle,
        }}
      >
        <img
          src={`${item}?w=248&fit=crop&auto=format`}
          // srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={index}
          loading="lazy"
          style={{
            borderRadius: "10px",
          }}
        />
      </ImageListItem>
    );
  });

  // get break points
  const theme = useTheme();
  // set the number of columns based on the current breakpoint

  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const isMdBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgBreakPoint = useMediaQuery(theme.breakpoints.down("xl"));

  // set the number of columns based on the current breakpoint
  let columns = 4;
  if (isXsBreakPoint) {
    columns = 4;
  } else if (isSmBreakPoint) {
    columns = 4;
  } else if (isMdBreakPoint) {
    columns = 5;
  } else if (isLgBreakPoint) {
    columns = 6;
  } else {
    columns = 6;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "1rem",
        overflowX: "hidden",
        overflowY: "hidden",
        textAlign: "-webkit-center",
      }}
    >
      <Box
        style={{
          height: "100%",
        }}
      >
        <ImageViewDialog
          image={image}
          onClose={handleClose}
          onDelete={onDelete ? handleDelete : null}
          onEdit={onEdit}
          isOwner={isOwnerOfUrl(image, currentUser.uid)}
        />
        <ImageList
          variant="woven"
          style={{ paddingRight: "2rem", paddingBottom: "1rem" }}
          cols={columns}
          gap={8}
        >
          {imageElements}
        </ImageList>
      </Box>
    </Box>
  );
}
