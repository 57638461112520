import { getDownloadURL } from "firebase/storage";
import { urlToBlob, urlToBlobAlt } from "../utilities/urlToBlob";
import { uploadFile } from "./firebase/cloudStorage/uploadFile";
import { updateData } from "./firebase/firestore/updateData";
import { uuidv4 } from "../utilities/uuidv4";

export function imageToNewLocation(url, newLocation) {
  /**
   * Turn an image url into a blob, then upload it to a new location
   */
  return new Promise((resolve, reject) => {
    urlToBlobAlt(url, (blob) => {
      // open blob in new window
      // let blobUrl = URL.createObjectURL(blob);
      // window.open(blobUrl);
      uploadFile(
        blob,
        newLocation + uuidv4(),
        (snapshotData) => {
          getDownloadURL(snapshotData.ref)
            .then((url) => {
              resolve(url);
            })
            .catch((error) => {
              console.error("Error uploading image:", url, error);
              reject(error);
            });
        },
        (error) => {
          reject(error);
        }
      );
    });
  });
}

export async function bonsaiImagesToNewLocation(bonsai, user, cb) {
  // return new Promise((resolve, reject) => {
  // get outlines, solutions and variations
  let outlineImages = bonsai.outlines;
  let solutionImages = bonsai.solutions;
  let variationImages = bonsai.variations;

  // Turn each image url into a blob, then upload it to a new location

  let newOutlineImages = [];
  let newSolutionImages = [];
  let newVariationImages = [];

  // upload outline images
  if (!outlineImages) outlineImages = [];
  for (let i = 0; i < outlineImages.length; i++) {
    let outlineImage = outlineImages[i];
    const newOutlineImage = await imageToNewLocation(
      outlineImage,
      `${user.uid}/`
    );
    newOutlineImages.push(newOutlineImage);
  }
  if (!solutionImages) solutionImages = [];
  for (let i = 0; i < solutionImages.length; i++) {
    let solutionImage = solutionImages[i];
    const newSolutionImage = await imageToNewLocation(
      solutionImage,
      `${user.uid}/`
    );
    newSolutionImages.push(newSolutionImage);
  }
  if (!variationImages) variationImages = [];
  for (let i = 0; i < variationImages.length; i++) {
    let variationImage = variationImages[i];
    const newVariationImage = await imageToNewLocation(
      variationImage,
      `${user.uid}/`
    );
    newVariationImages.push(newVariationImage);
  }

  bonsai.outlines = newOutlineImages;
  bonsai.solutions = newSolutionImages;
  bonsai.variations = newVariationImages;

  cb(bonsai);

  // wait for all promises to resolve
  // Promise.all(promises).then((values) => {
  //   newOutlineImages = values;
  //   console.log("newOutlineImages:", newOutlineImages);
  //   // upload solution images
  //   promises = [];

  //   // wait for all promises to resolve
  //   Promise.all(promises).then((values) => {
  //     newSolutionImages = values;
  //     console.log("newSolutionImages:", newSolutionImages);
  //     // upload variation images
  //     // upload variation images
  //     console.log("variationImages:", variationImages);

  //     // wait for all promises to resolve
  //     Promise.all(promises).then((values) => {
  //       newVariationImages = values;
  //       // update bonsai with new image urls
  //       console.log("promises:", promises);
  //       // update bonsai with new image urls
  //       console.log("bonsai:", bonsai);
  //       // resolve(bonsai);
  //     });
  // });
}

// outlineImages.forEach((outlineImage) => {
//   let promise = imageToNewLocation(outlineImage, `${user.uid}/`)
//     .then((newOutlineImage) => {
//       newOutlineImages.push(newOutlineImage);
//       console.log("newOutlineImage:", newOutlineImage);
//     })
//     .catch((error) => {
//       console.error("Error uploading outline image:", error);
//     });
//   promises.push(promise);
// });

// if (!solutionImages) solutionImages = [];
// solutionImages.forEach((solutionImage) => {
//   let promise = imageToNewLocation(solutionImage, `${user.uid}/`)
//     .then((newSolutionImage) => {
//       newSolutionImages.push(newSolutionImage);
//       console.log("oldSolutionImage:", solutionImage);
//       console.log("newSolutionImage:", newSolutionImage);
//     })
//     .catch((error) => {
//       console.error("Error uploading solution image:", error);
//     });
//   promises.push(promise);
// });

// return a promise that resolves when all images have been uploaded

// Promise.all(promises)
//   .then(() => {
//     console.log("promises:", promises);
//     // update bonsai with new image urls
//     bonsai.outlines = newOutlineImages;
//     bonsai.solutions = newSolutionImages;
//     bonsai.variations = newVariationImages;
//     console.log("bonsai:", bonsai);
//     resolve(bonsai);
//   })
//   .catch((error) => {
//     console.error("Error uploading images:", error);
//   });
//   });
// }

export function updateBonsaiImages(
  bonsai,
  newBonsai,
  user,
  successCb,
  errorCb
) {
  bonsaiImagesToNewLocation(newBonsai, user, (updatedBonsai) => {
    // combine new bonsai with old bonsai
    // updatedBonsai = { ...bonsai, ...updatedBonsai };
    // concat new bonsai with old bonsai, outlines, solutions and variations
    let updatedData = {};
    updatedData.outlines = [...updatedBonsai.outlines];
    updatedData.solutions = [...bonsai.solutions, ...updatedBonsai.solutions];
    updatedData.variations = [
      ...bonsai.variations,
      ...updatedBonsai.variations,
    ];
    // return;
    updateData(
      updatedData,
      `users/${user.uid}/bonsai`,
      bonsai.storageDocumentId,
      successCb,
      (e) => {
        console.error("Error updating bonsai:", e);
        errorCb(e);
      }
    );
    // successCb(updatedBonsai);
  });
}
