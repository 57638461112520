import { getAllData } from "./firebase/firestore/getAllData";
import { getData } from "./firebase/firestore/getData";
import { updateData } from "./firebase/firestore/updateData";
import { uploadData } from "./firebase/firestore/uploadData";

export function makeBonsaiPublic(user, bonsai, onUpdateData) {
  let bonsaiId = bonsai.storageDocumentId;
  let updatedData = {
    public: true,
  };
  updateData(
    updatedData,
    `users/${user.uid}/bonsai`,
    bonsaiId,
    () => {
      addBonsaiToRequestList(user, bonsai, () => {
        onUpdateData(`${user.uid}/bonsai/${bonsaiId}`);
      });
    },

    (e) => {
      console.error("couldn't update, something went wrong", e);
    }
  );
}

export function addBonsaiToRequestList(user, bonsai, onUpdatedList) {
  getAllData(
    `designRequests`,
    (requestList) => {
      let bonsaiAlreadyInList = false;
      requestList.forEach((request) => {
        let bonsaiId = request.bonsaiId || request.bonsaiID;
        if (bonsaiId === bonsai.storageDocumentId) {
          bonsaiAlreadyInList = true;
        }
      });

      if (bonsaiAlreadyInList) {
        onUpdatedList();
        return;
      }

      let newRequest = {
        bonsaiId: bonsai.storageDocumentId,
        ownerId: user.uid,
        fronts: [bonsai.fronts[0]],
        dateTimeCreated: new Date(),
      };

      delete newRequest.storageDocumentId;

      uploadData(
        newRequest,
        `designRequests`,
        () => {
          onUpdatedList();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    (err) => {
      console.log(err);
    }
  );
}
