// function using p5js takes in a canvas, copies it to a new image, flood fills the new image, removes everything but the flood fill, and returns the flood fill as a new image
import { copyPixelsToNew, copyCanvasToimage } from "../libs/copyImageToNew";
import { colorMatch } from "./colourMatch";
import { floodFillCanvas } from "./filltool";
import { hexToRgba } from "./hexToRgba";
import { replaceEverythingButColourInImage } from "./replaceColourInImage";

export function floodFillToCanvasAndExtractFlood(
  image,
  x,
  y,
  density,
  colorToMatch
) {
  image.loadPixels();
  floodFillCanvas(image, x, y, 1, colorToMatch);
  image.updatePixels();
  let rgba = hexToRgba(colorToMatch);
  replaceEverythingButColourInImage(
    image,
    rgba,
    {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    0
  );

  image.updatePixels();
  return image;
}
