export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  if (!name) return null;

  // split name by space
  // if name is only one word, split by first letter
  // if name is more than two words, split by first letter of first two words

  let splitName = name.split(" ");
  if (splitName.length === 1) {
    splitName = name.split("");
  } else if (splitName.length > 2) {
    splitName = [splitName[0][0], splitName[1][0]];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${splitName[0]}${splitName[1]}`,
  };
}
