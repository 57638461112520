// React component using MUI components for email textfield
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Email } from "@mui/icons-material";

export default function TextFieldEmail({ ...props }) {
  return (
    <TextField
      label="Email"
      type="email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        ),
      }}
      required
      {...props}
    />
  );
}
