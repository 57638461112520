// React component using MUI components for password textfield
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Lock } from "@mui/icons-material";

export default function TextFieldPassword({ ...props }) {
  return (
    <TextField
      label="Password"
      type="password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Lock />
          </InputAdornment>
        ),
      }}
      required
      {...props}
    />
  );
}
