import { BugReport, Help, People, Share, VideoCall } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import YoutubeVideo from "./Video";

// import { Link } from 'react-router-dom';

export default function BetaReleaseInfo({ startOpen = false }) {
  const [open, setOpen] = React.useState(
    localStorage.getItem("bonsai-beta_release-notes") !== "2.0.0" // Always comes up on android. So dont wanna show until i sort that out
  );

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("bonsai-beta_release-notes", "2.0.0");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography variant={"h4"} gutterBottom>
          Welcome
        </Typography>
        <Typography variant="body1" gutterBottom>
          Welcome to Bonsai Designer, where you can design your own bonsai tree
          step-by-step. Read further for updates and a video on how to use the
          tool. Have fun and enjoy!
        </Typography>

        <Typography variant={"h5"} gutterBottom>
          What's new in v2?
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          Video tutorial walking through an example using the tool
        </Typography> */}

        {/* Unumbered list */}
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <VideoCall />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Video tutorial walkthrough" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Share />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Sharing your designs" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <People />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Help others with their designs" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BugReport />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Fixed many, many bugs" />
          </ListItem>
        </List>
        <YoutubeVideo
          style={{
            marginBottom: "1rem",
          }}
        />
        <Typography gutterBottom>
          If you want to keep in touch feel free to message me on{" "}
          <Link href="https://www.facebook.com/TheBonsaiProject">Facebook</Link>
          .
        </Typography>
        {/* youtube */}
        <Typography gutterBottom>
          Or leave a comment on the{" "}
          <Link href="https://youtu.be/PRncN2ulfFg">youtube video</Link>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
