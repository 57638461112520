import { ThemeProvider } from '@mui/material';
import './App.css';
import BonsaiDesignerApp from './BonsaiDesignerApp';
import theme from "./components/theme/theme";

function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <ImageColorChanger />
  //     </header>
  //   </div>
  // );

  return (
    <ThemeProvider theme={theme}>
      <BonsaiDesignerApp />
    </ThemeProvider>
  );
}

export default App;
