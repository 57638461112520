import { p5ToBlob } from "./p5ToBlob";

export function saveCanvas(
  p,
  bonsaiId,
  property,
  data,
  successCb,
  errorCb,
  fileName
) {
  p.prepareCanvasForSaving();

  function handleOnSuccess() {
    p.restoreCanvasAfterSaving();

    localStorage.setItem("bonsai-design_edited", false);
    if (successCb) {
      successCb();
    }
  }
  // wait half a second for the canvas to be ready
  setTimeout(() => {
    p.canvas.toBlob((blob) => {
      p5ToBlob(
        blob,
        bonsaiId,
        property,
        data,
        handleOnSuccess,
        errorCb,
        fileName
      );
    });
  }, 500);
}
