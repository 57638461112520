import {
  AccountBalance,
  AccountBox,
  Dashboard,
  TravelExplore,
} from "@mui/icons-material";
import ArchiveIcon from "@mui/icons-material/Archive";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RestoreIcon from "@mui/icons-material/Restore";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

export default function BottomBarRoot({ startValue, onChange }) {
  const [value, setValue] = React.useState(startValue);
  const ref = React.useRef(null);

  const navigate = useNavigateToTop();
  React.useEffect(() => {
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);

  const handleOnChange = (event, newValue) => {
    setValue(newValue);

    // Change route based on value
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/community");
    } else if (newValue === 2) {
      navigate("/account");
    }
    // onChange(newValue);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value} onChange={handleOnChange}>
        {/*  Bottom navigation Icons for dashboard, share and account */}
        <BottomNavigationAction label="Dashboard" icon={<Dashboard />} />
        <BottomNavigationAction label="Community" icon={<TravelExplore />} />
        <BottomNavigationAction label="Account" icon={<AccountBox />} />
      </BottomNavigation>
    </Paper>
  );
}
