import { getAuth } from "firebase/auth";
import { uploadImage } from "../libs/firebase/cloudStorage/uploadImage";
import { updateData } from "../libs/firebase/firestore/updateData";
import { uploadData } from "../libs/firebase/firestore/uploadData";
import {
  isUrlForSameFile,
  isUrlInArray,
} from "../libs/firebase/cloudStorage/isUrlForSameFile";
export function p5ToBlob(
  blob,
  bonsaiId,
  property = "outlines",
  data = {},
  successCb = () => {},
  errorCb = () => {},
  fileName
) {
  // get the current user
  const user = getAuth().currentUser;
  let image = {};
  image.file = blob;
  uploadImage(
    image,
    (uploadedImageUrl) => {
      let updatedData = {};
      // if the property already exists, and is an array, push the new image url
      if (data[property] && Array.isArray(data[property])) {
        // if an element in uploadedImageUrl is already in data[property], don't add it
        if (!isUrlInArray(uploadedImageUrl, data[property])) {
          updatedData[property] = [...data[property], uploadedImageUrl];
        } else {
          // Replace it with the new one
          updatedData[property] = data[property].map((url) => {
            if (isUrlForSameFile(url, uploadedImageUrl)) {
              return uploadedImageUrl;
            } else {
              return url;
            }
          });
        }
      } else {
        updatedData[property] = [uploadedImageUrl];
      }
      updateData(
        updatedData,
        `users/${user.uid}/bonsai`,
        bonsaiId,
        successCb,
        (e) => {
          uploadData(
            updatedData,
            `users/${user.uid}/bonsai`,
            () => {
              successCb();
            },
            (e) => {
              errorCb(e);
            }
          );
        }
      );
      return uploadedImageUrl;
    },
    `${user.uid}/`,
    (e) => {
      console.error(e);
    },
    fileName
  );
}
