import { pipeMixins } from "../components/elements/imageEditorVisualisation";
import { actualMousePosition } from "../utilities/actualMousePosition";
import { withWidthHeight } from "./heightWidth";
import { withImage } from "./image";
import { withPosition } from "./position";
import { withRotation } from "./rotation";
import { withTransormable } from "./transformable";
import { withTransformingRectangle } from "./transformingRectangle";

export function createTransformativeImage(image, x = 0, y = 0) {
  const transformImage = pipeMixins(
    withWidthHeight,
    withPosition,
    withRotation,
    withImage,
    withTransormable,
    withTransformingRectangle
  );

  const handleSize = 10;

  transformImage.setImage(image);
  transformImage.setWidth(image.width / 10);
  transformImage.setHeight(image.height / 10);
  transformImage.setX(x);
  transformImage.setY(y);
  transformImage.setMinHeight(10);
  transformImage.setMinWidth(10);
  transformImage.startTransforming();

  transformImage.draw = function (drawingLibrary) {
    drawingLibrary.push();
    drawingLibrary.translate(this.x(), this.y());
    drawingLibrary.rotate(this.rotation());
    drawingLibrary.image(this.image(), 0, 0, this.width(), this.height());

    // If transforming, draw the transform handles
    // Translate from center to top left
    drawingLibrary.translate(-this.width() / 2, -this.height() / 2);

    if (this.isBeingTransformed()) {
      // Draw the transform handles
      drawingLibrary.stroke(255, 0, 0);
      drawingLibrary.strokeWeight(1);
      drawingLibrary.fill(255, 0, 0, 0);
      drawingLibrary.rect(0, 0, this.width(), this.height());

      drawingLibrary.fill(255, 0, 0, 255);
      drawingLibrary.rect(
        -handleSize * 0.5,
        -handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        this.width() - handleSize * 0.5,
        -handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        -handleSize * 0.5,
        this.height() - handleSize * 0.5,
        handleSize,
        handleSize
      );
      drawingLibrary.rect(
        this.width() - handleSize * 0.5,
        this.height() - handleSize * 0.5,
        handleSize,
        handleSize
      );
    }
    drawingLibrary.pop();
  };
  transformImage.onMousePressed = function (vis, drawingLibrary) {
    // Get mouse position
    // const mouseX = drawingLibrary.mouseX;
    // const mouseY = drawingLibrary.mouseY;
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);

    const handleHitBoxScale = 3;

    // If the mouse is over the image, image is drawn from center, then start transforming

    if (
      // Clicking the top left handle
      mouseX >
        this.x() - this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() - this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() - this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() - this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("topLeft");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the top right handle
      mouseX >
        this.x() + this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() + this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() - this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() - this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("topRight");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the bottom left handle
      mouseX >
        this.x() - this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() - this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() + this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() + this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("bottomLeft");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      // Clicking the bottom right handle
      mouseX >
        this.x() + this.width() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseX <
        this.x() + this.width() / 2 + (handleSize * handleHitBoxScale) / 2 &&
      mouseY >
        this.y() + this.height() / 2 - (handleSize * handleHitBoxScale) / 2 &&
      mouseY <
        this.y() + this.height() / 2 + (handleSize * handleHitBoxScale) / 2
    ) {
      this.setDragPointName("bottomRight");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      this.setDragWidthStart(this.width());
      this.setDragHeightStart(this.height());
    } else if (
      mouseX > this.x() - this.width() / 2 &&
      mouseX < this.x() + this.width() / 2 &&
      mouseY > this.y() - this.height() / 2 &&
      mouseY < this.y() + this.height() / 2
    ) {
      this.startTransforming();
      this.setDragPointName("center");
      // start drag
      this.startDragging();
      this.setDragStartX(mouseX);
      this.setDragStartY(mouseY);
      // if (this.isBeingTransformed()) {
      //   this.setDragPointName("center");
      //   // start drag
      //   this.startDragging();
      //   this.setDragStartX(mouseX);
      //   this.setDragStartY(mouseY);
      // } else {
      //   this.startTransforming();
      // }
    } else {
      this.stopTransforming();
      return false;
    }
    return true;
  };

  transformImage.onMouseDragged = function (vis, drawingLibrary) {
    // Get mouse position
    // const mouseX = drawingLibrary.mouseX;
    // const mouseY = drawingLibrary.mouseY;
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);

    if (this.isBeingTransformed()) {
      if (this.isDragging()) {
        // Dragging
        const deltaX = mouseX - this._dragStartX;
        const deltaY = mouseY - this._dragStartY;
        if (this.dragPointName() === "center") {
          this.setX(this.x() + deltaX);
          this.setY(this.y() + deltaY);
          this.setDragStartX(mouseX);
          this.setDragStartY(mouseY);
        } else if (this.dragPointName() === "topLeft") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() - deltaX * 2);
          this.setHeight(this.dragHeightStart() - deltaY * 2);
        } else if (this.dragPointName() === "topRight") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() + deltaX * 2);
          this.setHeight(this.dragHeightStart() - deltaY * 2);
        } else if (this.dragPointName() === "bottomLeft") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() - deltaX * 2);
          this.setHeight(this.dragHeightStart() + deltaY * 2);
        } else if (this.dragPointName() === "bottomRight") {
          // Change the height and width
          this.setX(this.x());
          this.setY(this.y());
          this.setWidth(this.dragWidthStart() + deltaX * 2);
          this.setHeight(this.dragHeightStart() + deltaY * 2);
        }
      }
    } else return false;
    return true;
  };

  transformImage.onMouseReleased = function (vis, drawingLibrary) {
    if (this.isBeingTransformed()) {
      if (this.isDragging()) {
        this.stopDragging();
      }
    }
  };

  return transformImage;
}

/**
 * Load all the base foliage images
 *  - then there are just objects that reference the base images on draw time
 */

export const withImages = {
  images() {
    if (!this._images) {
      this._images = [];
    }
    return this._images;
  },
  addImage(image) {
    if (!this._images) {
      this._images = [];
    }
    this._images.push(image);
  },
  removeImage(image) {
    this._images = this._images.filter((i) => i !== image);
  },
};

export const withSelection = {
  selection() {
    return this._selection;
  },
  setSelection(selection) {
    this._selection = selection;
  },
  clearSelection() {
    this._selection = null;
  },
};
export const withSourceImageManger = {
  sourceImages() {
    if (!this._sourceImages) {
      this._sourceImages = [];
    }
    return this._sourceImages;
  },
  addSourceImage(image) {
    if (!this._sourceImages) {
      this._sourceImages = [];
    }
    this._sourceImages.push(image);
    if (!this.sourceImage()) {
      this.setSourceImage(image);
    }
  },
  removeSourceImage(image) {
    if (!this._sourceImages) {
      this._sourceImages = [];
    }
    this._sourceImages = this._sourceImages.filter((i) => i !== image);
  },
  nextSourceImage() {
    const index = this.sourceImages().indexOf(this.sourceImage());
    if (index === this.sourceImages().length - 1) {
      this.setSourceImage(this.sourceImages()[0]);
      return;
    }
    this.setSourceImage(this.sourceImages()[index + 1]);
  },
  setSourceImage(image) {
    this._sourceImage = image;
  },

  sourceImage() {
    return this._sourceImage;
  },
  imageVariants() {
    return this._imageVariants;
  },
  addImageVariant(image, variant) {
    if (!this._imageVariants) {
      this._imageVariants = {};
    }
    if (!this._imageVariants[image.name]) {
      this._imageVariants[image.name] = [];
    }
    this._imageVariants[image.name].push(variant);
  },
  variantImage() {
    return this._variantImage;
  },
  setVariantImage(image) {
    this._variantImage = image;
  },
  nextVariantImage() {
    // if no variant image set, then set the first one
    if (!this.variantImage()) {
      this.setVariantImage(this.imageVariants()[this.sourceImage().name][0]);
      return;
    }

    const image = this.sourceImage();
    const index = this.imageVariants()[image.name].indexOf(this.variantImage());
    if (index === this.imageVariants()[image.name].length - 1) {
      // unset the variant image
      this.setVariantImage(null);
      return;
    }
    this.setVariantImage(this.imageVariants()[image.name][index + 1]);
  },
};

const withForegroundMidgroundBackground = {
  foreground() {
    if (!this._foreground) {
      this._foreground = [];
    }
    return this._foreground;
  },
  addToForeground(image) {
    if (!this._foreground) {
      this._foreground = [];
    }
    this._foreground.push(image);
  },
  removeFromForeground(image) {
    this._foreground = this._foreground.filter((i) => i !== image);
  },
  midground() {
    if (!this._midground) {
      this._midground = [];
    }
    return this._midground;
  },
  addToMidground(image) {
    if (!this._midground) {
      this._midground = [];
    }
    this._midground.push(image);
  },
  removeFromMidground(image) {
    this._midground = this._midground.filter((i) => i !== image);
  },
  background() {
    if (!this._background) {
      this._background = [];
    }
    return this._background;
  },
  addToBackground(image) {
    if (!this._background) {
      this._background = [];
    }
    this._background.push(image);
  },
  removeFromBackground(image) {
    this._background = this._background.filter((i) => i !== image);
  },
  moveToAnotherLayer(image, layer) {
    if (this.foreground().includes(image)) {
      this.removeFromForeground(image);
    }
    if (this.midground().includes(image)) {
      this.removeFromMidground(image);
    }
    if (this.background().includes(image)) {
      this.removeFromBackground(image);
    }
    if (layer === "foreground") {
      this.addToForeground(image);
    } else if (layer === "midground") {
      this.addToMidground(image);
    } else if (layer === "background") {
      this.addToBackground(image);
    }
  },
  deleteImageFromLayers(image) {
    if (this.foreground().includes(image)) {
      this.removeFromForeground(image);
    }
    if (this.midground().includes(image)) {
      this.removeFromMidground(image);
    }
    if (this.background().includes(image)) {
      this.removeFromBackground(image);
    }
  },
};

const withTriangle = {
  triangle() {
    return this._triangle;
  },
  setTriangle(left, right, top) {
    this._triangle = { left, right, top };
  },
  clearTriangle() {
    this._triangle = null;
  },
  toggleShowTriangle() {
    if (!this._showTriangle) {
      this._showTriangle = false;
    }
    this._showTriangle = !this._showTriangle;
  },
  hideTriangle() {
    this._showTriangle = false;
  },
  showTriangle() {
    return this._showTriangle;
  },
};

export function createFoliageImageManager() {
  // images
  // selected image
  // Events are manageed by this
  const imageManager = pipeMixins(
    withImages,
    withSelection,
    withSourceImageManger,
    withForegroundMidgroundBackground,
    withTriangle
  );

  imageManager.createImage = (drawingLibrary) => {
    if (!imageManager.sourceImage()) {
      return null;
    }
    let xPos = drawingLibrary.width() / 2;
    let yPos = drawingLibrary.height() / 2;
    const newImage = createTransformativeImage(
      imageManager.sourceImage(),
      xPos,
      yPos
    );
    imageManager.addImage(newImage);
    imageManager.addToForeground(newImage);
    return newImage;
  };

  imageManager.drawTriangle = (drawingLibrary) => {
    // get the left, right and top most foliage images
    let countImages = imageManager.images().length;
    let leftMost = imageManager.images().sort((a, b) => a.x() - b.x())[0];
    let rightMost = imageManager.images().sort((a, b) => b.x() - a.x())[0];
    const topMost = imageManager.images().sort((a, b) => b.y() - a.y())[
      countImages - 1
    ];
    const bottomMost = imageManager.images().sort((a, b) => b.y() - a.y())[0];

    if (!leftMost || !rightMost || !topMost) {
      return;
    }

    if (topMost == leftMost) {
      leftMost = bottomMost;
    }

    if (topMost == rightMost) {
      rightMost = bottomMost;
    }

    drawingLibrary.stroke("blue");
    drawingLibrary.strokeWeight(2);
    drawingLibrary.noFill();

    drawingLibrary.triangle(
      leftMost.x(),
      leftMost.y(),
      rightMost.x(),
      rightMost.y(),
      topMost.x(),
      topMost.y()
    );
    // // draw big red circle at the top
    // drawingLibrary.fill("red");
    // drawingLibrary.circle(topMost.x(), topMost.y(), 40);

    // // draw small blue circle at the left
    // drawingLibrary.fill("blue");
    // drawingLibrary.circle(leftMost.x(), leftMost.y(), 20);

    // // draw small blue circle at the right
    // drawingLibrary.fill("green");
    // drawingLibrary.circle(rightMost.x(), rightMost.y(), 20);
  };
  imageManager.drawAll = (drawingLibrary) => {
    // draw background then midground then foreground
    imageManager.drawBackground(drawingLibrary);
    imageManager.drawMidground(drawingLibrary);
    imageManager.drawForeground(drawingLibrary);
  };

  imageManager.drawForeground = (drawingLibrary) => {
    imageManager.foreground().forEach((image) => {
      image.draw(drawingLibrary);
    });
  };

  imageManager.drawMidground = (drawingLibrary) => {
    imageManager.midground().forEach((image) => {
      image.draw(drawingLibrary);
    });
  };

  imageManager.drawBackground = (drawingLibrary) => {
    imageManager.background().forEach((image) => {
      image.draw(drawingLibrary);
    });
  };

  imageManager.incrementSourceImage = () => {
    imageManager.nextSourceImage();
    for (let i = imageManager.images().length - 1; i >= 0; i--) {
      const image = imageManager.images()[i];
      if (image.isBeingTransformed()) {
        image.setImage(imageManager.sourceImage());
        return;
      }
    }
  };

  imageManager.incrementVariantImage = () => {
    imageManager.nextVariantImage();
    for (let i = imageManager.images().length - 1; i >= 0; i--) {
      const image = imageManager.images()[i];
      if (image.isBeingTransformed()) {
        if (!imageManager.variantImage()) {
          image.setImage(imageManager.sourceImage());
          // put image in forerground
          imageManager.moveToAnotherLayer.bind(imageManager)(
            image,
            "foreground"
          );
          return;
        } else {
          // if the image is in the foreground, then move it to the midground
          if (imageManager.foreground().includes(image)) {
            imageManager.moveToAnotherLayer.bind(imageManager)(
              image,
              "midground"
            );
          } else if (imageManager.midground().includes(image)) {
            imageManager.moveToAnotherLayer.bind(imageManager)(
              image,
              "background"
            );
          }
          image.setImage(imageManager.variantImage());
        }
        return;
      }
    }
  };

  imageManager.deselectAll = () => {
    imageManager.images().forEach((image) => {
      image.stopTransforming();
    });
  };

  imageManager.deleteSelectedImage = () => {
    for (let i = imageManager.images().length - 1; i >= 0; i--) {
      const image = imageManager.images()[i];
      if (image.isBeingTransformed()) {
        imageManager.removeImage(image);
        imageManager.deleteImageFromLayers(image);
        return;
      }
    }
  };

  imageManager.howCloseToTransformingCorners = (vis, drawingLibrary, image) => {
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);
    let shortestDistance = null;
    // for each corner, check how close the mouse is to it
    let corners = [
      [image.x() - image.width() / 2, image.y() - image.height() / 2],
      [image.x() + image.width() / 2, image.y() - image.height() / 2],
      [image.x() - image.width() / 2, image.y() + image.height() / 2],
      [image.x() + image.width() / 2, image.y() + image.height() / 2],
    ];
    // for each corner, check how close the mouse is to it
    for (let i = 0; i < corners.length; i++) {
      const corner = corners[i];
      const distance = Math.sqrt(
        Math.pow(mouseX - corner[0], 2) + Math.pow(mouseY - corner[1], 2)
      );
      if (shortestDistance == null || distance < shortestDistance) {
        shortestDistance = distance;
      }
    }
    return shortestDistance;
  };

  imageManager.howCloseToImage = (vis, drawingLibrary, image) => {
    const [mouseX, mouseY] = actualMousePosition(vis, drawingLibrary);
    const distance = Math.sqrt(
      Math.pow(mouseX - image.x(), 2) + Math.pow(mouseY - image.y(), 2)
    );
    return distance;
  };

  imageManager.closestImageChecker = (
    vis,
    drawingLibrary,
    images,
    closestCenter,
    closestDistance
  ) => {
    for (let i = images.length - 1; i >= 0; i--) {
      const image = images[i];

      let distance = imageManager.howCloseToImage(vis, drawingLibrary, image);

      // TODO: if the image is being transformed, check its corners aswell
      if (image.isBeingTransformed()) {
        let cornerDistance = imageManager.howCloseToTransformingCorners(
          vis,
          drawingLibrary,
          image
        );
        if (cornerDistance < distance) {
          distance = cornerDistance;
        }
      }

      if (closestDistance == null || distance < closestDistance - 20) {
        if (closestCenter) {
          closestCenter.stopTransforming();
        }
        closestCenter = image;
        closestDistance = distance;
      } else {
        image.stopTransforming();
      }
    }

    return [closestCenter, closestDistance];
  };

  imageManager.onMousePressed = (vis, drawingLibrary) => {
    // trigger onMousepressed on foreground, then midground, then background
    // if any of them return true, then break
    let clickConsumed = false;

    /**
     * TODO:
     * 1. loop through all the images and get the closest center
     * 2. if it it is closer than the last closest center, then set it as the closest center and deselect the last closest center
     * 3. when the final closest center is found, run its onMousePressed function
     */

    let closestCenter = null;
    let closestDistance = null;

    [closestCenter, closestDistance] = imageManager.closestImageChecker(
      vis,
      drawingLibrary,
      imageManager.foreground(),
      closestCenter,
      closestDistance
    );

    [closestCenter, closestDistance] = imageManager.closestImageChecker(
      vis,
      drawingLibrary,
      imageManager.midground(),
      closestCenter,
      closestDistance
    );

    [closestCenter, closestDistance] = imageManager.closestImageChecker(
      vis,
      drawingLibrary,
      imageManager.background(),
      closestCenter,
      closestDistance
    );
    if (closestCenter) {
      clickConsumed = closestCenter.onMousePressed(vis, drawingLibrary);
      if (!clickConsumed) {
        closestCenter.stopTransforming();
      }
    }

    return clickConsumed;
  };

  imageManager.onMouseDragged = (vis, drawingLibrary) => {
    for (let i = imageManager.images().length - 1; i >= 0; i--) {
      const image = imageManager.images()[i];
      if (image.onMouseDragged(vis, drawingLibrary)) break;
    }
  };
  imageManager.onMouseReleased = (vis, drawingLibrary) => {
    for (let i = imageManager.images().length - 1; i >= 0; i--) {
      const image = imageManager.images()[i];
      if (image.onMouseReleased(vis, drawingLibrary)) break;
    }
  };
  return imageManager;
}

export const withFoliageImageManager = {
  foliageImageManager() {
    if (!this._foliageImageManager)
      this._foliageImageManager = createFoliageImageManager();
    return this._foliageImageManager;
  },
  setFoliageImageManager(foliageImageManager) {
    this._foliageImageManager = foliageImageManager;
  },
};

// export const withFoliageImageManager = {
//   foregroundFoliageImages() {
//     return this._foregroundFoliageImages;
//   },
//   backgroundFoliageImages() {
//     return this._backgroundFoliageImages;
//   },
//   midgroundFoliageImages() {
//     return this._midgroundFoliageImages;
//   },
//   addForegroundFoliageImage(foliageImage) {
//     this._foregroundFoliageImages.push(foliageImage);
//   },

//   addBackgroundFoliageImage(foliageImage) {
//     this._backgroundFoliageImages.push(foliageImage);
//   },
//   addMidgroundFoliageImage(foliageImage) {
//     this._midgroundFoliageImages.push(foliageImage);
//   },
// };
