import { uuidFromUrl } from "../../../utilities/uuidFromUrl";
import { updateData } from "../firestore/updateData";
import { deleteFile } from "./deleteFile";

export function deleteDocReferencedImage(
  updatedData,
  image,
  referenceId,
  docId,
  cb = () => {},
  failCb = () => {},
  shouldDeleteFile = true
) {
  // update the document to remove the image reference
  updateData(updatedData, referenceId, docId, cb, (e) => {
    if (failCb) {
      failCb(e);
    }
  });
  if (!shouldDeleteFile) {
    return;
  }
  // delete the image from storage
  deleteFile(`${uuidFromUrl(image)}`, cb, failCb);
}
