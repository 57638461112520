export function drawVaryingWidthLine(
  drawingLibrary,
  x1,
  y1,
  x2,
  y2,
  startWidth,
  endWidth
) {
  const segments = 10;
  let prev_loc_x = x1;
  let prev_loc_y = y1;
  for (let i = 1; i <= segments; i++) {
    let cur_loc_x = drawingLibrary.lerp(x1, x2, i / segments);
    let cur_loc_y = drawingLibrary.lerp(y1, y2, i / segments);
    drawingLibrary.push();
    drawingLibrary.strokeWeight(
      drawingLibrary.lerp(startWidth, endWidth, i / segments)
    );
    drawingLibrary.line(prev_loc_x, prev_loc_y, cur_loc_x, cur_loc_y);
    drawingLibrary.pop();
    prev_loc_x = cur_loc_x;
    prev_loc_y = cur_loc_y;
  }
}
