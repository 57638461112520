import { useLocation, useNavigate } from "react-router-dom";

/** Navigate to the top of a page so that the scroll position isn't persisted between pages. Use this instead of React Dom's build-in @see {@link useNavigate}. */
export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  const navigateAndReset = (to) => {
    if (hasPreviousState) {
      navigate(to);
    } else {
      if (to == -1) {
        navigate("/");
      } else {
        navigate(to);
      }
    }
    window.scrollTo(0, 0);
  };

  return navigateAndReset;
};
