import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarOthersProfile from "../components/AppBar/OthersProfile";
import AppBarProfile from "../components/AppBar/Profile";
import AppBarSuggestionProfile from "../components/AppBar/SuggestionProfile";
import DesignProfile from "../components/DesignProfile";
import DesignSuggestionProfile from "../components/DesignSuggestionProfile";
import LoadingButtonCopyLinkBonsai from "../components/LoadingButton/CopyLinkBonsai";
import LoadingButtonShareBonsai from "../components/LoadingButton/ShareBonsai";
import TitleDescriptionAction from "../components/TitleDescription/Action";
import { bonsaiToPdf } from "../libs/bonsaiToPdf";
import { getAllData } from "../libs/firebase/firestore/getAllData";
import { getData } from "../libs/firebase/firestore/getData";
import { uploadDataWithName } from "../libs/firebase/firestore/uploadDataWithName";
import { sendDesignSuggestion } from "../libs/sendDesignSuggestion";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { bonsaiToSuggestedImagesObject } from "../utilities/bonsaiToSuggestedImages";
import { getDataFirebaseRecursion } from "../utilities/getAllDataRecursion";
import { isOwnerOfBonsai } from "../utilities/isOwnerOfBonsai";
import { useTheme } from "@emotion/react";

function shareButton(isOwner, bonsai, user, onShare) {
  if (isOwner && !bonsai.public) {
    return (
      <Box style={{ marginBottom: "1rem" }}>
        <LoadingButtonShareBonsai
          user={user}
          bonsai={bonsai}
          onShare={onShare}
        />
      </Box>
    );
  }
  return (
    <Box style={{ marginBottom: "1rem" }}>
      <LoadingButtonCopyLinkBonsai />
    </Box>
  );
}

const ScreenBonsaiProfile = () => {
  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const [designRequests, setDesignRequests] = React.useState([]);
  const [designSuggestions, setDesignSuggestions] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { bonsaiId, userId } = useParams();
  let currentUser = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBonsaiClicked = (type, item) => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/${type}`);
    } else {
      navigate(`/bonsai/${bonsaiId}/${type}`);
    }
  };

  const handleNewClicked = (type) => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/create/${type}`);
    } else {
      navigate(`/bonsai/${bonsaiId}/create/${type}`);
    }
  };

  const handleSeeAllDesignRequests = () => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/designRequests`);
    } else {
      navigate(`/bonsai/${bonsaiId}/designRequests`);
    }
  };

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);

    /**
     * Get any designRequests for this bonsai
     */

    getDataFirebaseRecursion(
      userId,
      hangleGotDesignRequests,
      getAllData,
      `designRequests`
    );
  };

  const hangleGotDesignRequests = (data) => {
    // only get the design requests for this bonsai
    data = data.filter((item) => item.bonsaiId === bonsaiId);
    setDesignRequests(data);
    getDataFirebaseRecursion(
      userId,
      handleGotDesignSuggestions,
      getAllData,
      `designSuggestions`
    );
  };

  const handleGotDesignSuggestions = (data) => {
    // only get the design requests for this bonsai
    data = data.filter((item) => item.bonsaiId === bonsaiId);
    setDesignSuggestions(data);
  };

  const handleOnCompare = () => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/compare`);
    } else {
      navigate(`/bonsai/${bonsaiId}/compare`);
    }
  };

  const getBonsaiData = () => {
    if (userId) {
      getData(`users/${userId}/bonsai/${bonsaiId}`, handleGotBonsaiData);
    } else if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  };

  const handleGetDataFailed = (error) => {
    setError("Hey, looks like you aren't allowed to see this bonsai. Sorry!");
  };

  // get the bonsai data
  useEffect(() => {
    getDataFirebaseRecursion(
      userId,
      handleGotBonsaiData,
      getData,
      `bonsai/${bonsaiId}`,
      1000,
      handleGetDataFailed
    );
  }, []);

  const handleOnSavePdf = (callback) => {
    bonsaiToPdf(selectedBonsai, callback);
    return;
  };
  const handleAdviceRequest = () => {
    /**
     * What happens when they accept to help the person
     * 1. Check don't already have in a suggestions folder
     * 1. File is created in their design suggestions folder
     *   - contains the bonsaiID
     *   - contains the ownerId
     *   - contains the fronts
     *   - contains if been sent = false
     * 2. Need to make the files readable by others? (Maybe later)
     */

    // Get the owners name
    getData(
      `users/${userId}`,
      (data) => {
        const designSuggestion = {
          ...selectedBonsai,
          bonsaiId: bonsaiId,
          ownerId: userId,
          ownerName: data.name,
          isSuggestion: true,
        };

        delete designSuggestion.storageDocumentId;
        delete designSuggestion.public;
        delete designSuggestion.bonsaiID;
        delete designSuggestion.ownerID;

        // Create file using firebase
        uploadDataWithName(
          designSuggestion,
          `users/${currentUser.uid}/bonsai`,
          bonsaiId,
          () => {
            navigate(`/bonsai/${bonsaiId}`);
          },
          (error) => {
            console.log("Error", error);
          }
        );
      },
      (error) => {
        console.log("Error", error);
      }
    );
  };

  const handleSendSuggestion = () => {
    /**
     * TODO:
     *  + Create file in owners request folder
     *  + Create file in designers suggestion folder
     *
     */

    sendDesignSuggestion(
      selectedBonsai,
      selectedBonsai.ownerId || selectedBonsai.ownerID,
      currentUser.uid,
      bonsaiId,
      () => {
        // reload page
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    );
  };

  const handleOnBack = () => {};

  const title = (userId && "Their design") || "Your design";

  const isOwner = isOwnerOfBonsai(selectedBonsai, currentUser);

  const isProfile = !userId || (currentUser && currentUser.uid === userId);

  const isSuggestion = isProfile && !isOwner;
  const suggestionImages = bonsaiToSuggestedImagesObject(
    selectedBonsai,
    currentUser ? currentUser.uid : null
  );

  // status must be pending
  const numberofNewDesignRequests = designRequests.filter(
    (item) => item.status === "pending"
  ).length;

  let hasPendingDesignRequest =
    isOwner && designRequests && numberofNewDesignRequests;

  function isPending() {
    if (designSuggestions == null) {
      return null;
    }
    if (!designSuggestions) {
      return false;
    }

    // return true if any are pending
    const pendingRequests = designSuggestions.filter(
      (item) => item.status === "pending"
    );

    return !!pendingRequests.length;
  }

  function isSendAllowed() {
    if (!designSuggestions) {
      return true;
    }

    // cannot have any pending requests
    const pendingRequests = designSuggestions.filter(
      (item) => item.status === "pending"
    );

    if (pendingRequests.length) {
      return false;
    }

    // cannot have 3 denied requests
    const deniedRequests = designSuggestions.filter(
      (item) => item.status === "denied"
    );

    if (deniedRequests.length >= 3) {
      return false;
    }
    return true;
  }

  const handleOnShare = () => {
    getDataFirebaseRecursion(
      userId,
      handleGotBonsaiData,
      getData,
      `bonsai/${bonsaiId}`,
      1000,
      handleGetDataFailed
    );
  };

  return (
    <Box>
      {isOwner && (
        <AppBarProfile
          onBack={handleOnBack}
          title={title}
          label="design"
          onCompare={handleOnCompare}
          onSavePdf={handleOnSavePdf}
        />
      )}
      {!isOwner && !isSuggestion && (
        <AppBarOthersProfile
          onBack={handleOnBack}
          title={title}
          label="design"
          onAdviceRequest={handleAdviceRequest}
        />
      )}
      {isSuggestion && (
        <AppBarSuggestionProfile
          suggestionImages={suggestionImages}
          onSendSuggestion={handleSendSuggestion}
          bonsai={selectedBonsai}
          sendAllowed={isSendAllowed()}
          isPending={isPending()}
        />
      )}
      <Box
        style={{
          padding: "1rem",
          textAlign: isXsBreakPoint ? "-webkit-center" : "unset",
        }}
      >
        {error && <Typography variant="h5">{error}</Typography>}

        {shareButton(isOwner, selectedBonsai, currentUser, handleOnShare)}
        {hasPendingDesignRequest ? (
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <TitleDescriptionAction
                description={`You have ${numberofNewDesignRequests} new design${
                  numberofNewDesignRequests > 1 ? "s" : ""
                } to look at`}
                actionText={"See all"}
                onClick={handleSeeAllDesignRequests}
              />
            </Grid>
          </Grid>
        ) : null}
        {!isSuggestion && (
          <DesignProfile
            bonsai={selectedBonsai}
            bonsaiId={bonsaiId}
            onClick={handleBonsaiClicked}
            onNewClick={handleNewClicked}
            onUpdateData={getBonsaiData}
            user={currentUser}
            userId={userId}
          />
        )}
        {isSuggestion && (
          <DesignSuggestionProfile
            bonsai={selectedBonsai}
            bonsaiId={bonsaiId}
            onClick={handleBonsaiClicked}
            onNewClick={handleNewClicked}
            onUpdateData={getBonsaiData}
            user={currentUser}
            userId={userId}
            sendAllowed={isSendAllowed()}
            isPending={isPending()}
          />
        )}
      </Box>
    </Box>
  );
};

export default ScreenBonsaiProfile;
