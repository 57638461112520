import { Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import FloodFillCorrectionGif from "../../images/gifs/floodFillCorrection.gif";

// import { Link } from 'react-router-dom';

export default function InfoAlertCannotFill({ onClose }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography variant={"h4"} gutterBottom>
          Wait a second!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your design saved but it looks like you have some gaps in your
          outline! We need to connect all the gaps so we can fill it with
          colour.
        </Typography>

        <Typography variant="body1" gutterBottom></Typography>
        <Typography variant="body1" gutterBottom>
          Maybe it's the roots of your tree? Or a gap in the branches? Either
          way use the pen to close them off.
        </Typography>

        <img
          width="100%"
          style={{ marginBottom: "1rem" }}
          src={FloodFillCorrectionGif}
          alt="Tapered drawing"
        />

        <Typography gutterBottom>
          I will continue making this easier to use and more intuitive. If you
          have any thoughts or ideas, please message me on {/* reddit */}{" "}
          <Link href="https://www.reddit.com/user/TheBonsaiProject">
            Reddit
          </Link>
          , {/* bonsainut */}
          <Link href="https://www.bonsainut.com/members/thebonsaiproject.44250/">
            Bonsai Nut
          </Link>
          {" or "}
          <Link href="https://www.facebook.com/TheBonsaiProject">Facebook</Link>
          .
        </Typography>

        <Typography gutterBottom>
          Thanks{" "}
          <span
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              color: "green",
              fontStyle: "italic",
            }}
          >
            exitsanity
          </span>{" "}
          for the feedback!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
