/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Typography } from "@mui/material";
import React from "react";

export default function TitleDescription(props) {
  const { title, description, note, style, children, size = "small" } = props;
  let subVariant = "caption";
  if (size === "large") {
    subVariant = "body1";
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          //   justifyContent: "center",
          paddingRight: ".5rem",
          flex: 3,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant={subVariant} align="left" gutterBottom>
          {description}
        </Typography>
        {note && <Typography variant="caption">{note}</Typography>}
        {children}
      </Box>
    </Box>
  );
}
