import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../libs/firebase/firestore/getData";
import { getDataFirebaseRecursion } from "../../utilities/getAllDataRecursion";
import ScreenDesignRequest from "../DesignRequest";
import { getAllData } from "../../libs/firebase/firestore/getAllData";

/**
 *
 * This route displays the design requests for a bonsai. Except instead off a single image with a list that doesnt really work,
 * it just shows the newest design request and lets them accept or deny. When done that then shows the next one.
 *
 * This makes most sense.
 */

export function ScreenDesignRequests({
  property,
  title,
  onEdit,
  enableDelete = true,
  userId,
}) {
  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const [requests, setRequests] = React.useState([]);
  const { bonsaiId } = useParams();

  let currentUser = getAuth().currentUser;

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };

  const handleGotRequetsData = (data) => {
    // get the requests that are for this bonsai
    let requests = data.filter(
      (request) =>
        request.bonsaiId === bonsaiId || request.bonsaiID === bonsaiId
    );

    // get only those that are pending
    requests = requests.filter((request) => request.status === "pending");

    // get only the storageDocumentIds
    requests = requests.map((request) => request.storageDocumentId);

    setRequests(requests);
  };

  const handleOnClick = (requestId, accepted) => {
    // remove the request from the list
    let newRequests = requests.filter((request) => request !== requestId);
    setRequests(newRequests);
  };

  useEffect(() => {
    getDataFirebaseRecursion(
      userId,
      handleGotRequetsData,
      getAllData,
      `designRequests`
    );
  }, []);

  return (
    <ScreenDesignRequest requestID={requests[0]} onClick={handleOnClick} />
  );
}
