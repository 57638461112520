/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MultiImageCarousel from "./MultiImageCarousel";
import BonsaiMasonryDesignListStable from "./BonsaiMasonryDesignListStable";
import PrimaryButton from "./PrimaryButton";
function NewItemButton({ onClick, ...props }) {
  return (
    <PrimaryButton onClick={onClick} {...props}>
      Create
    </PrimaryButton>
  );
}
export default function TitleDescriptionImageMosaic(props) {
  const {
    title,
    description,
    images,
    style,
    onClick,
    allowNew = true,
    canAction = false,
    onNewItemClick,
    isOwner,
  } = props;

  let showAction = canAction && allowNew;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        height: "100%",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "center",
          // paddingRight: ".5rem",
          // height: "100%",
          flex: 1,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant="caption" align="left">
          {description}
        </Typography>
        {showAction && (
          <NewItemButton
            style={{
              alignSelf: "start",
              margin: "0",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
            onClick={onNewItemClick}
          />
        )}
      </Box>
      <Box
        style={{ flex: 3, height: "100%", width: "100%", textAlign: "left" }}
      >
        <BonsaiMasonryDesignListStable
          size="large"
          style={{ padding: 0 }}
          data={images}
          onClick={onClick}
          isOwner={isOwner}
        />
      </Box>
    </Box>
  );
}
