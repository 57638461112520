import { Close } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import AppBarRoot from "./Root";

function AppBarClose({ title, onClose }) {
  return (
    <AppBarRoot
      startAction={
        <Stack direction={"row"} spacing={1}>
          <IconButton color="secondary" onClick={onClose}>
            <Close />
          </IconButton>
          <Typography
            variant="h5"
            style={{ alignSelf: "center", color: "white" }}
          >
            {title}
          </Typography>
        </Stack>
      }
    />
  );
}

export default AppBarClose;
