import { getAuth } from "firebase/auth";

export function getDataFirebaseRecursion(
  userId,
  handleGotBonsaiData,
  getDataFunc,
  afterUsersString,
  retryTime = 1000,
  failCb = () => {}
) {
  if (userId) {
    getDataFunc(
      `users/${userId}/${afterUsersString}`,
      handleGotBonsaiData,
      failCb
    );
  } else if (getAuth().currentUser) {
    getDataFunc(
      `users/${getAuth().currentUser.uid}/${afterUsersString}`,
      handleGotBonsaiData,
      failCb
    );
  } else {
    setTimeout(() => {
      if (getAuth().currentUser) {
        getDataFunc(
          `users/${getAuth().currentUser.uid}/${afterUsersString}`,
          handleGotBonsaiData,
          failCb
        );
      }
    }, retryTime);
  }
}
