import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import * as React from "react";

export default function ImageMasonry({ data, onClick }) {
  const handleItemClick = (item, index) => {
    onClick(item, index);
  };

  const imageElements = data.map((item, index) => {
    if (!item) return null;
    return (
      <ImageListItem
        key={index}
        onClick={() => {
          handleItemClick(item, index);
        }}
        style={{
          height: "auto",
          width: "auto",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
          border: "1px solid #ccc",
        }}
      >
        <img
          src={`${item}?w=248&fit=crop&auto=format`}
          // srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={index}
          loading="lazy"
          style={{
            borderRadius: "10px",
          }}
        />
      </ImageListItem>
    );
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "1rem",
        overflowX: "hidden",
        overflowY: "hidden",
        textAlign: "-webkit-center",
      }}
    >
      <Box style={{}}>
        <ImageList variant="woven" cols={2} gap={8}>
          {imageElements}
        </ImageList>
      </Box>
    </Box>
  );
}
