import { Masonry } from "@mui/lab";
import {
  Badge,
  ImageListItemBar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";

export default function DashboardList({
  data,
  onClick,
  padding = "1rem",
  isOwner = false,
  size = "small",
  style,
}) {
  /**
   * Data comes as an array of objects with the following properties:
   * image: string
   * title: string
   * subtitle: string
   * badge: string
   * isOwner: boolean
   *
   */

  const imageElements = data.map((item, index) => {
    let boxShadow = item.disabled
      ? "unset"
      : "4px 4px 0px 0px rgba(0,0,0,0.75)";
    let children = (
      <div
        key={index}
        onClick={() => (onClick ? onClick(item, index) : null)}
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: boxShadow,
          // filter: item.disabled ? "grayscale(50%)" : "unset",
          border:
            item.image == tutorialBonsai.fronts[0]
              ? "1px solid #E46540"
              : "1px solid #ccc",
        }}
      >
        <img
          src={`${item.image}?w=162&auto=format`}
          // srcSet={`${item}?w=162&auto=format&  dpr=2 2x`}
          alt={index}
          loading="lazy"
          style={{
            display: "block",
            width: "100%",
            borderRadius: "10px",
            border: getBorderStyle(item.isOwner),
          }}
        />
        {item.title || item.subtitle ? (
          <ImageListItemBar
            title={item.title}
            subtitle={<span>{item.subtitle}</span>}
            position="below"
            style={{ paddingTop: ".5rem" }}
          />
        ) : null}
      </div>
    );
    return item.badge ? (
      <Badge
        key={index}
        color="primary"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={item.badge}
      >
        {children}
      </Badge>
    ) : (
      children
    );
  });

  // get break points
  const theme = useTheme();
  // set the number of columns based on the current breakpoint

  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const isMdBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgBreakPoint = useMediaQuery(theme.breakpoints.down("xl"));

  let smallColumns = 2;
  let mediumColumns = 4;
  let largeColumns = 6;

  if (size == "large") {
    smallColumns = 2;
    mediumColumns = 2;
    largeColumns = 3;
  }

  // set the number of columns based on the current breakpoint
  let columns = mediumColumns;
  if (isXsBreakPoint) {
    columns = smallColumns;
  } else if (isSmBreakPoint) {
    columns = mediumColumns;
  } else if (isMdBreakPoint) {
    columns = mediumColumns;
  } else if (isLgBreakPoint) {
    columns = largeColumns;
  } else {
    columns = largeColumns;
  }

  function getBorderStyle(isOwner) {
    return isOwner ? "1px solid #E46540" : "1px solid #ccc";
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: padding,
        overflowX: "hidden",
        overflowY: "hidden",
        textAlign: "-webkit-center",
        ...style,
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Masonry columns={columns} spacing={2} style={{ padding: "0rem" }}>
          {imageElements}
        </Masonry>
      </Box>
    </Box>
  );
}
