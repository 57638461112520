import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { Fragment } from "react";
import ScreenInstructionFront from "../screens/instruction/Front";
import ScreenInstructionOutline from "../screens/instruction/Oultine";
import ScreenInstructionSolution from "../screens/instruction/Solution";
import IconInformation from "@mui/icons-material/Info";
import ScreenInstructionVariations from "../screens/instruction/Variations";
import ScreenInstructionDesign from "../screens/instruction/Design";
import ScreenInstructionDashboard from "../screens/instruction/Dashboard";

function Instructions({ label }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOnInformation = () => {
    setDialogOpen(true);
  };
  return (
    <Fragment>
      <InstructionDialog
        label={label}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
      <IconButton color="secondary" onClick={handleOnInformation}>
        <IconInformation />
      </IconButton>
    </Fragment>
  );
}

function InstructionDialog({ label, open, onClose }) {
  let screen = null;
  switch (label) {
    case "fronts":
      screen = <ScreenInstructionFront onClose={onClose} />;
      break;
    case "outlines":
      screen = <ScreenInstructionOutline onClose={onClose} />;
      break;
    case "solutions":
      screen = <ScreenInstructionSolution onClose={onClose} />;
      break;
    case "variations":
      screen = <ScreenInstructionVariations onClose={onClose} />;
      break;
    case "design":
      screen = <ScreenInstructionDesign onClose={onClose} />;
      break;
    case "dashboard":
      screen = <ScreenInstructionDashboard onClose={onClose} />;
      break;
    default:
      screen = null;
      break;
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      {/* <DialogTitle>{label}</DialogTitle> */}
      <DialogContent style={{ padding: "0" }}>{screen}</DialogContent>
    </Dialog>
  );
}

export default Instructions;
