import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

export default function BackdropExecutingAction({
  status,
  loader = true,
  open,
  handleClose,
}) {
  return (
    <div style={{}}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
        style={{ flexDirection: "column" }}
      >
        {status && (
          <Typography style={{ padding: "10px" }} variant="h3" align="center">
            {status}
          </Typography>
        )}
        {loader && <CircularProgress align="center" color="inherit" />}
      </Backdrop>
    </div>
  );
}
