export function p5ImageMaskUsingGraphics(drawingLibrary, _content, _mask) {
  //Create the mask as image
  var img = drawingLibrary.createImage(_mask.width, _mask.height);
  img.copy(
    _mask,
    0,
    0,
    _mask.width,
    _mask.height,
    0,
    0,
    _mask.width,
    _mask.height
  );
  console.timeStamp();
  //load pixels
  img.loadPixels();
  for (var i = 0; i < img.pixels.length; i += 4) {
    // 0 red, 1 green, 2 blue, 3 alpha
    // Assuming that the mask image is in grayscale,
    // the red channel is used for the alpha mask.
    // the color is set to black (rgb => 0) and the
    // alpha is set according to the pixel brightness.
    var v = img.pixels[i];
    img.pixels[i] = 0;
    img.pixels[i + 1] = 0;
    img.pixels[i + 2] = 0;
    img.pixels[i + 3] = v;
  }
  img.updatePixels();

  //convert _content from pg to image
  var contentImg = drawingLibrary.createImage(_content.width, _content.height);

  contentImg.copy(
    _content,
    0,
    0,
    _content.width,
    _content.height,
    0,
    0,
    _content.width,
    _content.height
  );
  // create the mask
  contentImg.mask(img);
  // return the masked image
  return contentImg;
}
