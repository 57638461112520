import { Box, Grid, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarDesignRequest from "../components/AppBar/DesignRequest";
import LoadingButtonShareBonsai from "../components/LoadingButton/ShareBonsai";
import TitleDescriptionCompareImage from "../components/TitleDescriptionCompareImage";
import TitleDescriptionImageMosaic from "../components/TitleDescriptionImageMosaic";
import { getData } from "../libs/firebase/firestore/getData";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { bonsaiToSuggestedImages } from "../utilities/bonsaiToSuggestedImages";
import { getDataFirebaseRecursion } from "../utilities/getAllDataRecursion";
import { updateBonsaiImages } from "../libs/imageToNewLocation";
import { updateData } from "../libs/firebase/firestore/updateData";
const ScreenDesignRequest = ({ onClick, requestID }) => {
  const [request, setRequest] = React.useState(null);
  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const [error, setError] = React.useState(null);

  let { requestId, userId } = useParams();
  if (!requestId) {
    requestId = requestID;
  }

  let currentUser = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };

  const handleGetDataFailed = (error) => {
    setError("Hey, looks like you aren't allowed to see this bonsai. Sorry!");
    console.log(error);
  };

  const handleGotRequestData = (data) => {
    setRequest(data);
    let bonsaiId = data.bonsaiId || data.bonsaiID;
    // get the bonsai data
    if (bonsaiId) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData,
        handleGetDataFailed
      );
    }
  };

  const retryUseEffect = (requestId) => {
    currentUser = getAuth().currentUser;
    getDataFirebaseRecursion(
      currentUser.uid,
      handleGotRequestData,
      getData,
      `designRequests/${requestId}`,
      1000,
      handleGetDataFailed
    );
  };

  // get the bonsai data
  useEffect(() => {
    if (!currentUser || !requestId) {
      // wait 2 seconds and try again
      setTimeout(retryUseEffect.bind(this, requestId), 2000);
    } else {
      getDataFirebaseRecursion(
        currentUser.uid,
        handleGotRequestData,
        getData,
        `designRequests/${requestId}`,
        1000,
        handleGetDataFailed
      );
    }
  }, [requestId, currentUser]);

  const handleOnBack = () => {};

  const title = "Do you want to intergrate these changes?"; //(userId && "Their design") || "Your design";

  const isOwner = (selectedBonsai && !selectedBonsai.isSuggestion) || false;
  const isProfile = !userId || (currentUser && currentUser.uid === userId);

  const isSuggestion = isProfile && !isOwner;
  const suggestionImages = bonsaiToSuggestedImages(
    selectedBonsai,
    currentUser ? currentUser.uid : null
  );

  const newOutline = request?.outlines?.[0];
  let newStructures = request?.solutions;
  let newFoliages = request?.variations;

  // if newFoliages is empty then set to false
  newFoliages = newFoliages && newFoliages.length > 0 ? newFoliages : false;
  // if structures is empty then set to false
  newStructures =
    newStructures && newStructures.length > 0 ? newStructures : false;
  const handleOnSave = (successCb, failCb) => {
    /**
     * Upload the images to the owners profile
     * Add urls to owners bonsai file
     * Mark the dessign request as accepted (people get requests)
     * Mark the suggestion as accepted (people suggest to others)
     */
    // upload the images to the owners profile and add ti owners bonsai file
    updateBonsaiImages(
      selectedBonsai,
      request,
      currentUser,
      () => {
        acceptDesignRequest(successCb, failCb);
      },
      (error) => {
        console.error(error);
        setError(error);
      }
    );
  };

  function acceptDesignRequest(successCb, failCb) {
    /**
     * Mark the dessign request as accepted (people get requests)
     *  + Mark in the owners designRequests folder
     */
    let accpetedStatus = {
      status: "accepted",
    };
    updateData(
      accpetedStatus,
      `users/${currentUser.uid}/designRequests`,
      requestId,
      () => {
        acceptDesignSuggestion(successCb, failCb);
      },
      (error) => {
        console.log(error);
        setError(error);
        failCb(error);
      }
    );
  }

  function acceptDesignSuggestion(successCb, failCb) {
    /**
     * Mark the suggestion as accepted (people suggest to others)
     * + Mark in the designers designSuggestions folder
     */
    let accpetedStatus = {
      status: "accepted",
    };
    updateData(
      accpetedStatus,
      `users/${request.designerId}/designSuggestions`,
      requestId,
      () => {
        // go back
        successCb();
        navigate(-1);
        // alert("Design Accepted! You can now view it in your profile.");
      },
      (error) => {
        console.error(error);
        failCb(error);
        setError(error);
      }
    );
  }

  function declineDesignSuggestion(successCb, failCb) {
    /**
     * mark suggestion as declined
     */
    let accpetedStatus = {
      status: "declined",
    };
    updateData(
      accpetedStatus,
      `users/${request.designerId}/designSuggestions`,
      requestId,
      () => {
        // go back
        successCb();
        navigate(-1);
        // alert("Design Accepted! You can now view it in your profile.");
      },
      (error) => {
        console.log(error);
        failCb(error);
        setError(error);
      }
    );
  }

  function declineDesignRequest(successCb, failCb) {
    let accpetedStatus = {
      status: "declined",
    };
    updateData(
      accpetedStatus,
      `users/${currentUser.uid}/designRequests`,
      requestId,
      () => {
        declineDesignSuggestion(successCb, failCb);
      },
      (error) => {
        console.log(error);
        failCb(error);
        setError(error);
      }
    );
  }

  const handleOnDecline = (successCb, failCb) => {
    declineDesignRequest(successCb, failCb);
  };
  return (
    <Box>
      <AppBarDesignRequest
        onSave={handleOnSave}
        onDecline={handleOnDecline}
        onBack={handleOnBack}
        title={title}
        label="design"
      />
      <Box
        style={{
          padding: "1rem",
        }}
      >
        {/* {error && <Typography variant="h5">{error}</Typography>} */}
        <Box>
          {/* {modalOpen && (
            <ImageSelectModal
              images={modalImages}
              onClick={handleModalImageClick}
              onClose={handleCloseModal}
              description={modalModeDescriptions[modalOpen]}
            />
          )} */}
          {selectedBonsai && (
            <Grid
              container
              spacing={2}
              style={{ overflowX: "hidden", width: "100%" }}
            >
              <Grid item xs={12} sm={4} md={4}>
                {newOutline && (
                  <TitleDescriptionCompareImage
                    title="We will change your outline"
                    description=""
                    oldImage={selectedBonsai.outlines[0]}
                    newImage={newOutline}
                    style={{ marginBottom: "1rem" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={5}>
                {newStructures && (
                  <TitleDescriptionImageMosaic
                    title={`We will add these ${
                      newStructures.length
                    } ${""}structure/s`}
                    description=""
                    style={{ marginBottom: "1rem" }}
                    images={newStructures}
                    onClick={(item) => {
                      onClick("solutions", item);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={5}>
                {newFoliages && (
                  <TitleDescriptionImageMosaic
                    title={`We will add these ${
                      newStructures.length
                    } ${""}foliage/s`}
                    description="These are the variations of your tree. You will consider these and select your final design for the tree."
                    style={{ marginBottom: "1rem" }}
                    images={newFoliages}
                    onClick={(item) => {
                      onClick("variations", item);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScreenDesignRequest;
