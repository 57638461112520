import { Save } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React from "react";
import PrimaryIconButton from "./PrimaryIconButton";
import PrimaryButton from "./PrimaryButton";

const SaveButton = ({ onClick, saving }) => {
  const icon = saving ? (
    <CircularProgress
      style={{ width: "1.5rem", height: "1.5rem" }}
      color="secondary"
    />
  ) : (
    <Save />
  );
  return <PrimaryIconButton onClick={onClick}>{icon}</PrimaryIconButton>;
};

export const SaveButtonFull = ({
  title = "Save",
  onClick,
  saving,
  startIcon = <Save />,
}) => {
  const icon = saving ? (
    <CircularProgress
      style={{ width: "1.5rem", height: "1.5rem" }}
      color="secondary"
    />
  ) : (
    startIcon
  );
  return (
    <PrimaryButton
      style={{ padding: "0rem 1rem" }}
      startIcon={icon}
      onClick={(...args) => {
        if (saving) return;
        onClick(...args);
      }}
    >
      {title}
    </PrimaryButton>
  );
};

export default SaveButton;
