import { getDownloadURL } from "firebase/storage";
import { uuidv4 } from "../../../utilities/uuidv4";
import { uploadFile } from "./uploadFile";

export const uploadImage = (
  image,
  cb,
  path = "",
  failCb = null,
  uuid = uuidv4()
) => {
  let promise = new Promise((resolve, reject) => {
    const imageStatusUpdated = (snapshotData) => {
      const imageIndex = 0;
      getDownloadURL(snapshotData.ref)
        .then((url) => {
          cb(url, imageIndex, resolve);
        })
        .catch((error) => {
          console.error("Error uploading image:", image, error);
          reject(image);
          if (failCb) failCb(error);
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
            default:
              break;
          }
        });
    };
    let filePath = uuid;
    if (path) {
      filePath = path + uuid;
    }

    uploadFile(image.file, filePath, imageStatusUpdated, (error) => {
      reject(error);
    });
  });
  return promise;
};
