import { doc, getFirestore, updateDoc } from "firebase/firestore";

export async function updateData(
  jsonData,
  collectionName,
  docName,
  cb,
  errorCb
) {
  const db = getFirestore();
  const docRef = doc(db, collectionName, docName);
  try {
    const docRefSet = await updateDoc(docRef, jsonData);
    if (cb) {
      cb(docRefSet);
    }
  } catch (e) {
    if (errorCb) {
      errorCb(e);
    }
  }
}
