import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarBack from "../components/AppBar/Back";
import DesignProfile from "../components/DesignProfile";
import { getData } from "../libs/firebase/firestore/getData";
import AppBarBackInfo from "../components/AppBar/BackInfo";
import AppBarProfile from "../components/AppBar/Profile";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";

const ScreenBonsaiTutorial = () => {
  const [selectedBonsai, setSelectedBonsai] = React.useState(tutorialBonsai);
  let bonsaiId = tutorialBonsai.storageDocumentId;
  let currentUser = tutorialBonsai.user;
  const navigate = useNavigateToTop();

  const handleBonsaiClicked = (type, item) => {
    navigate(`/bonsai/${bonsaiId}/${type}`);
  };

  const handleNewClicked = (type) => {
    navigate(`/bonsai/${bonsaiId}/create/${type}`);
  };

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };

  const getBonsaiData = () => {
    if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  };

  return (
    <Box>
      <AppBarProfile title="Example design" label="design" />
      <Box
        style={{
          padding: "1rem",
        }}
      >
        <DesignProfile
          bonsai={selectedBonsai}
          bonsaiId={selectedBonsai.storageDocumentId}
          onClick={handleBonsaiClicked}
          onNewClick={handleNewClicked}
          onUpdateData={getBonsaiData}
          user={currentUser}
        />
      </Box>
    </Box>
  );
};

export default ScreenBonsaiTutorial;
