import { Box } from "@mui/material";
import React from "react";

function YoutubeVideo({ startTime = "0", style, ...props }) {
  return (
    <Box
      style={{
        textAlign: "center",
        marginLeft: "-.5rem",
        marginBottom: "2rem",
        ...style,
      }}
      {...props}
    >
      <iframe
        style={{
          width: "auto",
          height: "auto",
        }}
        src={`https://www.youtube.com/embed/PRncN2ulfFg?si=XhaDqlmiRjVq18F7&amp;start=${startTime}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Box>
  );
}

export default YoutubeVideo;
