import { Grid, List, ListItem, Typography } from "@mui/material";
import InstructionRoot from "./Root";
import HeadlineImage from "../../images/artwork/photo_bonsai_camera.jpg";
import CleanRootsImage from "../../images/artwork/photo_clear_roots.jpg";
import FoliageCoverImage1 from "../../images/designs/foliageCover (1).jpg";
import FoliageCoverImage2 from "../../images/designs/foliageCover (2).jpg";
import { Box } from "@mui/system";
import BonsaiMasonryDesignList from "../../components/BonsaiMasonryDesignList";
import { InstructionSection } from "../../components/InstructionSection";

import PhotoImage1 from "../../images/designs/photo (1).jpg";

export default function ScreenInstructionFront({ onClose }) {
  return (
    <InstructionRoot
      title="Take a good picture"
      startTime="0"
      onClose={onClose}
    >
      {/* <Box
        style={{
          height: "10rem",
          width: "100%",
          overflow: "hidden",
          borderRadius: ".5rem",
          marginBottom: "1rem",
        }}
      >
        <img
          src={HeadlineImage}
          alt="Tree wrapped in fabric"
          style={{ width: "100%", height: "auto", marginTop: "-4rem" }}
        />
      </Box> */}
      <InstructionSection heading="Why?">
        <Typography>
          A good picture makes it easier to outline the structure of your tree
          and come up with a design.
        </Typography>
      </InstructionSection>
      <InstructionSection heading="How? (Prepare the tree)">
        <Typography gutterBottom>
          If you need help finding the front of your tree, Bonsai Mirai has a
          great video: {/* link */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=7rJW_ZE32Hw"
          >
            Finding the Front
          </a>
        </Typography>
        <Typography gutterBottom>
          Otherwise here are the steps to prepare your tree for a good photo for
          this tool:
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Decluter the foliage - Remove trunk shoots, croch branches and
            anything you 100% don't want.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            2. Make the surface roots visible - scrape soil away from base of
            tree
          </ListItem>
          <img
            src={CleanRootsImage}
            alt="Tree wrapped in fabric"
            style={{ width: "50%", height: "auto" }}
          />

          <ListItem sx={{ display: "list-item" }}>
            3. Hide excessive foliage - If the foliage is to dense, you can try
            wrapping fabric or a plastic bag around groups. This will bunch the
            foliage without damaging it. The soft wraps will help reveal the
            trees structure.
          </ListItem>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img
                src={FoliageCoverImage2}
                alt="Tree wrapped in fabric"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={FoliageCoverImage1}
                alt="Tree wrapped in fabric"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </List>
      </InstructionSection>
      <InstructionSection heading="How? (Taking the photo)">
        <Typography gutterBottom>
          If you can, follow these steps. If you can't just take the photo.
          Better than nothing.
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Photograph what you think is your best front
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            2. Soft lighting (e.g. taken on a cloudy day)
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            3. Solid background (cardboard or cloth) behind the tree.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            4. About 10cm above nebari ( the same as viewing height).
          </ListItem>
        </List>
      </InstructionSection>
      <InstructionSection heading="Example of an image">
        <Typography gutterBottom>
          Here is my front image. The background helps me see the structure and
          the roots are clearly visible. I should have wrapped cloth around the
          foliage on the right so I could see the structure easier... next time.
        </Typography>
        <Box>
          <img
            src={PhotoImage1}
            alt="Tree wrapped in fabric"
            style={{ width: "100%", height: "auto", borderRadius: "1rem" }}
          />
        </Box>
      </InstructionSection>
      <InstructionSection heading="What's next?">
        <Typography gutterBottom>
          We will outline your tree to clarify its shape and allow you to create
          digital designs.
        </Typography>
      </InstructionSection>
    </InstructionRoot>
  );
}
