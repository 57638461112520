/**
 *  Creating variations.
 *
 *  This is where you add the variations of your bonsai with foliage, deadwood and the pot.
 *
 *  You will likely play around with this a lot and save many variations so you can come back and review. Later see if something catches your eye or you find more visually stimulating.
 *
 *  Here are some tips to help you get started:
 *  - Create lots of variations. If you are not too sure about a variation, save it anyway. You can always delete it later.
 *
 *  Guide to this tool:
 *  - Make the pads proportional to the size of the tree
 *  - Use the triangle tool to understand foliage balance
 *  - Add depth to your tree by adding a front, middle and rear pads.
 *
 *  Guides to tree styling:
 *  - Varry the vertical spacing between pads.
 *  - Make sure you use air space in your design.
 *  - Add energy to your design by offsetting the balance (apex and first branch).
 *  - Make an isocelies triangle with the pads
 *  - Try compacting your design
 *
 *  Awesome. Remember to revisit your design and see if it affects the branch structure, you need to grow.
 *
 */

import { List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InstructionRoot from "./Root";

import OutlineImage from "../../images/designs/outline.png";
import PhotoImage1 from "../../images/designs/photo (1).jpg";

import BonsaiMasonryDesignListStable from "../../components/BonsaiMasonryDesignListStable";
import Structure1Image from "../../images/designs/structures/structure (1).png";
import Structure2Image from "../../images/designs/structures/structure (2).png";
import Structure3Image from "../../images/designs/structures/structure (3).png";
import Structure4Image from "../../images/designs/structures/structure (4).png";
import Structure5Image from "../../images/designs/structures/structure (5).png";

import Variations1Image from "../../images/designs/variations/variation (1).png";
import Variations2Image from "../../images/designs/variations/variation (2).png";
import Variations3Image from "../../images/designs/variations/variation (3).png";
import Variations4Image from "../../images/designs/variations/variation (4).png";
import { InstructionSection } from "../../components/InstructionSection";

export default function ScreenInstructionVariations({ onClose }) {
  return (
    <InstructionRoot startTime="222" title="Adding foliage" onClose={onClose}>
      {/* <Box
        style={{
          height: "10rem",
          width: "100%",
          overflow: "hidden",
          borderRadius: ".5rem",
          marginBottom: "1rem",
        }}
      >
        <img
          src={HeadlineImage}
          alt="Tree wrapped in fabric"
          style={{ width: "100%", height: "auto", marginTop: "-10rem" }}
        />
      </Box> */}
      <InstructionSection heading="Why?">
        <Typography gutterBottom>
          Your tree needs foliage otherwise they are just sticks in a pot.
        </Typography>
      </InstructionSection>
      <InstructionSection heading="How?">
        <Typography gutterBottom>
          For each structure try a couple foliage layouts and save as you go. A
          couple tips for using this tool:
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Make the pad sizes proportional to the tree.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            2. Add depth to your tree by adding front, middle and rear pads.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            3. Use rear foliage as the soil around the tree roots.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            4. Use the triangle tool to understand foliage balance.
          </ListItem>
        </List>
      </InstructionSection>
      <InstructionSection heading="What?">
        <Typography gutterBottom>
          What are the things you should think about when styling your tree?
        </Typography>
        <List>
          <ListItem sx={{ display: "list-item" }}>
            1. Vary vertical spacing between pads.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            2. Incorperate white space into the design.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            3. Add energy by offsetting the balance (apex and first branch).
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            4. Make an isocelies triangle with the pads
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            5. Compact your design
          </ListItem>
        </List>
      </InstructionSection>
      <InstructionSection heading="Examples of foliage">
        <Box>
          <Typography gutterBottom>Here is my tree.</Typography>
          <img
            src={PhotoImage1}
            alt="Tree wrapped in fabric"
            style={{ width: "100%", height: "auto", borderRadius: "1rem" }}
          />
          <Typography gutterBottom>Here is the outline I created.</Typography>
          <img
            src={OutlineImage}
            alt="Tree wrapped in fabric"
            style={{ width: "70%", height: "auto" }}
          />
          <Typography>
            Here are some of the many structures I was trying out.
          </Typography>
          <BonsaiMasonryDesignListStable
            data={[
              Structure1Image,
              Structure2Image,
              Structure3Image,
              Structure4Image,
              Structure5Image,
            ]}
            padding={"0"}
            onClick={() => {}}
          />
          <Typography>
            Here are some foliage designs I was trying out.
          </Typography>
          <BonsaiMasonryDesignListStable
            data={[
              Variations1Image,
              Variations2Image,
              Variations3Image,
              Variations4Image,
            ]}
            padding={"0"}
            onClick={() => {}}
          />
        </Box>
      </InstructionSection>
      <InstructionSection heading="What's next">
        <Typography gutterBottom>
          Select the design you like the most as the final design and start
          working towards that. Your tree won't just follow what's on a sheet of
          paper so you might have to revist your designs and try new things. The
          fact that you put effort into the design is awesome and your work will
          reflect that.
        </Typography>
      </InstructionSection>
    </InstructionRoot>
  );
}
