import { Box, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarProfile from "../components/AppBar/Profile";
import DesignProfile from "../components/DesignProfile";
import LoadingButtonShareBonsai from "../components/LoadingButton/ShareBonsai";
import { bonsaiToPdf } from "../libs/bonsaiToPdf";
import { getData } from "../libs/firebase/firestore/getData";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import { getDataFirebaseRecursion } from "../utilities/getAllDataRecursion";
import AppBarOthersProfile from "../components/AppBar/OthersProfile";
import { uploadData } from "../libs/firebase/firestore/uploadData";
import { uploadDataWithName } from "../libs/firebase/firestore/uploadDataWithName";
import DashboardCard from "../components/DashboardCard";
import DesignSuggestionProfile from "../components/DesignSuggestionProfile";
const ScreenBonsaiSuggestionProfile = () => {
  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  const [suggestionData, setSuggestionData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { bonsaiId, userId } = useParams();
  let currentUser = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleBonsaiClicked = (type, item) => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/${type}`);
    } else {
      navigate(`/bonsai/${bonsaiId}/${type}`);
    }
  };

  const handleNewClicked = (type) => {
    if (userId) {
      navigate(`/${userId}/bonsai/${bonsaiId}/create/${type}`);
    } else {
      navigate(`/bonsai/${bonsaiId}/create/${type}`);
    }
  };

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };

  const getBonsaiData = () => {
    if (userId) {
      getData(`users/${userId}/bonsai/${bonsaiId}`, handleGotBonsaiData);
    } else if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  };

  const handleGetDataFailed = (error) => {
    setError("Hey, looks like you aren't allowed to see this bonsai. Sorry!");
  };

  const handleGotSuggestionData = (data) => {
    setSuggestionData(data);
    // return;

    // Get the bonsai data
    getDataFirebaseRecursion(
      data.ownerId || data.ownerID,
      handleGotBonsaiData,
      getData,
      `bonsai/${data.bonsaiId || data.bonsaiID}`,
      1000,
      handleGetDataFailed
    );
  };

  // get the bonsai data
  useEffect(() => {
    /**
     * TODO:
     *  + Get the bonsai request data
     *  + Read the data and get the bonsaiID
     *  + Get the bonsai data
     */

    // Get the bonsai request data
    getDataFirebaseRecursion(
      userId,
      handleGotSuggestionData,
      getData,
      `designSuggestions/${bonsaiId}`,
      1000,
      (error) => {
        console.log("Error", error);
      }
    );
  }, []);

  const handleOnSavePdf = (callback) => {
    bonsaiToPdf(selectedBonsai, callback);
    return;
  };
  const handleAdviceRequest = () => {
    /**
     * What happens when they accept to help the person
     * 1. Check don't already have in a suggestions folder
     * 1. File is created in their design suggestions folder
     *   - contains the bonsaiID
     *   - contains the ownerId
     *   - contains the fronts
     *   - contains if been sent = false
     * 2. Need to make the files readable by others? (Maybe later)
     */

    const designSuggestion = {
      bonsaiID: bonsaiId,
      ownerId: userId,
      fronts: selectedBonsai.fronts,
      sent: false,
    };

    // Create file using firebase
    uploadDataWithName(
      designSuggestion,
      `users/${currentUser.uid}/designSuggestions`,
      bonsaiId,
      () => {
        navigate(`/designs/${bonsaiId}`);
      },
      (error) => {
        console.log("Error", error);
      }
    );
  };

  const handleOnBack = () => {};

  const title = "Thanks for helping out!";

  const isOwner = !userId || (currentUser && currentUser.uid === userId);
  // const isOwner = false;
  return (
    <Box>
      {isOwner && (
        <AppBarProfile
          onBack={handleOnBack}
          title={title}
          label="design"
          onSavePdf={handleOnSavePdf}
        />
      )}
      {!isOwner && (
        <AppBarOthersProfile
          onBack={handleOnBack}
          title={title}
          label="design"
          onAdviceRequest={handleAdviceRequest}
        />
      )}
      <DashboardCard>
        Create a design and share it with the owner of this bonsai
      </DashboardCard>
      <Box
        style={{
          padding: "1rem",
          paddingTop: "0",
        }}
      >
        {error && <Typography variant="h5">{error}</Typography>}
        {/* {!error && (
          <Box style={{ marginBottom: "1rem" }}>
            <LoadingButtonShareBonsai user={currentUser} bonsaiId={bonsaiId} />
          </Box>
        )} */}
        {!error && (
          <DesignSuggestionProfile
            bonsai={selectedBonsai}
            bonsaiId={bonsaiId}
            onClick={handleBonsaiClicked}
            onNewClick={handleNewClicked}
            onUpdateData={getBonsaiData}
            user={currentUser}
            userId={userId}
          />
        )}
      </Box>
    </Box>
  );
};

export default ScreenBonsaiSuggestionProfile;
