import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarBack from "../components/AppBar/Back";
import DesignProfile from "../components/DesignProfile";
import { getData } from "../libs/firebase/firestore/getData";
import ContainerCompareImages from "../components/elements/container/CompareImages";

const ScreenCompare = () => {
  const [selectedBonsai, setSelectedBonsai] = React.useState(null);
  // state for sketchRef
  const [sketchRef, setSketchRef] = React.useState(null);
  const { bonsaiId } = useParams();

  let currentUser = getAuth().currentUser;
  const navigate = useNavigate();

  const handleGotBonsaiData = (data) => {
    setSelectedBonsai(data);
  };
  // get the bonsai data
  useEffect(() => {
    if (currentUser) {
      getData(
        `users/${currentUser.uid}/bonsai/${bonsaiId}`,
        handleGotBonsaiData
      );
    }
  }, []);

  const firstImage = selectedBonsai?.fronts[0];

  // variable images with solutions and variations combined from selectedbonsai if exists
  const images = selectedBonsai?.solutions
    ?.map((solution) => {
      return solution;
    })
    .flat()
    .concat(
      selectedBonsai?.variations
        ?.map((variation) => {
          return variation;
        })
        .flat()
    );

  return (
    <Box>
      <AppBarBack title="Compare" label="design" />
      <Box
        style={{
          padding: "1rem",
          height: "100%",
        }}
      >
        <ContainerCompareImages
          firstImage={firstImage}
          images={images}
          sketchRef={sketchRef}
          setSketchRef={setSketchRef}
        />
      </Box>
    </Box>
  );
};

export default ScreenCompare;
