import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import AppBarRoot from "./Root";
import IconBack from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

function AppBarBack({ title }) {
  const navigate = useNavigateToTop();
  return (
    <AppBarRoot
      startAction={
        <Stack direction={"row"} spacing={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconBack />
          </IconButton>
          <Typography
            variant="h5"
            style={{ alignSelf: "center", color: "white" }}
          >
            {title}
          </Typography>
        </Stack>
      }
    />
  );
}

export default AppBarBack;
