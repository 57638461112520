export const withTransformingRectangle = {
  dragPointName() {
    return this._dragPointName;
  },
  setDragPointName(point) {
    this._dragPointName = point;
  },
  startDragging() {
    this._dragging = true;
  },
  stopDragging() {
    this._dragging = false;
    this._dragPointName = null;
    this._dragStartX = null;
    this._dragStartY = null;
  },
  isDragging() {
    return this._dragging;
  },
  setDragStartX(x) {
    this._dragStartX = x;
  },
  setDragStartY(y) {
    this._dragStartY = y;
  },
  setDragWidthStart(width) {
    this._dragWidthStart = width;
  },
  dragWidthStart() {
    return this._dragWidthStart;
  },
  setDragHeightStart(height) {
    this._dragHeightStart = height;
  },
  dragHeightStart() {
    return this._dragHeightStart;
  },
};
