/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { ElevatedImage } from "./ElevatedImage";
import PrimaryButton from "./PrimaryButton";
import { useTheme } from "@emotion/react";

function getImage(image, isOwner, onClick) {
  if (!image) {
    return null;
  }
  return (
    <ElevatedImage
      style={{
        border: isOwner ? "1px solid #ccc" : "1px solid green",
      }}
      src={image}
      onClick={onClick}
    />
  );
}

export default function TitleDescriptionImage(props) {
  const {
    title,
    description,
    image,
    style,
    onClick,
    onNewItemClick,
    imageAlt = "create",
    action,
    canAction = true,
    isOwner = true,
  } = props;

  const isCreateImage =
    image === null || (image === undefined && imageAlt === "create");

  const showAction = isCreateImage && canAction;

  // get the mobile breakpoint from muitheme

  const theme = useTheme();
  // set the number of columns based on the current breakpoint

  const isSmBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));

  if (isSmBreakPoint && showAction) {
    // make it so that the action button is below the text
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
          padding: "1rem",
          borderRadius: "1rem",
          // border: "1px solid grey",
          ...style,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            //   justifyContent: "center",
            alignSelf: "stretch",
            paddingRight: ".5rem",
            flex: 3,
          }}
        >
          <Typography variant="h5" alignSelf="start">
            {title}
          </Typography>
          <Typography
            variant="caption"
            style={{ textAlign: "left" }}
            align="left"
            gutterBottom
          >
            {description}
          </Typography>
          <div style={{ flexGrow: "1" }} />
          {canAction && action}
          <div style={{ flexGrow: "1" }} />
        </Box>
        <Box style={{ flex: 2 }}>
          {showAction ? (
            <PrimaryButton onClick={onNewItemClick}>{imageAlt}</PrimaryButton>
          ) : (
            getImage(image, isOwner, onClick)
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "center",
          alignSelf: "stretch",
          paddingRight: ".5rem",
          flex: 3,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant="caption" align="left" gutterBottom>
          {description}
        </Typography>
        <div style={{ flexGrow: "1" }} />
        {canAction && action}
        <div style={{ flexGrow: "1" }} />
      </Box>
      <Box style={{ flex: 2 }}>
        {showAction ? (
          <PrimaryButton onClick={onNewItemClick}>{imageAlt}</PrimaryButton>
        ) : (
          getImage(image, isOwner, onClick)
        )}
      </Box>
    </Box>
  );
}
