import { Box, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DesignMenu from "../components/DesignMenu";
import UploadImage from "../components/UploadImage";
import { getAuth } from "firebase/auth";
import AppBarBack from "../components/AppBar/Back";
import TitleDescription from "../components/TitleDescription";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import AppBarBackInfo from "../components/AppBar/BackInfo";
import { Info } from "@mui/icons-material";

const ScreenCreateBonsai = () => {
  const { bonsaiId } = useParams();
  const user = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleOnClose = () => {
    navigate("/");
  };
  return (
    <Box>
      <AppBarBackInfo title="Create a bonsai" label="fronts" />
      <Box
        style={{
          padding: "1rem",
        }}
      >
        <TitleDescription
          title={"Front Photo"}
          description={
            "Upload a photo of the front of your tree. You will sketch over it to create an outline."
          }
          size="large"
        >
          <Box>
            <Typography variant="caption">
              Click the <Info style={{ position: "relative", top: "8px" }} />{" "}
              icon at the top right of your screen for tips on taking a good
              photo.
            </Typography>
          </Box>
          {/* <Link to="/instruction/fronts">
            Follow these tips to taking a good photo!
          </Link> */}
        </TitleDescription>
        <UploadImage
          user={user}
          handleOnClose={handleOnClose}
          title={null}
          actionTitle={"Create bonsai"}
          property={"fronts"}
        />
      </Box>
    </Box>
  );
};

export default ScreenCreateBonsai;
