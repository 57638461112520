import IconBack from "@mui/icons-material/ArrowBack";
import { IconButton, Snackbar, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { alertUserUnsavedChanges } from "../../libs/alertUserUnsavedChanges";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import Instructions from "../InstructionDialog";
import SaveButton, { SaveButtonFull } from "../SaveButton";
import AppBarRoot from "./Root";
import SnackbarCustom from "../SnackbarCustom";

function AppBarSaveDiscard({ title, onSave, label }) {
  // state for snackbar
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const navigate = useNavigateToTop();
  const vertical = "top";
  const horizontal = "center";

  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const saveSuccess = () => {
    runSnackbar("Image saved");
    setIsSaving(false);
  };
  const saveFailed = (e) => {
    runSnackbar("Failed to save image: " + e + ". Please try again.");
    setIsSaving(false);
  };
  const handleSaveClick = (event) => {
    setIsSaving(true);
    onSave(saveSuccess, saveFailed);
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUserUnsavedChanges);
    return () => {
      window.removeEventListener("beforeunload", alertUserUnsavedChanges);
    };
  }, []);

  return (
    <Fragment>
      <AppBarRoot
        startAction={
          <Stack direction={"row"} spacing={1}>
            <IconButton
              color="secondary"
              onClick={(e) => {
                alertUserUnsavedChanges(e, () => {
                  navigate(-1);
                });
              }}
            >
              <IconBack />
            </IconButton>
            <Typography
              variant="h5"
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "1.1rem",
              }}
            >
              {title}
            </Typography>
          </Stack>
        }
        endAction={
          <Stack direction={"row"} spacing={1}>
            <Instructions label={label} />
            <SaveButtonFull onClick={handleSaveClick} saving={isSaving} />
          </Stack>
        }
      />
      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        // onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}

export default AppBarSaveDiscard;
