import React from "react";
import { Box, Typography } from "@mui/material";

export function InstructionSection({ heading, children }) {
  return (
    <Box>
      <Typography
        style={{
          // padding: ".2rem 1rem",
          fontWeight: "bold",
          color: "black",
        }}
        gutterBottom
      >
        {heading}
      </Typography>
      <Box
        style={{
          marginBottom: "2rem",
          // marginLeft: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
