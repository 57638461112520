import React from "react";
import { ScreenDesignRoot } from "./Root";
import { useNavigate, useParams } from "react-router-dom";
import { encodeURLtoHex } from "../../utilities/encodeUrlToHex";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

export function ScreenDesignVariations({ root = "bonsai" }) {
  let navigate = useNavigateToTop();
  let { bonsaiId, userId } = useParams();

  const handleOnEdit = (imageUrl) => {
    let encodedImageUrl = encodeURLtoHex(imageUrl);
    navigate(`/${root}/${bonsaiId}/edit/variations/${encodedImageUrl}`);
  };
  return (
    <ScreenDesignRoot
      title="Foliage images"
      property="variations"
      onEdit={handleOnEdit}
      userId={userId}
    />
  );
}
