import p5 from "p5";

const withBackgroundImage = {
  backgroundImage() {
    return this._backgroundImage;
  },
  setBackgroundImage(image) {
    this._backgroundImage = image;
  },
  toggleVisibilityBackgroundImage() {
    this._backgroundImageVisible = !this._backgroundImageVisible;
  },
  hideBackgroundImage() {
    this._backgroundImageVisible = false;
  },
  isBackgroundImageVisible() {
    if (this._backgroundImageVisible === undefined) {
      this._backgroundImageVisible = true;
    }
    return this._backgroundImageVisible;
  },
};

const withForegroundImage = {
  foregroundImage() {
    return this._foregroundImage;
  },
  setForegroundImage(image) {
    this._foregroundImage = image;
  },
  toggleVisibilityForegroundImage() {
    this._foregroundImageVisible = !this._foregroundImageVisible;
  },
  isForegroundImageVisible() {
    if (this._foregroundImageVisible === undefined) {
      this._foregroundImageVisible = true;
    }
    return this._foregroundImageVisible;
  },
};

const withScale = {
  scale() {
    return this._scale;
  },
  setScale(scale) {
    this._scale = scale;
  },
};

export function createImageComparisonVisualisation(containerElement, options) {
  const { framerate = 60, onCanvasChange } = options;

  const map2d = pipeMixins(
    withP5DrawingLibrary,
    withBackgroundImage,
    withForegroundImage,
    withScale
  );

  map2d.destroy = () => {
    map2d.destroyLibrary();
  };

  map2d._imageLoadFailed = false;

  map2d.setup = (drawingLibrary) => {
    drawingLibrary.disableFriendlyErrors = true;
    let cnv = drawingLibrary.createCanvas(
      containerElement.offsetWidth,
      containerElement.offsetHeight
    );
    onCanvasChange(drawingLibrary);

    map2d.setScale(1);

    drawingLibrary._canvas = cnv;

    drawingLibrary.frameRate(framerate);
    drawingLibrary.angleMode(drawingLibrary.DEGREES);
    drawingLibrary.imageMode(drawingLibrary.CENTER);

    // INITIAL LOADING
    //load background image
    if (map2d.backgroundImage()) {
      const backgroundImage = map2d.backgroundImage();
      if (!map2d._imageLoadFailed) {
        drawingLibrary.loadImage(
          backgroundImage.src,
          (img) => {
            drawingLibrary._backgroundImage = img;
            drawingLibrary._backgroundImageSrc = backgroundImage.src;
          },
          (error) => {
            map2d._imageLoadFailed = true;
          }
        );
      }
    }
    if (map2d.foregroundImage()) {
      if (!map2d._imageLoadFailed) {
        const foregroundImage = map2d.foregroundImage();
        drawingLibrary.loadImage(
          foregroundImage.src,
          (img) => {
            drawingLibrary._foregroundImage = img;
            drawingLibrary._foregroundImageSrc = foregroundImage.src;
          },
          (error) => {
            map2d._imageLoadFailed = true;
          }
        );
      }
    }

    drawingLibrary.frameRate(framerate);
  };
  map2d.draw = (drawingLibrary) => {
    drawingLibrary.background(255);

    drawingLibrary.clear();
    drawingLibrary.smooth();

    // drawingLibrary.scale(map2d.scale());
    if (map2d._imageLoadFailed) {
      return;
    }
    const backgroundImage = map2d.backgroundImage();
    if (
      drawingLibrary._backgroundImage &&
      drawingLibrary._backgroundImageSrc === backgroundImage.src
    ) {
      // get the height and width of the background image, then draw to fit the canvas
      let backgroundImageWidth = drawingLibrary._backgroundImage.width;
      let backgroundImageHeight = drawingLibrary._backgroundImage.height;
      let backgroundImageScale = Math.min(
        drawingLibrary.width,
        drawingLibrary.height / 2 / backgroundImageHeight
      );

      // Draw the image on the left

      let backgroundImagePosition = {
        x: drawingLibrary.width / 2,
        y: drawingLibrary.height / 4,
      };

      drawImageScaledAtPosition(
        drawingLibrary,
        drawingLibrary._backgroundImage,
        backgroundImagePosition.x,
        backgroundImagePosition.y,
        backgroundImageScale,
        0
      );
    } else {
      if (backgroundImage && backgroundImage.src) {
        drawingLibrary.loadImage(
          backgroundImage.src,
          (img) => {
            drawingLibrary._backgroundImage = img;
            drawingLibrary._backgroundImageSrc = backgroundImage.src;
          },
          (error) => {
            map2d._imageLoadFailed = true;
          }
        );
      }
    }

    if (
      drawingLibrary._foregroundImage &&
      drawingLibrary._foregroundImageSrc === drawingLibrary._foregroundImage.src
    ) {
      // get the height and width of the background image, then draw to fit the canvas
      let foregroundImageWidth = drawingLibrary._foregroundImage.width;
      let foregroundImageHeight = drawingLibrary._foregroundImage.height;
      let foregroundImageScale = Math.min(
        drawingLibrary.width / 2 / foregroundImageWidth,
        drawingLibrary.height / 2 / foregroundImageHeight
      );
      // Draw the image on the right

      let backgroundImagePosition = {
        x: (drawingLibrary.width / 4) * 3,
        y: drawingLibrary.height / 2,
      };

      drawImageScaledAtPosition(
        drawingLibrary,
        drawingLibrary._foregroundImage,
        backgroundImagePosition.x,
        backgroundImagePosition.y,
        foregroundImageScale,
        0
      );
    } else {
      if (map2d.foregroundImage() && map2d.foregroundImage().src) {
        drawingLibrary.loadImage(
          map2d.foregroundImage().src,
          (img) => {
            drawingLibrary._foregroundImage = img;
            drawingLibrary._foregroundImageSrc = map2d.foregroundImage().src;
          },
          (error) => {
            map2d._imageLoadFailed = true;
          }
        );
      }
    }
  };

  map2d.createLibrary(containerElement, map2d.setup, map2d.draw);

  return map2d;
}

function drawImageScaledAtPosition(p5, image, x, y, scale, rotation = 0) {
  // Scale image to fit in the canvas
  let xPos = p5.width / 2 + (x * p5.width) / 2;
  let yPos = p5.height / 2 + (y * p5.height) / 2;

  // get scaled image width and height
  let scaledWidth = image.width * scale;
  let scaledHeight = image.height * scale;

  p5.push();

  // translate to the center of the image
  p5.translate(xPos, yPos);

  // rotate the image
  p5.rotate(rotation);

  p5.image(image, 0, 0, scaledWidth, scaledHeight);

  p5.pop();
}

export function pipeMixins(...mixins) {
  return Object.create(Object.assign({}, ...mixins));
}

export const withP5DrawingLibrary = (function withP5DrawingLibrary() {
  const sketch = (setupCb, drawCb, p) => {
    // TODO not entirely sure about this here
    p.setup = () => setupCb(p);
    p.draw = () => drawCb(p);
  };

  function createLibrary(containerElement, setupCb, drawCb) {
    this.drawingLibrary_ = new p5(
      sketch.bind(this, setupCb, drawCb),
      containerElement
    );
  }

  function destroyLibrary() {
    this.drawingLibrary_.remove();
  }

  function drawingLibrary() {
    return this.drawingLibrary_;
  }

  // Reveal and hoist common drawingLibrary components
  function width() {
    return this.drawingLibrary().width;
  }

  function height() {
    return this.drawingLibrary().height;
  }

  function loadImage(...args) {
    return this.drawingLibrary().loadImage(...args);
  }

  return {
    createLibrary,
    destroyLibrary,
    drawingLibrary,
    width,
    height,
    loadImage,
  };
})();
