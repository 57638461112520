import { getStorage, ref, uploadBytes } from "firebase/storage";

export function uploadFile(file, storageName, cb = null, failCb = null) {
  const storage = getStorage();
  const storageRef = ref(storage, storageName);
  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, file)
    .then((snapshot) => {
      if (cb) {
        cb(snapshot);
      }
    })
    .catch((error) => {
      if (failCb) failCb(error);
    });
}
