import { colorMatchPrecise } from "./colourMatch";
import { hexToRgba } from "./hexToRgba";

function getColorAtPixel(imageData, x, y) {
  const { width, data, d } = imageData;
  let idx = (x * d + y * width * d) * 4;
  // let idx = 4 * (y * d * width * d + x * d);
  return {
    r: data[idx],
    g: data[idx + 1],
    b: data[idx + 2],
    a: data[idx + 3],
  };
  // return {
  //   r: data[4 * (width * y + x) + 0],
  //   g: data[4 * (width * y + x) + 1],
  //   b: data[4 * (width * y + x) + 2],
  //   a: data[4 * (width * y + x) + 3],
  // };
}

function getColorAtPixelAlt(imageData, x, y) {
  let { drawingLibrary, d } = imageData;
  var colour = {};
  for (var i = 0; i < d; ++i) {
    for (var j = 0; j < d; ++j) {
      let idx = 4 * ((y * d + j) * drawingLibrary.width * d + (x * d + i));
      colour.r = drawingLibrary.pixels[idx];
      colour.g = drawingLibrary.pixels[idx + 1];
      colour.b = drawingLibrary.pixels[idx + 2];
      colour.a = drawingLibrary.pixels[idx + 3];
    }
  }
  return colour;
}

function setColorAtPixel(imageData, color, x, y) {
  const { width, data, d } = imageData;

  for (var i = 0; i < d; ++i) {
    for (var j = 0; j < d; ++j) {
      let idx = 4 * ((y * d + j) * width * d + (x * d + i));
      data[idx + 0] = color.r;
      data[idx + 1] = color.g;
      data[idx + 2] = color.b;
      data[idx + 3] = color.a;
    }
  }
  // data[4 * (width * y + x) + 0] = color.r;
  // data[4 * (width * y + x) + 1] = color.g;
  // data[4 * (width * y + x) + 2] = color.b;
  // data[4 * (width * y + x) + 3] = color.a;
}

function setColorAtPixelAlt(imageData, colour, x, y, d) {
  let { drawingLibrary } = imageData;
  for (var i = 0; i < d; ++i) {
    for (var j = 0; j < d; ++j) {
      let idx = 4 * ((y * d + j) * drawingLibrary.width * d + (x * d + i));
      drawingLibrary.pixels[idx] = colour.r;
      drawingLibrary.pixels[idx + 1] = colour.g;
      drawingLibrary.pixels[idx + 2] = colour.b;
      drawingLibrary.pixels[idx + 3] = colour.a;
    }
  }
}

// export function colorMatch(a, b, tolerance = 80) {
//   return (
//     Math.abs(a.r - b.r) <= tolerance &&
//     Math.abs(a.g - b.g) <= tolerance &&
//     Math.abs(a.b - b.b) <= tolerance &&
//     Math.abs(a.a - b.a) <= tolerance
//   );
// }

export async function floodFillCanvas(drawingLibrary, x, y, d, colour) {
  drawingLibrary.loadPixels();
  let imageData = {
    width: drawingLibrary.width,
    height: drawingLibrary.height,
    data: drawingLibrary.pixels,
    drawingLibrary,
    d,
  };

  let newColor = hexToRgba(colour);
  const { width, height, data } = imageData;
  const stack = [];
  const baseColor = getColorAtPixel(imageData, x, y);
  let operator = { x, y };
  // Check if base color and new color are the same
  if (colorMatchPrecise(baseColor, newColor)) {
    return;
  }

  // Add the clicked location to stack
  stack.push({ x: operator.x, y: operator.y });
  while (stack.length) {
    // wait a 10th of a second before processing the next one

    operator = stack.pop();
    let contiguousDown = true; // Vertical is assumed to be true
    let contiguousUp = true; // Vertical is assumed to be true
    let contiguousLeft = false;
    let contiguousRight = false;

    // Move to top most contiguousDown pixel
    while (contiguousUp && operator.y >= 0) {
      operator.y--;
      contiguousUp = colorMatchPrecise(
        getColorAtPixel(imageData, operator.x, operator.y),
        baseColor
      );
    }

    // Move downward
    while (contiguousDown && operator.y < height) {
      setColorAtPixel(imageData, newColor, operator.x, operator.y);

      // Check left
      if (
        operator.x - 1 >= 0 &&
        colorMatchPrecise(
          getColorAtPixel(imageData, operator.x - 1, operator.y),
          baseColor
        )
      ) {
        if (!contiguousLeft) {
          contiguousLeft = true;
          stack.push({ x: operator.x - 1, y: operator.y });
        }
      } else {
        contiguousLeft = false;
      }

      // Check right
      if (
        operator.x + 1 < width &&
        colorMatchPrecise(
          getColorAtPixel(imageData, operator.x + 1, operator.y),
          baseColor
        )
      ) {
        if (!contiguousRight) {
          stack.push({ x: operator.x + 1, y: operator.y });
          contiguousRight = true;
        }
      } else {
        contiguousRight = false;
      }

      operator.y++;
      contiguousDown = colorMatchPrecise(
        getColorAtPixel(imageData, operator.x, operator.y),
        baseColor
      );
    }
  }
  drawingLibrary.updatePixels();
}
