import { Box, Skeleton, Snackbar, Stack } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBarBackInfo from "../../components/AppBar/BackInfo";
import ImageMasonryViewEditDelete from "../../components/ImageMasonryViewEditDelete";
import { deleteDocReferencedImage } from "../../libs/firebase/cloudStorage/deleteDocReferencedImage";
import { getData } from "../../libs/firebase/firestore/getData";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import { getDataFirebaseRecursion } from "../../utilities/getAllDataRecursion";
import { isOwnerOfUrl } from "../../utilities/isOwnerOfUrl";
import SnackbarCustom from "../../components/SnackbarCustom";

export function ScreenDesignRoot({
  property,
  title,
  onEdit,
  enableDelete = true,
  userId,
}) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [fronts, setFronts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { bonsaiId } = useParams();
  const vertical = "top";
  const horizontal = "center";

  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const deleteSuccess = () => {
    runSnackbar("Image deleted successfully");
    getData(
      `users/${currentUser.uid}/bonsai/${bonsaiId}`,
      handleGotBonsaiData,
      (e) => console.error(e)
    );
  };
  const deleteFailed = (e) => {
    runSnackbar("Image deletion failed: " + e + ". Please try again.");
  };
  let currentUser = getAuth().currentUser;

  const handleGotBonsaiData = (data) => {
    setLoading(false);
    if (data && data[property].length) {
      setFronts(data[property]);
    } else {
      // if property is outlines then set nothing
      if (property === "outlines") {
        setFronts([]);
      }
      // REF: This was causing issues with navigation
      // console.log("no data so heading back");
      // if (userId) {
      //   navigate(`/${userId}/bonsai/${bonsaiId}`);
      // } else {
      //   navigate(`/bonsai/${bonsaiId}`);
      // }
    }
  };

  const handleDelete = (imageUrl) => {
    // Search for imageUrl in fronts
    // If found, delete it
    // If not found, do nothing
    //

    const isOwner = isOwnerOfUrl(imageUrl, currentUser.uid);

    let updatedData = {};
    updatedData[property] = fronts.filter((front) => front !== imageUrl);

    const referenceId = `users/${currentUser.uid}/bonsai`;
    deleteDocReferencedImage(
      updatedData,
      imageUrl,
      referenceId,
      bonsaiId,
      deleteSuccess,
      deleteFailed,
      isOwner
    );
  };

  useEffect(() => {
    getDataFirebaseRecursion(
      userId,
      handleGotBonsaiData,
      getData,
      `bonsai/${bonsaiId}`
    );
  }, []);

  let bonsaiNotExist = (!fronts || fronts.length === 0) && !loading;

  return (
    <Box style={{ overflow: "hidden" }}>
      <AppBarBackInfo title={title} label={property} />

      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
      {loading && (
        <Stack style={{ padding: "1rem" }} direction={"row"} spacing={2}>
          <Skeleton variant="rounded" height={150} width={100} />
          <Skeleton variant="rounded" height={150} width={100} />
          <Skeleton variant="rounded" height={150} width={100} />
        </Stack>
      )}
      {bonsaiNotExist ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h3>No images found</h3>
        </Box>
      ) : (
        <ImageMasonryViewEditDelete
          data={fronts}
          onDelete={enableDelete && !userId ? handleDelete : false}
          onEdit={userId ? null : onEdit}
        />
      )}
    </Box>
  );
}
