import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { validateEmailAndPassword } from "../../../utilities/validateEmailAndPassword";

export function signIn(email, password, successCb, failCb) {
  const auth = getAuth();
  const isValid = validateEmailAndPassword(email, password);
  if (!isValid) {
    return;
  }
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      if (successCb) {
        successCb(user);
        // ...
      }
    })
    .catch((error) => {
      if (failCb) {
        failCb(error);
      }
      // ..
    });
}
