import { PictureAsPdf } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";

export function DialogDownloadPdf({ onSavePdf }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleOnInformation = () => {
    setDialogOpen(true);
  };

  const handlePdfSaved = () => {
    setLoading(false);
    setDialogOpen(false);
  };

  const handleSavePdf = () => {
    setLoading(true);
    onSavePdf(handlePdfSaved);
  };

  return (
    <Fragment>
      <ConfirmDownload
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        onConfirm={handleSavePdf}
        loading={loading}
      />
      <IconButton color="secondary" onClick={handleOnInformation}>
        <PictureAsPdf />
      </IconButton>
    </Fragment>
  );
}

function ConfirmDownload({ open, onClose, onConfirm, loading }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>Download as PDF?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This will download the design as a single PDF file. This may take 30
          seconds.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onConfirm}
        >
          Download
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
