// React component using MUI components and MUI styles for login screen
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../libs/firebase/auth/signInEmail";

import PrimaryButton from "../components/PrimaryButton";
import TextFieldEmail from "../components/textfield/Email";
import TextFieldPassword from "../components/textfield/Password";
import JapanArtImage from "../images/artwork/japan_art (2).jpg";
import TextFieldName from "../components/textfield/Name";
import { signUp } from "../libs/firebase/auth/signUpEmail";

export default function ScreenLogin({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [experience, setExperience] = React.useState("");
  const [terms, setTerms] = React.useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setExperience(event.target.value);
  };
  //   const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.error("needs to do validation for sign up");

    // throw error if inputs are empty
    if (!email || !password || !name || !experience) {
      setError("Please fill out all fields");
      return;
    }

    signUp(email, password, name, handleSuccessCb, failCb, experience);
  };
  const handleSuccessCb = (userCredential) => {
    // navigate to login screen
    navigate("/");
  };

  const failCb = (error) => {
    console.error(error, "setting error");
    setError(error.code);
    // if (error.code === "auth/invalid-email" || error.code === "auth/user-not-found") {
    //   setInvalidEmail(true);
    // }
    // else if (error.code === "auth/wrong-password") {
    //   setInvalidCredentials(true);
    // }
    // else {
    //   setOtherIssue(true);
    // }
  };
  const theme = useTheme();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        background: `url("${JapanArtImage}")`,
      }}
    >
      {/* <Box>
        <img src={JapanArtImage} alt="logo" />
      </Box> */}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px",
          padding: "1rem",
          gap: ".5rem",
          backgroundColor: theme.palette.background.paper,
          borderRadius: "10px",
          boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h5" component="h2">
          Register
        </Typography>
        <Typography variant="subtitle2" component="p" gutterBottom>
          Create your account to get started
        </Typography>
        <FormControl
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          <TextFieldName
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl
          style={{
            marginBottom: "1rem",
          }}
        >
          <TextFieldEmail
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl
          style={{
            marginBottom: "1rem",
          }}
        >
          <TextFieldPassword
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        <FormControl
          style={{
            marginBottom: "1rem",
            // paddingRight: "2rem",
            // paddingLeft: "2rem",
          }}
          fullWidth
        >
          <InputLabel id="experience-level-label">Experience level</InputLabel>
          <Select
            required
            labelId="experience-level-label"
            id="experinece-level"
            value={experience}
            label="Experience Level"
            onChange={handleChange}
          >
            <MenuItem value={"beginner"}>0-3 years</MenuItem>
            <MenuItem value={"intermediate"}>3-6 years</MenuItem>
            <MenuItem value={"expert"}>6+ years</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
                name="terms"
              />
            }
            label={
              <Box>
                I have read and agree to the{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  target="_blank"
                  to="http://www.thebonsaiproject.com/policy"
                >
                  Terms and Conditions
                </Link>{" "}
                and the{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  target="_blank"
                  to="http://www.thebonsaiproject.com/policy"
                >
                  Privacy Policy
                </Link>
              </Box>
            }
          />
        </FormControl>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <PrimaryButton
          style={{
            width: "100%",
            maxWidth: "15rem",
            marginTop: "2rem",
          }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Sign up
        </PrimaryButton>
        <Typography variant="subtitle2" component={"p"}>
          Have an account?{" "}
          <Link
            style={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            to="/login"
          >
            log in
          </Link>
        </Typography>
      </form>
    </Box>
  );
}
