/**
 * Create a React component using MUI. It has a title, description, and image. The title is on the left with the description underneath. The image is on the right.
 */
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { ElevatedImage } from "./ElevatedImage";
import { ArrowRightAlt } from "@mui/icons-material";

export default function TitleDescriptionCompareImage(props) {
  const { title, description, style, oldImage, newImage } = props;
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        padding: "1rem",
        borderRadius: "1rem",
        // border: "1px solid grey",
        ...style,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "center",
          paddingRight: ".5rem",
          flex: 3,
        }}
      >
        <Typography variant="h5" alignSelf="start">
          {title}
        </Typography>
        <Typography variant="caption" align="left" gutterBottom>
          {description}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="body1" align="center">
            Old
          </Typography>
          <ElevatedImage
            src={oldImage}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "1rem",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ArrowRightAlt />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1" align="center">
            New
          </Typography>
          <ElevatedImage
            src={newImage}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "1rem",
            }}
          />
        </Grid>
      </Grid>
      {/* <Box style={{ flex: 2, height: "100%", width: "100%" }}>
      </Box> */}
    </Box>
  );
}
