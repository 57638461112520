// React component that returns a grid of two grids. In the first gird the image is drawn and a list of bonsai pots. In the second grid are tools to manipulate the image
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Slider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import React, { Fragment } from "react";
import ForegroundBackgroundImageDrawer from "./ForegroundBackgroundImageDrawer";
import ImageControls from "./ImageControls";
import MeasurementControls from "./MeasurementControls";
import ImageEditor from "./ImageEditor";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ColorWheel from "./ColorWheel";

const DrawerWithControls = ({
  backgroundImage,
  sketchRef,
  setSketchRef,
  foregroundImage,
  selectedBonsaiPot,
  toolbars,
}) => {
  const [brushSize, setBrushSize] = React.useState(30);

  const [hex, setHex] = React.useState("#000000");

  const handleBrushSizeChange = (event, newValue) => {
    setBrushSize(newValue);
  };

  // state to store scale of foreground image
  const [scaleForground, setScaleForground] = React.useState(4);
  // state to store scale of background image
  const [scaleBackground, setScaleBackground] = React.useState(0.6);
  // state to store x and y position of background image
  const [positionBackgroundImage, setPositionBackgroundImage] = React.useState({
    x: 0,
    y: 0,
  });
  // state to store x and y position of foreground image
  const [positionForegroundImage, setPositionForegroundImage] = React.useState({
    x: 0,
    y: 0,
  }); // State to store the selected bonsai bot

  // -- All states to do with guidelines
  const [useRealLifeSize, setUseRealLifeSize] = React.useState(false);
  // const [showSizingGuidelines, setShowSizingGuidelines] = React.useState(false);
  // state for guidelineWidth
  const [guidelineWidth, setGuidelineWidth] = React.useState(0);
  // state for guide line position
  const [guidelinePosition, setGuidelinePosition] = React.useState({
    x1: 0,
    x2: 0,
  });
  // state for guideline scale
  const [guidelineSavedAtScale, setGuidelineSavedAtScale] = React.useState(0);
  // state if measurement has been saved
  const [measurementSaved, setMeasurementSaved] = React.useState(false);
  // state for editing measurement
  const [editingMeasurement, setEditingMeasurement] = React.useState(false);

  // Variables that determine how things are rendered
  const showMeasurementControls =
    (editingMeasurement && measurementSaved) ||
    (useRealLifeSize && !measurementSaved);
  const showEditMeasurementButton = measurementSaved;

  const cantDoRealLifeSize =
    selectedBonsaiPot &&
    (!selectedBonsaiPot.width || !selectedBonsaiPot.height);

  const useRealLifeSizeDisabled =
    backgroundImage === null || cantDoRealLifeSize;
  const showSizingGuidelines =
    showMeasurementControls && !useRealLifeSizeDisabled;
  const useRealSizeForegroundImage =
    useRealLifeSize && measurementSaved && !cantDoRealLifeSize;

  const foregroudImageScaleDisabled =
    !foregroundImage || (useRealLifeSize && !cantDoRealLifeSize);

  // Theme and style stuff
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  const handleMeasurementSave = (measurement) => {
    setMeasurementSaved(true);
    setEditingMeasurement(false);
    setGuidelineSavedAtScale(scaleBackground);
    setUseRealLifeSize(true);
  };

  const guideScale = scaleBackground;
  let scaledGuideWidthPosition = {
    x1: guidelinePosition.x1 * guideScale,
    x2: guidelinePosition.x2 * guideScale,
  };
  return (
    <Grid item xs={12} style={{ height: "100%", width: "100%" }}>
      <Stack style={{ overflow: "hidden", height: "100%" }} spacing={1}>
        <ImageEditor
          getBrushSize={() => brushSize}
          brushSize={brushSize}
          primaryColor={hex}
          backgroundImagePosition={positionBackgroundImage}
          backgroundImage={backgroundImage}
          foregroundImage={foregroundImage}
          backgroundImageScale={scaleBackground / 10}
          toolbars={toolbars}
          onCanvasChange={(canvas) => {
            setSketchRef(canvas);
            // sketchRef.current = canvas;
          }}
          style={{
            flex: isXsBreakPoint ? 5 : 5,
            height: "100%",
            width: "100%",
            minHeight: "200px",
          }}
        />
      </Stack>
    </Grid>
  );
};

export default DrawerWithControls;
