import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import AppBarBack from "../../components/AppBar/Back";
import UploadImage from "../../components/UploadImage";
import AppBarSaveDiscard from "../../components/AppBar/SaveDiscard";
import { useNavigateToTop } from "../../libs/useNavigateToTop";

const ScreenCreateFront = () => {
  const user = getAuth().currentUser;
  const navigate = useNavigateToTop();

  const handleOnClose = () => {
    navigate(-1);
  };
  const handleOnInformation = () => {
    navigate("/instruction/fronts");
  };
  return (
    <Box>
      <AppBarSaveDiscard
        title="Front Image"
        label="fronts"
        onSave={handleOnClose}
        onInformation={handleOnInformation}
      />
      <Box style={{}}>
        <UploadImage
          user={user}
          handleOnClose={handleOnClose}
          title={null}
          actionTitle={"Upload image"}
          property={"fronts"}
        />
      </Box>
    </Box>
  );
};

export default ScreenCreateFront;
