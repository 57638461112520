import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IconTheBonsaiProjectLogo } from "../Icon/TheBonsaiProjectLogo";
import { IconTheBonsaiProject } from "../Icon/TheBonsaiProject";
import FacebookIcon from "@mui/icons-material/Facebook";
import IconButton from "@mui/material/IconButton";
import { Fragment, useEffect } from "react";

function HomeIcon({ onClick }) {
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Fragment>
      {isXsBreakPoint ? (
        <IconTheBonsaiProjectLogo
          onClick={onClick}
          style={{
            width: "2rem",
            height: "auto",
            fill: "#EDE8DA",
            marginRight: "1rem",
          }}
        />
      ) : (
        <IconTheBonsaiProject
          onClick={onClick}
          style={{
            width: "12rem",
            height: "auto",
            fill: "#EDE8DA",
            marginRight: "1rem",
          }}
        />
      )}
    </Fragment>
  );
}

const AppBarRoot = ({ showHome = false, onHome, startAction, endAction }) => {
  const theme = useTheme();
  const isXsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // window.addEventListener("beforeunload", alertUser);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };
  }, []);

  const alertUser = (e) => {
    if (
      localStorage.getItem("bonsai-design_edited") == "false" ||
      localStorage.getItem("bonsai-design_edited") == null
    ) {
      // If we don't have unsaved changes, then we don't need to do anything
      return null;
    }

    // Prevent default behavior of leaving the screen
    e.preventDefault();

    // Prompt the user before leaving the screen

    let confirmed = window.confirm(
      "You will lose any unsaved changes if you leave. Are you sure you want to leave?"
    );
    if (confirmed == true) {
      window.dispatch(e.data.action);
    }

    return (e.returnValue = "Test return.");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        color="primary"
        position="relative"
        component="nav"
        style={{
          // border: "2px solid black",
          boxShadow: "0px 4px 0px 0px #687569", //#273223
          backgroundColor: "#829384",
          color: "#EDE8DA",
          // borderRadius: "0 0 .2rem .2rem",
          position: "relative",
          zIndex: 1,
          // width: "100vw",
        }}
      >
        <Toolbar>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // padding: "1rem",
              flex: 10,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {showHome && <HomeIcon onClick={onHome} />}

              {startAction && startAction}
              <div style={{ flexGrow: "1" }}></div>
              {endAction && endAction}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default AppBarRoot;
