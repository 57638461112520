import { Save, SaveAs } from "@mui/icons-material";
import IconBack from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { alertUserUnsavedChanges } from "../../libs/alertUserUnsavedChanges";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import Instructions from "../InstructionDialog";
import { SaveButtonFull } from "../SaveButton";
import SnackbarCustom from "../SnackbarCustom";
import AppBarRoot from "./Root";

function SaveMenu({ onClick, ...props }) {
  /**
   * MUI menu with two options to save. Save as new or save as draft.
   */
  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={true}
      {...props}
    >
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <SaveAs fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save as new design</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <Save fontSize="small" />
        </ListItemIcon>
        <ListItemText>Save</ListItemText>
      </MenuItem>
    </Menu>
  );
}

function AppBarMultiSaveDiscard({
  title,
  onSave,
  onInformation,
  onSaveNew,
  property,
}) {
  // state for snackbar
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const saveMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const vertical = "top";
  const horizontal = "center";
  const navigate = useNavigateToTop();

  const runSnackbar = (message) => {
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const saveSuccess = () => {
    runSnackbar("Image saved");
    setIsSaving(false);
  };
  const saveNewSuccess = () => {
    runSnackbar("Image saved as new design");
    setIsSaving(false);
  };
  const saveFailed = (e) => {
    runSnackbar("Failed to save image: " + e + ". Please try again.");
    setIsSaving(false);
  };
  const handleSaveMenuClick = (event) => {
    handleClose();
    const option = event.currentTarget.textContent;
    if (option === "Save") {
      onSave(saveSuccess, saveFailed);
    } else if (option === "Save as new design") {
      onSaveNew(saveNewSuccess, saveFailed);
    }
    setIsSaving(true);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUserUnsavedChanges);
    return () => {
      window.removeEventListener("beforeunload", alertUserUnsavedChanges);
    };
  }, []);
  return (
    <Fragment>
      <AppBarRoot
        startAction={
          <Stack direction={"row"} spacing={1}>
            <IconButton
              color="secondary"
              onClick={(e) => {
                alertUserUnsavedChanges(e, () => {
                  navigate(-1);
                });
              }}
            >
              <IconBack />
            </IconButton>
            <Typography
              variant="h5"
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "1.1rem",
              }}
            >
              {title}
            </Typography>
          </Stack>
        }
        endAction={
          <Stack direction={"row"} spacing={1}>
            <Instructions label={property} />

            <SaveButtonFull onClick={handleClick} saving={isSaving} />
          </Stack>
        }
      />
      <SaveMenu
        open={saveMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleSaveMenuClick}
      />
      <SnackbarCustom
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        key={vertical + horizontal}
      />
    </Fragment>
  );
}

export default AppBarMultiSaveDiscard;
