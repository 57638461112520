import jsPDF from "jspdf";
import TheBonsaiProjectLogo from "../images/brand/logo.png";

export async function bonsaiToPdf(bonsai, callback) {
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF();
  doc._cursorYLocation = 20;
  // var options = { orientation: "p", unit: "mm" };
  // var doc = new jsPDF(options);

  // Get the current date as a string (day number, month name, year number)
  let date = new Date();
  let dateString =
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear();

  // Add a title to the document
  addTitle(doc, "Bonsai Design" + " - " + dateString);

  // Add the front images to the document
  if (!bonsai.fronts || bonsai.fronts.length == 0) {
    endProcess(doc, callback);
    return;
  }
  await getImagesAndAddToPdf(doc, bonsai.fronts);

  // Add the sections to the document
  // Add outlines
  if (!bonsai.outlines || bonsai.outlines.length == 0) {
    endProcess(doc, callback);
    return;
  }
  addSection(doc, "Outlines");
  await getImagesAndAddToPdf(doc, bonsai.outlines);
  // Save the document

  // Add solutions
  if (!bonsai.solutions || bonsai.solutions.length == 0) {
    endProcess(doc, callback);
    return;
  }
  // add page
  newPage(doc);
  addSection(doc, "Structure");
  await getImagesAndAddToPdf(doc, bonsai.solutions);

  // Add Variations
  if (!bonsai.variations || bonsai.variations.length == 0) {
    endProcess(doc, callback);
    return;
  }
  // add page
  newPage(doc);
  addSection(doc, "Foliage");
  await getImagesAndAddToPdf(doc, bonsai.variations);

  // add Final Design
  if (!bonsai.finalDesign) {
    endProcess(doc, callback);
    return;
  }

  // add page
  newPage(doc);
  addSection(doc, "Final Design");
  await getImagesAndAddToPdf(doc, [bonsai.finalDesign]);

  endProcess(doc, callback);
  return;
}

function endProcess(doc, cb) {
  savePdf(doc);
  cb();
}

function savePdf(doc) {
  doc.save("TheBonsaiProject.pdf");
}

function addTitle(doc, title) {
  // draw the logo top right
  doc.addImage(TheBonsaiProjectLogo, "PNG", 180, 5, 20, 20);
  doc.text(title, 10, doc._cursorYLocation);
  doc._cursorYLocation += 10;
}

async function getImagesAndAddToPdf(doc, images) {
  return new Promise((resolve, reject) => {
    let promises = getImagesData(images);
    Promise.all(promises).then((data) => {
      addImages(doc, data);
      resolve();
    });
  });
}

function newPage(doc) {
  doc.addPage();
  // draw the logo top right
  doc.addImage(TheBonsaiProjectLogo, "PNG", 180, 5, 20, 20);
  doc._cursorYLocation = 10;
}

function addImages(doc, images) {
  let maxImageHeight = 100;
  let cursorX = 10;
  for (let i = 0; i < images.length; i++) {
    if (
      doc._cursorYLocation + maxImageHeight >
      doc.internal.pageSize.height - 10
    ) {
      newPage(doc);
      cursorX = 10;
    }

    // image scale
    let scale = maxImageHeight / images[i].height;
    let height = images[i].height * scale;
    let width = images[i].width * scale;
    let x = 0;
    let y = 0;

    if (cursorX + width > doc.internal.pageSize.width) {
      doc._cursorYLocation += maxImageHeight;
      cursorX = 10;
    }
    doc.addImage(
      images[i],
      "JPEG",
      cursorX,
      doc._cursorYLocation,
      width,
      height
    );
    cursorX += width + 10;
  }
  doc._cursorYLocation += maxImageHeight + 10;
}

function addSection(doc, section) {
  doc.text(section, 10, doc._cursorYLocation);
  doc._cursorYLocation += 10;
}

function getImagesData(images) {
  let promises = [];
  for (let i = 0; i < images.length; i++) {
    let promise = new Promise((resolve, reject) => {
      getImgFromUrl(images[i], function (img) {
        resolve(img);
      });
    });
    promises.push(promise);
  }
  return promises;
}

function getImgFromUrl(logo_url, callback) {
  var img = new Image();
  img.src = logo_url;
  img.onload = function () {
    callback(img);
  };
}
