import { deleteObject, getStorage, ref } from "firebase/storage";

export function deleteFile(fileName, cb = null, failCb = null) {
  const storage = getStorage();
  const fileRef = ref(storage, fileName);

  // 'file' comes from the Blob or File API
  deleteObject(fileRef)
    .then((snapshot) => {
      if (cb) {
        cb(snapshot);
      }
    })
    .catch((error) => {
      if (failCb) {
        failCb(error);
      }
    });
}
