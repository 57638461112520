import React from "react";
import { Box, Snackbar } from "@mui/material";

export default function SnackbarCustom({ ...props }) {
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Snackbar
        style={{
          transform: "unset",
          WebkitTransform: "unset",
        }}
        sx={{
          position: "inherit",
          marginTop: "3rem",
          "& .MuiSnackbarContent-root": {
            borderRadius: "3rem",
            padding: "0rem 2rem",
            "& .MuiSnackbarContent-message": {
              borderRadius: "3rem",
            },
          },
        }}
        {...props}
      />
    </Box>
  );
}
