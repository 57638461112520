export function isOwnerOfUrl(url, uid) {
  if (!url || !uid) {
    return false;
  }
  // does the url contain the uid?
  if (url.includes(uid)) {
    return true;
  }
  return false;
}
