import WhiteFoliageImage1 from "../images/designs/white (1).png";
import WhiteFoliageImage2 from "../images/designs/white (2).png";
import WhiteFoliageImage3 from "../images/designs/white (3).png";
import WhiteFoliageImage4 from "../images/designs/white (4).png";
import WhiteFoliageImage5 from "../images/designs/white (5).png";

import TransparentFoliageImage1 from "../images/designs/foliageColour (1).png";
import TransparentFoliageImage2 from "../images/designs/foliageColour (2).png";
import TransparentFoliageImage3 from "../images/designs/foliageColour (3).png";
import TransparentFoliageImage4 from "../images/designs/foliageColour (4).png";
import TransparentFoliageImage5 from "../images/designs/foliageColour (5).png";

import OutlineImage from "../images/designs/outline.png";
import SolutionImage1 from "../images/designs/solution (1).png";
import SolutionImage2 from "../images/designs/solution (2).png";
import SolutionImage3 from "../images/designs/solution (3).png";

import ColourImage from "../images/designs/colour.png";
import ColourImage1 from "../images/designs/colour (1).png";
import ColourImage2 from "../images/designs/colour (2).png";
import ColourImage3 from "../images/designs/colour (3).png";
import ColourImage4 from "../images/designs/colour (4).png";
import ColourImage5 from "../images/designs/colour (5).png";
import ColourImage6 from "../images/designs/colour (6).png";

import NoColourFoliageImage1 from "../images/designs/foliageNoColour (1).png";
import NoColourFoliageImage2 from "../images/designs/foliageNoColour (2).png";
import NoColourFoliageImage3 from "../images/designs/foliageNoColour (3).png";
import NoColourFoliageImage4 from "../images/designs/foliageNoColour (4).png";
import NoColourFoliageImage5 from "../images/designs/foliageNoColour (5).png";
import NoColourFoliageImage6 from "../images/designs/foliageNoColour (6).png";
import NoColourFoliageImage7 from "../images/designs/foliageNoColour (7).png";
import NoColourFoliageImage8 from "../images/designs/foliageNoColour (8).png";
import NoColourFoliageImage9 from "../images/designs/foliageNoColour (9).png";
import NoColourFoliageImage10 from "../images/designs/foliageNoColour (10).png";

import Photo1 from "../images/designs/photo (1).jpg";
import Photo2 from "../images/designs/photo (2).jpg";

import OrientationImage1 from "../images/designs/orientation (1).png";
import OrientationImage2 from "../images/designs/orientation (2).png";
import OrientationImage3 from "../images/designs/orientation (3).png";
import OrientationImage4 from "../images/designs/orientation (4).png";
import OrientationImage5 from "../images/designs/orientation (5).png";

export const bonsai = [
  {
    name: "Bonsai 1",
    id: "2c76a9c7-7f06-44ec-b14d-60cb610d8b6f",
    photos: [Photo1, Photo2],
    fronts: [Photo1, Photo2],
    outlines: [
      {
        src: OutlineImage,
        parent: Photo1,
      },
    ],
    solutions: [SolutionImage1, SolutionImage2, SolutionImage3],
    orientations: [
      OrientationImage1,
      OrientationImage2,
      OrientationImage3,
      OrientationImage4,
      OrientationImage5,
    ],
    variations: [
      WhiteFoliageImage1,
      WhiteFoliageImage2,
      WhiteFoliageImage3,
      WhiteFoliageImage4,
      WhiteFoliageImage5,
      ColourImage4,
      ColourImage5,
    ],
  },
  {
    name: "Bonsai 1",
    id: "e60e941a-bcd0-4adf-94c6-9b70eb1f86ce",
    photos: [WhiteFoliageImage1],
  },
  {
    name: "Bonsai 1",
    photos: [ColourImage1],
  },
  {
    name: "Bonsai 1",
    photos: [ColourImage3],
  },
  {
    name: "Bonsai 1",
    photos: [OrientationImage2],
  },
];

// {
//   name: "Bonsai 1",
//   id: "2c76a9c7-7f06-44ec-b14d-60cb610d8b6f",
//   photos: [Photo1, Photo2],
//   fronts: [Photo1, Photo2],
//   outlines: [
//     {
//       src: OutlineImage,
//       parent: Photo1,
//     },
//   ],
//   solutions: [SolutionImage1, SolutionImage2, SolutionImage3],
//   orientations: [
//     OrientationImage1,
//     OrientationImage2,
//     OrientationImage3,
//     OrientationImage4,
//     OrientationImage5,
//   ],
//   colours: [
//     ColourImage1,
//     ColourImage2,
//     ColourImage3,
//     ColourImage4,
//     ColourImage5,
//     ColourImage6,
//   ],
//   foliage: [
//     WhiteFoliageImage1,
//     WhiteFoliageImage2,
//     WhiteFoliageImage3,
//     WhiteFoliageImage4,
//     WhiteFoliageImage5,
//   ],
// },
