import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import { ElevatedImage } from "./ElevatedImage";
import PrimaryButton from "./PrimaryButton";
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bird",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));

function NewItem({ onClick }) {
  return (
    <Grid item xs={12 / 3} onClick={onClick}>
      <Item>
        <Box
          sx={{
            height: "fill-available",
            display: "block",
            maxWidth: 400,
            width: "fit-content",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "6rem",
          }}
        >
          <Add />
        </Box>
      </Item>
    </Grid>
  );
}

function NewItemButton({ onClick }) {
  return (
    <Grid item xs={12 / 2} onClick={onClick}>
      <PrimaryButton>Create</PrimaryButton>
    </Grid>
  );
}

function MultiImageCarousel({
  images,
  onClick,
  onNewItemClick = () => {},
  allowNew = true,
  isOwner = true,
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images ? images.length : 0;

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // set the active step so that shows the next set of images
    setActiveStep(activeStep + maxShow);
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // set the active step so that shows the previous set of images
    setActiveStep(activeStep - maxShow);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const maxShow = 3;

  // store the images to show starting from activeStep and ending at activeStep + maxShow
  const imagesToShow = [];
  for (let i = activeStep; i < activeStep + maxShow; i++) {
    // make sure doesnt exceed the length of the images array
    if (!images || i >= images.length) {
      break;
    }
    imagesToShow.push(images[i]);
  }

  // show the add button if there is any more images to show
  // check if is last available step
  let isLastStep = activeStep + maxShow >= images.length;

  const showAdd = !isLastStep && imagesToShow.length >= maxShow;

  function getBorderStyle(index) {
    if (isOwner.length > 0 && isOwner[index]) {
      return "1px solid #ccc";
    } else {
      return "1px solid green";
    }
  }

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, marginTop: "1rem" }}>
      {allowNew && <NewItemButton onClick={onNewItemClick} />}
      <Box
        style={{
          padding: "1rem",
          paddingLeft: "0",
          position: "relative",
        }}
      >
        <Grid container spacing={2}>
          {imagesToShow.map((image, index) => (
            <Grid
              key={index}
              onClick={() => onClick(image)}
              item
              xs={12 / maxShow}
            >
              <ElevatedImage
                style={{
                  border: getBorderStyle(index),
                }}
                src={image.src || image}
              />
            </Grid>
          ))}
        </Grid>

        {/* Left arrow absolute */}
        {activeStep > 0 && (
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{
              position: "absolute",
              top: "50%",
              height: "100%",
              left: "0",
              transform: "translateY(-50%)",
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        )}

        {/* Right arrow absolute */}
        {showAdd && (
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            style={{
              position: "absolute",
              top: "50%",
              right: "0",
              transform: "translateY(-50%)",
              height: "100%",
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        )}
      </Box>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </Box>
  );
}

export default MultiImageCarousel;
