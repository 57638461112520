import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import HeadlineImage from "../../images/artwork/thinking-bonsai-2.jpg";
import InstructionRoot from "./Root";

import MultiImageCarousel from "../../components/MultiImageCarousel";
import OutlineImage from "../../images/designs/outline.png";
import PhotoImage1 from "../../images/designs/photo (1).jpg";

import BonsaiMasonryDesignList from "../../components/BonsaiMasonryDesignList";
import Structure1Image from "../../images/designs/structures/structure (1).png";
import Structure2Image from "../../images/designs/structures/structure (2).png";
import Structure3Image from "../../images/designs/structures/structure (3).png";
import Structure4Image from "../../images/designs/structures/structure (4).png";
import Structure5Image from "../../images/designs/structures/structure (5).png";
import { InstructionSection } from "../../components/InstructionSection";

export default function ScreenInstructionSolution({ onClose }) {
  return (
    <InstructionRoot
      startTime="130"
      title="Creating structures"
      onClose={onClose}
    >
      {/* <Box
        style={{
          height: "10rem",
          width: "100%",
          overflow: "hidden",
          borderRadius: ".5rem",
          marginBottom: "1rem",
        }}
      >
        <img
          src={HeadlineImage}
          alt="Tree wrapped in fabric"
          style={{ width: "100%", height: "auto", marginTop: "-10rem" }}
        />
      </Box> */}
      <InstructionSection heading="Why?">
        <Typography gutterBottom>
          We often can't conceptualise ideas until it is down on paper. This is
          why we create lots of structures to test our different ideas and
          reveal what might be the best solution. Instead of permenant changes
          on the tree, we are exploring it on the design.
        </Typography>
      </InstructionSection>

      <InstructionSection heading="How?">
        <Typography gutterBottom>
          Think of each structure as a different design direction you could take
          your tree.
          {/* One might be a formal upright, another a cascade, the third a
        cascade with the main trunk turned into deadwood and a new leader for
        taper. */}
        </Typography>
        <Typography gutterBottom>
          Now apply the bonsai rules you know and love. Removing crouch
          branches, rotating for movement or a new style, adding taper,
          deadwood, adding branches you later graft etc. Whenever you want to go
          in a new direction, create a new structure.
        </Typography>
      </InstructionSection>

      <InstructionSection heading="Example of structures">
        <Box>
          <Typography gutterBottom>Here is my tree.</Typography>
          <img
            src={PhotoImage1}
            alt="Tree wrapped in fabric"
            style={{ width: "100%", height: "auto", borderRadius: "1rem" }}
          />
          <Typography gutterBottom>Here is the outline I created.</Typography>
          <img
            src={OutlineImage}
            alt="Tree wrapped in fabric"
            style={{ width: "70%", height: "auto" }}
          />
          <Typography>
            Here are some of the many solutions I was trying out.
          </Typography>
          <BonsaiMasonryDesignList
            data={[
              Structure1Image,
              Structure2Image,
              Structure3Image,
              Structure4Image,
              Structure5Image,
            ]}
            padding={"0"}
            onClick={() => {}}
          />
        </Box>
      </InstructionSection>

      <InstructionSection heading="What's next?">
        <Typography gutterBottom>
          We will add foliage to these different structures to find the most
          compelling design.
        </Typography>
      </InstructionSection>
    </InstructionRoot>
  );
}
