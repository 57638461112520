import IconBack from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Fragment } from "react";
import { useNavigateToTop } from "../../libs/useNavigateToTop";
import PrimaryButton from "../PrimaryButton";
import AppBarRoot from "./Root";
import ImageMasonry from "../ImageMasonry";
import { Send } from "@mui/icons-material";
import BonsaiProfileChanges from "../BonsaiProfileChanges";
function SuggestionMenu({ bonsai, images, onSend, onClose }) {
  /**
   * MUI menu with two options to save. Save as new or save as draft.
   */

  // images are an object of {fronts:[], outlines:[], solutions:[], variations:[]}

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Send your design</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ marginBottom: "1rem" }}
        >
          The owner of the bonsai will be able to see these designs and if they
          like it they can approve it. Below are the additions you have made to
          the design.
        </DialogContentText>

        <Box
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginBottom: "1rem",
          }}
        >
          <BonsaiProfileChanges originalBonsai={bonsai} suggestion={images} />
        </Box>

        <DialogContentText id="alert-dialog-description">
          Thanks for the effort you have put in to helping others bonsai
          enthusiasts. It makes bonsai better for everyone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSend}>
          Send design to owner
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function isThingsToSendBonsai(images) {
  if (!images) {
    return false;
  }

  // if images is an object then it has keys
  if (Object.keys(images).length === 0) {
    return false;
  }

  if (images.fronts && images.fronts.length > 0) {
    return true;
  }

  if (images.outlines && images.outlines.length > 0) {
    return true;
  }

  if (images.solutions && images.solutions.length > 0) {
    return true;
  }

  if (images.variations && images.variations.length > 0) {
    return true;
  }

  return false;
}

function AppBarSuggestionProfile({
  bonsai,
  onSendSuggestion,
  suggestionImages,
  sendAllowed = false,
  isPending = false,
}) {
  // Modal state
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalImages, setModalImages] = React.useState({});
  const navigate = useNavigateToTop();

  const thingsToSend = isThingsToSendBonsai(suggestionImages);
  const canSendSuggestion =
    sendAllowed && thingsToSend && isPending != null && !isPending;

  const noChanges = !thingsToSend;

  const handleSendSuggestion = () => {
    setModalOpen(false);
    onSendSuggestion(modalImages);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    setModalImages(suggestionImages);
  };

  let title = "Their design";

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      {modalOpen && (
        <SuggestionMenu
          bonsai={bonsai}
          images={modalImages}
          onSend={handleSendSuggestion}
          onClose={handleCloseModal}
        />
      )}
      <AppBarRoot
        startAction={
          <Stack direction={"row"} spacing={1}>
            <IconButton
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconBack />
            </IconButton>
            <Typography
              variant="h5"
              style={{ alignSelf: "center", color: "white" }}
            >
              {title}
            </Typography>
          </Stack>
        }
        endAction={
          <Stack direction={"row"} spacing={1}>
            {canSendSuggestion && (
              <PrimaryButton startIcon={<Send />} onClick={handleOpenModal}>
                Send
              </PrimaryButton>
            )}
            {noChanges && (
              <Typography alignSelf={"center"}>No changes</Typography>
            )}

            {isPending && (
              <Typography alignSelf={"center"}>Sent to owner</Typography>
            )}
            {/* <Instructions label={label} /> */}
          </Stack>
        }
      />
    </Fragment>
  );
}

export default AppBarSuggestionProfile;
