import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { Typography } from "@mui/material";
import LoadingImage from "../../images/artwork/bonsai-loading-screen-2.png";

/**
 * Create a React component using MUI. This is a loading screen that is used when the app is loading. It has a large picture accompanies by a loading spinner and a message. This is all centered in the middle of the screen.
 *
 */
export default function LoadingScreen({ title = "Loading..." }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={LoadingImage}
          alt="bonsai loading screen"
          style={{
            width: "100%",
            height: "auto",
            width: "40%",
            borderRadius: "2rem",
          }}
        />

        <Typography variant="h4">{title}</Typography>
        <Box style={{ width: "90%" }}>
          <LinearProgress style={{ width: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
}
