import p5 from "p5";
import { withBonsaiPot } from "../../mixins/bonsaiPot";
import { brushStrokeAtLocation } from "../../utilities/brush/stroke";
import { drawBonsaiPot } from "../../utilities/draw/bonsaiPot";
import { drawVaryingWidthLine } from "../../utilities/drawVaryingWidthLine";
import { floodFill } from "../../utilities/floodfill";
import {
  applyArrayToTaperSegments,
  pointIntersectsArray,
} from "../../utilities/lineBetweenPoints";
import { p5ImageMaskUsingGraphics } from "../../utilities/p5ImageMaskUsingGraphics";

import FoliageImage1 from "../../images/foliage/foliage1_fg.png";
import FoliageImage1Mid from "../../images/foliage/foliage1_mg.png";
import FoliageImage1Back from "../../images/foliage/foliage1_bg.png";
import FoliageImage2 from "../../images/foliage/foliage2_fg.png";
import FoliageImage2Mid from "../../images/foliage/foliage2_mg.png";
import FoliageImage2Back from "../../images/foliage/foliage2_bg.png";
import FoliageImage3 from "../../images/foliage/foliage3_fg.png";
import FoliageImage3Mid from "../../images/foliage/foliage3_mg.png";
import FoliageImage3Back from "../../images/foliage/foliage3_bg.png";
import FoliageImage4 from "../../images/foliage/foliage4_fg.png";
import FoliageImage4Mid from "../../images/foliage/foliage4_mg.png";
import FoliageImage4Back from "../../images/foliage/foliage4_bg.png";
import {
  createTransformativeImage,
  withFoliageImageManager,
} from "../../mixins/foliageImageManager";
import { withToolbar } from "../../mixins/toolbar";
import { floodFillCanvas } from "../../utilities/filltool";
import { isOutline, isSolution, isVariation } from "../../libs/toolbarSettings";
import { copyImageToNew, copyPixelsToNew } from "../../libs/copyImageToNew";
import { floodFillToCanvasAndExtractFlood } from "../../utilities/floodFillToCanvasAndExtractFlood";
import {
  replaceColourInImage,
  replaceEverythingButColourInImage,
} from "../../utilities/replaceColourInImage";
import { createTransformedImage } from "../../utilities/transformedImage";
import { rotationAboutAPoint } from "../../utilities/rotations";
import { isThereClosedShapeOnCanvas } from "../../utilities/checkClosedShapeOnCanvas";
import InfoAlertCannotFill from "../InfoAlert/CannotFill";
import { designElementsToPanScale } from "../../libs/designElementsToPanScale";

const mobileConsciousBrushYPosition = (drawingLibrary, vis) => {
  const isPhone = window.innerWidth < 600;
  return drawingLibrary.mouseY;
  return isPhone ? drawingLibrary.mouseY - 70 : drawingLibrary.mouseY;
};

const mobileConsciousBrushSize = (brushSize) => {
  const isPhone = window.innerWidth < 600;
  return isPhone ? brushSize / 2 : brushSize;
};

const withOptimisedLineDrawing = {
  setOptimisedLastDrawnPosition(x, y) {
    this._optimisedLastDrawnPosition = { x, y };
  },
  optimisedLastDrawnPosition() {
    return this._optimisedLastDrawnPosition || { x: 0, y: 0 };
  },
};

const withBranchStructure = {
  resetBranches() {
    this._branches = [];
  },
  addBranch(branch) {
    if (!this._branches) {
      this._branches = [];
    }
    this._branches.push(branch);
  },
  removeLastBranch() {
    if (this._branches) {
      this._branches.pop();
    }
  },
  branches() {
    return this._branches;
  },
  resetCurrentBranch() {
    this._currentBranch = [];
  },
  currentBranch() {
    return this._currentBranch || undefined;
  },
  currentBranchStartingTaper() {
    return this._currentBranchStartingTaper || 20;
  },
  setCurrentBranchStartingTaper(taper) {
    this._currentBranchStartingTaper = taper;
  },
  setCurrentBranch(branch) {
    this._currentBranch = branch;
  },
  addCurrentBranchToBranches() {
    if (this._currentBranch) {
      this._branches.push(this._currentBranch);
    }
    // Clear current branch
    this._currentBranch = undefined;
  },
};

const withBackgroundImage = {
  backgroundImage() {
    return this._backgroundImage;
  },
  setBackgroundImage(image) {
    this._backgroundImage = image;
  },
  toggleVisibilityBackgroundImage() {
    this._backgroundImageVisible = !this._backgroundImageVisible;
    if (this._onBackgroundVisibilityToggleChange) {
      this._onBackgroundVisibilityToggleChange(this._backgroundImageVisible);
    }
  },
  setOnBackgroundVisibilityToggleChange(callback) {
    this._onBackgroundVisibilityToggleChange = callback;
  },
  hideBackgroundImage() {
    this._backgroundImageVisible = false;
  },
  showBackgroundImage() {
    this._backgroundImageVisible = true;
  },
  isBackgroundImageVisible() {
    if (this._backgroundImageVisible === undefined) {
      this._backgroundImageVisible = true;
    }
    return this._backgroundImageVisible;
  },
  setBackgroundImageLoading() {
    this._backgroundImageLoading = true;
  },
  setBackgroundImageLoadingFinished() {
    this._backgroundImageLoading = false;
  },
  isBackgroundImageLoading() {
    return this._backgroundImageLoading;
  },
};

const withForegroundImage = {
  foregroundImage() {
    return this._foregroundImage;
  },
  setForegroundImage(image) {
    this._foregroundImage = image;
  },
  toggleVisibilityForegroundImage() {
    this._foregroundImageVisible = !this._foregroundImageVisible;
  },
  isForegroundImageVisible() {
    if (this._foregroundImageVisible === undefined) {
      this._foregroundImageVisible = true;
    }
    return this._foregroundImageVisible;
  },
  setForegroundImageLoading() {
    this._foregroundImageLoading = true;
  },
  setForegroundImageLoadingFinished() {
    this._foregroundImageLoading = false;
  },
  isForegroundImageLoading() {
    return this._foregroundImageLoading;
  },
};

const withBarkTextureImage = {
  barkTextureImage() {
    return this._barkTextureImage;
  },
  setBarkTextureImage(image) {
    this._barkTextureImage = image;
  },
};

const withEraser = {
  isEraser() {
    return this._drawingTool === "eraser";
  },
  setEraser() {
    this._brushMode = "eraser";
    this._drawingTool = "eraser";
  },
};

const withBrushColor = {
  brushColor() {
    return this._brushColor || "#000000";
  },
  setBrushColor(color) {
    this._brushColor = color;
    this._brushMode = "color";
  },
  isColorBrush() {
    return this._brushMode === "color";
  },
};

const withBrushTexture = {
  loadBrushTexture(name, texture, visualisation) {
    visualisation.drawingLibrary().loadImage(texture, (img) => {
      this._brushTexture = img;
      this._brushTextureName = name;
    });
  },
  brushTexture() {
    return this._brushTexture;
  },
  brushTextureName() {
    return this._brushTextureName;
  },
  setBrushTexture(name, image, drawingLibrary) {
    this._brushTexture = null;
    this._brushTextureName = null;
    this._brushMode = "texture";
    this.loadBrushTexture(name, image, drawingLibrary);
  },
  isTextureBrush() {
    return !this._brushMode || this._brushMode === "texture";
  },
};

const withBranchBrushTexture = {
  setBranchBrushTexture(name, image, drawingLibrary) {
    this._brushTexture = null;
    this._brushTextureName = null;
    this._brushMode = "branch";
    this.loadBrushTexture(name, image, drawingLibrary);
  },
  isBranchTextureBrush() {
    // console.log("isBranchTextureBrush", this._brushMode);
    return !this._brushMode || this._brushMode === "branch";
  },
};

const withEraserSize = {
  eraserSize() {
    return this._eraserSize || 12;
  },
  setEraserSize(size) {
    this._eraserSize = size;
  },
};

const withBrushSize = {
  brushSize() {
    return this._brushSize || 5;
  },
  setBrushSize(size) {
    this._brushSize = size;
  },
};

const withMaskHistort = {
  maskHistory() {
    return this._maskHistory || [];
  },
  setMaskHistory(history) {
    this._maskHistory = history;
  },
  latestMask() {
    return this._maskHistory[this._maskHistory.length - 1];
  },
  removeLastMask() {
    this._maskHistory.pop();
  },
  resetMaskHistory() {
    this._maskHistory = [];
  },
  setMaskUndoLimit(limit) {
    this._maskUndoLimit = limit;
  },
  addMaskToHistory(mask) {
    this._maskHistory.push(mask);
    // if (this._maskHistory.length > 2) {
    //   this._maskHistory.shift();
    // }
  },
  // function returns if undo can be performed
  canUndoMask() {
    return this._maskHistory.length > 1;
  },
};

const withFloodFillHistory = {
  floodFillHistory() {
    return this._floodFillHistory || [];
  },
  setFloodFillHistory(history) {
    this._floodFillHistory = history;
  },
  latestFloodFill() {
    return this._floodFillHistory[this._floodFillHistory.length - 1];
  },
  removeLastFloodFill() {
    this._floodFillHistory.pop();
  },
  resetFloodFillHistory() {
    this._floodFillHistory = [];
  },
  addFloodFillToHistory(floodFill) {
    this._floodFillHistory.push(floodFill);
  },
  canUndoFloodFill() {
    return this._floodFillHistory.length > 0;
  },
};

const withCanvasHistory = {
  canvasHistory() {
    return this._canvasHistory || [];
  },
  setCanvasHistory(history) {
    this._canvasHistory = history;
  },
  latestCanvas() {
    return this._canvasHistory[this._canvasHistory.length - 1];
  },
  removeLastCanvas() {
    this._canvasHistory.pop();
  },
  resetCanvasHistory() {
    this._canvasHistory = [];
  },
  setUndoLimit(limit) {
    this._undoLimit = limit;
  },
  addCanvasToHistory(canvas) {
    this._canvasHistory.push(canvas);
  },
  // function returns if undo can be performed
  canUndo() {
    return this._canvasHistory.length > 1;
  },
};

const withUndoRedo = {
  setUndo(undoFunction) {
    this.undo = () => {
      undoFunction();
    };
  },
};

const withDrawingTool = {
  setDrawingTool(tool) {
    this._drawingTool = tool;
  },
  drawingTool() {
    return this._drawingTool;
  },
  setTool(tool) {
    this._drawingTool = tool;
  },
  setBrushTool() {
    this.setDrawingTool("brush");
  },
  setEraserTool() {
    this.setDrawingTool("eraser");
  },
  setHandTool() {
    this.setDrawingTool("pan");
  },
  setDropperTool() {
    this.setDrawingTool("dropper");
  },
};

const withScale = {
  setScale(scale) {
    this._lastScale = this._scale;
    this._scale = scale;
  },
  lastScale() {
    return this._lastScale || 1;
  },
  scale() {
    return this._scale || 1;
  },
  incrementScale() {
    this._scale += 0.1;
  },
  decrementScale() {
    if (this._scale > 0.5) {
      this._scale -= 0.1;
    }
  },
};

const withCanvasVisual = {
  enableCanvasVisual() {
    this._canvasVisualEnabled = true;
  },
  disableCanvasVisual() {
    this._canvasVisualEnabled = false;
  },
  isCanvasVisualEnabled() {
    return this._canvasVisualEnabled;
  },
};

function resizeCanvasToBackgroundImage(drawingLibrary, canvas, image) {
  let backgroundImageWidth = image.width;
  let backgroundImageHeight = image.height;
  let scale = Math.min(
    drawingLibrary.width / backgroundImageWidth,
    drawingLibrary.height / backgroundImageHeight
  );

  canvas.resize(image.width * scale, image.height * scale);
}

export function createImageEditorVisualisation(containerElement, options) {
  const {
    framerate = 60,
    // brushSize = 10,
    // primaryColor = 'rgba(0,0,0, 1)',
    // backgroundImage,
    backgroundImagePosition,
    onCanvasChange,
    onInfoAlert,
    toolbars,
    // getBrushSize,
    // getPrimaryColor
  } = options;

  let changableBackgroundImagePosition = backgroundImagePosition;

  const map2d = pipeMixins(
    withP5DrawingLibrary,
    withBrushColor,
    withBrushSize,
    withEraser,
    withEraserSize,
    withUndoRedo,
    withCanvasHistory,
    withBackgroundImage,
    withForegroundImage,
    withBrushTexture,
    withBranchBrushTexture,
    withDrawingTool,
    withScale,
    withBranchStructure,
    withBarkTextureImage,
    withOptimisedLineDrawing,
    withBonsaiPot,
    withFoliageImageManager,
    withToolbar,
    withCanvasVisual,
    withFloodFillHistory
    // withGeoImageFromUserFiles,
    // withLayerManager
  );

  map2d.destroy = () => {
    map2d.destroyLibrary();
  };

  map2d._imageLoadFailed = false;

  map2d.setup = (drawingLibrary) => {
    // #REF
    drawingLibrary.disableFriendlyErrors = true;
    let cnv = drawingLibrary.createCanvas(
      containerElement.offsetWidth,
      containerElement.offsetHeight
      // 100,
      // 100
    );
    onCanvasChange(drawingLibrary);

    drawingLibrary._penAndPencilCanvas = drawingLibrary.createGraphics(
      drawingLibrary.width,
      drawingLibrary.height
    );
    drawingLibrary._penAndPencilCanvas.imageMode(drawingLibrary.CENTER);
    drawingLibrary._penAndPencilCanvas.angleMode(drawingLibrary.DEGREES);

    map2d.setOnBackgroundVisibilityToggleChange((isVisible) => {
      if (!isVisible) {
        if (isOutline(toolbars)) {
          // replace all the yellow pixels with black in canvas history
          map2d.canvasHistory().forEach((canvas) => {
            // if it is a p5image
            if (canvas.pixels) {
              replaceEverythingButColourInImage(
                canvas,
                {
                  r: 0,
                  g: 0,
                  b: 0,
                  a: 0,
                },
                "#000000"
              );
            }
          });

          // If is outline then replace foreground image with black
          if (map2d.foregroundImage()) {
            replaceEverythingButColourInImage(
              drawingLibrary._foregroundImage,
              {
                r: 0,
                g: 0,
                b: 0,
                a: 0,
              },
              "#000000"
            );
          }
        }
      } else {
        if (isOutline(toolbars)) {
          map2d.canvasHistory().forEach((canvas) => {
            // if it is a p5image
            if (canvas.pixels) {
              replaceColourInImage(canvas, "#000000", "#ffff00");
            }
          });

          // If is outline then replace foreground image with yellow
          if (map2d.foregroundImage()) {
            replaceColourInImage(
              drawingLibrary._foregroundImage,
              "#000000",
              "#ffff00"
            );
          }
        }
      }
    });

    drawingLibrary.prepareCanvasForSaving = () => {
      /**
       * - Deselect all the foliage
       * - Deselect all the pots
       * - Turn off image background
       */

      // Store for recentering later
      map2d._panXPrev = map2d._panX;
      map2d._panYPrev = map2d._panY;

      // Make sure all design elements are visible
      let designElements = [
        map2d.bonsaiPot(),
        {
          // this represents the foreground and background images
          height: drawingLibrary.height,
          width: drawingLibrary.width,
          x: drawingLibrary.width / 2,
          y: drawingLibrary.height / 2,
        },
      ];
      if (map2d.foliageImageManager()) {
        let images = map2d.foliageImageManager().images();
        if (images.length > 0) {
          designElements.push(...images);
        }
      }

      let { panX, panY, scale } = designElementsToPanScale(
        designElements,
        drawingLibrary.width,
        drawingLibrary.height
      );

      map2d._panX = -panX;
      map2d._panY = -panY;
      map2d.setScale(scale);

      map2d._imageLoadedAndResized = false;

      map2d.disableCanvasVisual();
      map2d.foliageImageManager().deselectAll();
      map2d.bonsaiPot().stopTransforming();

      map2d.foliageImageManager().hideTriangle();
      if (isOutline(toolbars)) {
        map2d.hideBackgroundImage();
        // replace all the yellow pixels with black in canvas history
        map2d.canvasHistory().forEach((canvas) => {
          // if it is a p5image
          if (canvas.pixels) {
            replaceEverythingButColourInImage(
              canvas,
              {
                r: 0,
                g: 0,
                b: 0,
                a: 0,
              },
              "#000000"
            );
          }
        });
      }

      // render the canvas
      drawingLibrary.redraw();

      // Stop the animation
      drawingLibrary.noLoop();
    };

    drawingLibrary.restoreCanvasAfterSaving = () => {
      // take back the pan
      map2d._panX = map2d._panXPrev;
      map2d._panY = map2d._panYPrev;

      // restore the scale
      map2d.setScale(map2d.lastScale());

      if (isOutline(toolbars)) {
        let outlineIsValid = isThereClosedShapeOnCanvas(
          drawingLibrary,
          drawingLibrary._canvas
        );

        if (!outlineIsValid) {
          onInfoAlert(
            <InfoAlertCannotFill
              onClose={() => {
                onInfoAlert(null);
              }}
            />
          );
        }
      }

      map2d.enableCanvasVisual();
      map2d.showBackgroundImage();
      if (isOutline(toolbars)) {
        // replace all the black pixels with yellow in canvas history
        map2d.canvasHistory().forEach((canvas) => {
          // if it is a p5image
          if (canvas.pixels) {
            replaceColourInImage(canvas, "#000000", "#ffff00");
          }
        });
      }
      // render the canvas
      drawingLibrary.redraw();

      // Start the animation
      drawingLibrary.loop();
    };

    map2d.enableCanvasVisual();
    map2d.showBackgroundImage();
    map2d.setBrushTool();
    map2d.setScale(1);
    map2d.setBrushSize(mobileConsciousBrushSize(3));
    map2d._brushMode = "color";
    map2d.setToolbar(map2d.OUTLINER_TOOLBAR);

    map2d._lastEraseLocations = [];
    map2d.resetFloodFillHistory();

    drawingLibrary._canvas = cnv;

    drawingLibrary.frameRate(framerate);
    drawingLibrary.angleMode(drawingLibrary.DEGREES);
    drawingLibrary.imageMode(drawingLibrary.CENTER);

    // draw a white circle in the middel with black stroke

    // INITIAL LOADING
    //load background image
    // if (map2d.backgroundImage()) {
    //   const backgroundImage = map2d.backgroundImage();
    //   if (!map2d._imageLoadFailed && !map2d.isBackgroundImageLoading()) {
    //     map2d.setBackgroundImageLoading();
    //     drawingLibrary.loadImage(
    //       backgroundImage.src,
    //       (img) => {
    //         drawingLibrary._backgroundImage = img;
    //         drawingLibrary._backgroundImageSrc = backgroundImage.src;
    //         resizeCanvasToBackgroundImage(
    //           drawingLibrary,
    //           drawingLibrary._canvas,
    //           img
    //         );
    //         resizeCanvasToBackgroundImage(
    //           drawingLibrary,
    //           drawingLibrary._penAndPencilCanvas,
    //           img
    //         );
    //       },
    //       (error) => {
    //         map2d._imageLoadFailed = true;
    //         alert(error);
    //         console.error("Image load failed", error);
    //       }
    //     );
    //   }
    // }
    // if (map2d.foregroundImage()) {
    //   const foregroundImage = map2d.foregroundImage();
    //   if (!map2d._imageLoadFailed && !map2d.isForegroundImageLoading()) {
    //     map2d.setForegroundImageLoading();
    //     drawingLibrary.loadImage(
    //       foregroundImage.src,
    //       (img) => {
    //         drawingLibrary._foregroundImage = img;
    //         drawingLibrary._foregroundImageSrc = foregroundImage.src;
    //       },

    //       (error) => {
    //         map2d._imageLoadFailed = true;
    //         alert(error);
    //         console.error("Image load failed", error);
    //       }
    //     );
    //   }
    // }

    if (isVariation(toolbars)) {
      // Load in the foliage images
      drawingLibrary.loadImage(FoliageImage1, (img) => {
        map2d._foliageImage1 = createTransformativeImage(img);
        // set position to middle of screen
        map2d._foliageImage1.setX(drawingLibrary.width / 2);
        map2d._foliageImage1.setY(drawingLibrary.height / 2);
        img.name = "FoliageImage1";
        map2d.foliageImageManager().addSourceImage(img);

        drawingLibrary.loadImage(FoliageImage1Mid, (imgVariant) => {
          map2d.foliageImageManager().addImageVariant(img, imgVariant);

          drawingLibrary.loadImage(FoliageImage1Back, (imgVariant) => {
            map2d.foliageImageManager().addImageVariant(img, imgVariant);
          });
        });
      });
      drawingLibrary.loadImage(FoliageImage2, (img) => {
        map2d._foliageImage2 = createTransformativeImage(img);
        // map2d.foliageImageManager().setSelection(img);
        img.name = "FoliageImage2";
        map2d.foliageImageManager().addSourceImage(img);

        drawingLibrary.loadImage(FoliageImage2Mid, (imgVariant) => {
          map2d.foliageImageManager().addImageVariant(img, imgVariant);

          drawingLibrary.loadImage(FoliageImage2Back, (imgVariant) => {
            map2d.foliageImageManager().addImageVariant(img, imgVariant);
          });
        });
      });

      drawingLibrary.loadImage(FoliageImage3, (img) => {
        img.name = "FoliageImage3";
        map2d.foliageImageManager().addSourceImage(img);

        drawingLibrary.loadImage(FoliageImage3Mid, (imgVariant) => {
          map2d.foliageImageManager().addImageVariant(img, imgVariant);

          drawingLibrary.loadImage(FoliageImage3Back, (imgVariant) => {
            map2d.foliageImageManager().addImageVariant(img, imgVariant);
          });
        });
      });

      drawingLibrary.loadImage(FoliageImage4, (img) => {
        img.name = "FoliageImage4";
        map2d.foliageImageManager().addSourceImage(img);

        drawingLibrary.loadImage(FoliageImage4Mid, (imgVariant) => {
          map2d.foliageImageManager().addImageVariant(img, imgVariant);

          drawingLibrary.loadImage(FoliageImage4Back, (imgVariant) => {
            map2d.foliageImageManager().addImageVariant(img, imgVariant);
          });
        });
      });
    }

    // EVENT HANDLING

    // on press, make the image alpha 255 where the mouse is
    drawingLibrary.mouseReleased = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }

      localStorage.setItem("bonsai-design_edited", true);

      // if (map2d._foliageImage1) {
      //   map2d._foliageImage1.onMouseReleased(drawingLibrary);
      // }
      // if (map2d.drawingTool() === "resizePot") {
      //   if (!map2d.isBonsaiPotVisible()) {
      //     return;
      //   }
      //   map2d.bonsaiPot().onMouseReleased(map2d, drawingLibrary);
      // }
      // if rotate
      if (map2d.drawingTool() === "rotate") {
        // add rotate to canvas histroy
        // map2d.canvasHistory().push({
        //   action: "rotate",
        //   value: map2d._rotate,
        // });

        // Go through each canvas history and rotate the canvas
        map2d.canvasHistory().forEach((canvas) => {
          // get the current rotation
          // if action
          if (canvas.action) {
            // log panx and pany
            let image = canvas.image;
            let currentRotation = image._rotation || 0;
            currentRotation += map2d._rotate;
            image._rotation = currentRotation;
          }
          // if not an array
          else if (!Array.isArray(canvas)) {
            let currentRotation = canvas._rotation || 0;
            currentRotation += map2d._rotate;
            canvas._rotation = currentRotation;
          } else {
            let currentRotation = canvas.rotation || 0;
            currentRotation += map2d._rotate;
            canvas.rotation = currentRotation;
          }
        });

        // rotate the flood fill history

        map2d.floodFillHistory().forEach((canvas) => {
          // get the current rotation
          // set the rotation
          let currentRotation = canvas.rotation() || 0;
          currentRotation += map2d._rotate;
          canvas.setRotation(currentRotation);
        });

        if (drawingLibrary._backgroundImage) {
          let currentRotation = drawingLibrary._backgroundImage._rotation || 0;
          currentRotation += map2d._rotate;
          drawingLibrary._backgroundImage._rotation = currentRotation;
        }

        if (drawingLibrary._foregroundImage) {
          let currentRotation = drawingLibrary._foregroundImage._rotation || 0;
          currentRotation += map2d._rotate;
          drawingLibrary._foregroundImage._rotation = currentRotation;
        }

        map2d._rotate = 0;
      } else if (map2d.drawingTool() === "pan") {
        map2d.bonsaiPot().onMouseReleased(map2d, drawingLibrary);

        if (map2d.foliageImageManager()) {
          map2d.foliageImageManager().onMouseReleased(map2d, drawingLibrary);
        }
        if (map2d._wasTwoFingerScaling) {
          map2d._wasTwoFingerScaling = false;
          map2d._dragStartX = null;
          map2d._dragStartY = null;
          return;
        }
        if (map2d._dragStartX === null || map2d._dragStartY === null) {
          return;
        }

        // set pan values using drag start and end
        map2d._panX =
          map2d._panX +
          (drawingLibrary.mouseX - map2d._dragStartX) / map2d.scale();
        map2d._panY =
          map2d._panY +
          (drawingLibrary.mouseY - map2d._dragStartY) / map2d.scale();
        // reset drag start position
        map2d._dragStartX = null;
        map2d._dragStartY = null;
      } else if (map2d.drawingTool() === "floodFill") {
        localStorage.setItem("bonsai-design_edited", true);
        // If it is a touch event then ignore
        if (event.touches) {
          return;
        }

        // Make the image as pixel dense as canvas
        drawingLibrary._canvas.loadPixels();
        let floodFillImage = copyPixelsToNew(
          drawingLibrary._canvas._pInst.imageData.data,
          drawingLibrary._canvas.height,
          drawingLibrary._canvas.width,
          drawingLibrary
        );

        let density = drawingLibrary.pixelDensity();
        floodFillImage.resize(
          floodFillImage.width / density,
          floodFillImage.height / density
        );

        // loop through the flood fill image pixels and check if any colour other than white
        floodFillImage.loadPixels();

        let x = Math.floor(drawingLibrary.mouseX);
        let y = Math.floor(drawingLibrary.mouseY);
        // let backgroundImage = drawingLibrary._backgroundImage;
        floodFillToCanvasAndExtractFlood(
          floodFillImage,
          x,
          y,
          1,
          map2d.brushColor()
        );
        floodFillImage.updatePixels();
        floodFillImage._rotation = 0;

        x = (-map2d._panX - drawingLibrary.width / 2) * map2d.scale();
        y = (-map2d._panY - drawingLibrary.height / 2) * map2d.scale();
        floodFillImage = createTransformedImage(
          floodFillImage,
          x,
          y,
          map2d.scale()
        );

        // drawingLibrary._backgroundImage = floodFillImage;
        // add to flood fill history

        // let floodFillImage = floodFillToCanvasAndExtractFlood(
        //   drawingLibrary,
        //   drawingLibrary._canvas,
        //   x,
        //   y,
        //   map2d.brushColor()
        // );
        // map2d.addFloodFillToHistory(floodFillImage);
        map2d.addCanvasToHistory({
          action: "floodFill",
          image: floodFillImage,
        });

        // log tthe flood fill histroy
      } else if (map2d.drawingTool() === "brush") {
        localStorage.setItem("bonsai-design_edited", true);
        // taper texture
        if (map2d.isBranchTextureBrush()) {
          const currentBranch = map2d.currentBranch();
          if (currentBranch !== undefined) {
            if (!currentBranch.length) {
              // Want to copy the taper.

              // Check if point is intersecting with a previous line
              // Current mouse position as point
              let transX =
                map2d._panX + drawingLibrary.width / 2 / map2d.scale();
              let transY =
                map2d._panY + drawingLibrary.height / 2 / map2d.scale();
              let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);
              const point = {
                x: drawingLibrary.mouseX / map2d.scale() - transX,
                y: yDraw / map2d.scale() - transY,
              };

              let intersection = false;
              // Loop through all the brnaches
              const branches = map2d.branches();
              if (branches) {
                for (let i = 0; i < branches.length; i++) {
                  const line = branches[i];
                  intersection = pointIntersectsArray(point, line);
                  if (intersection) {
                    break;
                  }
                }
              }

              // If so then copy the taper
              // If not then keep the taper
              if (intersection) {
                // Copy the taper
                map2d.setBrushSize(intersection.taper * 0.66);
              } else {
                // reset the taper
                // map2d.setBrushSize(undefined);
              }
            } else {
              // Simplify the drawn line using Ramer-Douglas-Peucker algorithm
              // Simplifying line
              const simplifiedLine = currentBranch; //simplifyLine(currentBranch, 1);

              // let taperedLine = applyArrayToTaperSegments(
              //   simplifiedLine,
              //   map2d.brushSize(),
              //   1
              // );

              const branches = map2d.branches();
              if (!branches) {
                map2d.resetBranches();
              }
              map2d.addBranch(currentBranch);
            }
          }
        } else {
          if (!map2d.iDrewSomething) {
            return;
          }

          // capture the current canvas and add it to the history
          drawingLibrary._circleMask.updatePixels();

          map2d.addCanvasToHistory(drawingLibrary._circleMask);

          // create new graphics object
          drawingLibrary._circleMask = drawingLibrary.createGraphics(
            drawingLibrary.width,
            drawingLibrary.height
          );
          drawingLibrary._circleMask.imageMode(drawingLibrary.CENTER);
          drawingLibrary._circleMask.angleMode(drawingLibrary.DEGREES);

          // drawingLibrary._canvasHistory.push(drawingLibrary._circleMask.get());

          map2d.iDrewSomething = false;
        }
      } else if (map2d.drawingTool() === "eraser") {
        localStorage.setItem("bonsai-design_edited", true);
        // If it was an eraser than we add the array to canvas histroy and not the mask / canvas

        if (map2d._lastEraseLocations.length) {
          map2d.addCanvasToHistory([...map2d._lastEraseLocations]);

          map2d._lastEraseLocations.length = 0;
        }
      } else if (map2d.drawingTool() === "dropper") {
        const color = drawingLibrary.get(
          drawingLibrary.mouseX,
          drawingLibrary.mouseY
        );
        map2d.setBrushColor(color);
        map2d.setBrushTool();
      }
    };

    // on touch move print out console
    drawingLibrary.touchMoved = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }

      const isPhone = window.innerWidth < 600;
      // If there's exactly one finger inside this element then pan
      if (event.touches.length === 1) {
        drawingLibrary.mouseDragged(event);
        return;
      }

      // if drawing tool is pan
      if (map2d.drawingTool() === "pan") {
        if (event.touches.length === 2) {
          map2d._wasTwoFingerScaling = true;
        }
        // // if there are two fingers then calcualte distance between them and zoom in or out accordingly
        if (event.touches.length === 2) {
          const touch1 = event.touches[0];
          const touch2 = event.touches[1];
          const dx = touch1.clientX - touch2.clientX;
          const dy = touch1.clientY - touch2.clientY;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (map2d._lastDistance) {
            const scale = distance / map2d._lastDistance;
            map2d._scale *= scale;
          }
          map2d._lastDistance = distance;
          map2d._lastTouches = event.touches;
          return;
        }
      }
    };
    map2d._wasTwoFingerScaling = false;

    drawingLibrary.touchEnded = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }
      map2d._lastDistance = null;
      if (event.touches.length === 0) {
        drawingLibrary.mouseReleased(event);
      } else if (event.touches.length === 1) {
        if (map2d._lastTouches && map2d._lastTouches.length === 2) {
          const touch1 = map2d._lastTouches[0];
          const touch2 = map2d._lastTouches[1];
          const dx = touch1.clientX - touch2.clientX;
          const dy = touch1.clientY - touch2.clientY;
        }
      }
    };

    map2d._panX = -drawingLibrary.width / 2;
    map2d._panY = -drawingLibrary.height / 2;

    map2d._dragStartX = null;
    map2d._dragStartY = null;

    // drawingLibrary.mouseReleased = (event) => {
    //   if (event.target !== drawingLibrary._canvas.canvas) {
    //     return;as9
    //   }
    //   // x and y position of the mouse
    //   const xCursor = drawingLibrary.mouseX;
    //   const yCursor = drawingLibrary.mouseY;

    //   // floodFill(xCursor, yCursor, drawingLibrary);

    // };

    drawingLibrary.keyPressed = (event) => {
      // if pressing ctrl + z then undo
      if (event.ctrlKey && event.key === "z") {
        map2d.undo();
      }
    };

    // mouste pressed
    drawingLibrary.mousePressed = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }
      // if (event.touches.length === 2) {
      //     return;
      // }

      if (map2d.drawingTool() === "resizePot") {
      }
      if (map2d.drawingTool() === "pan") {
        if (
          (map2d.toolbar() === map2d.ORIENTATION_TOOLBAR ||
            map2d.toolbar() === map2d.COLOURING_TOOLBAR ||
            map2d.toolbar() === map2d.FOLIAGE_TOOLBAR) &&
          map2d.bonsaiPot().onMousePressed(map2d, drawingLibrary)
        ) {
          return;
        }

        if (
          map2d.foliageImageManager() &&
          map2d.foliageImageManager().onMousePressed(map2d, drawingLibrary)
        ) {
          return;
        }
        map2d._dragStartX = drawingLibrary.mouseX;
        map2d._dragStartY = drawingLibrary.mouseY;
      }

      if (map2d.drawingTool() === "brush") {
        // drawing branching
        if (map2d.isBranchTextureBrush()) {
          map2d.resetCurrentBranch();
        }
      }

      map2d.setOptimisedLastDrawnPosition(
        drawingLibrary.mouseX,
        drawingLibrary.mouseY
      );
    };

    map2d.xCursor = 0;
    map2d.yCursor = 0;

    drawingLibrary.mouseDragged = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }

      // if (map2d.foliageImageManager()) {
      //   map2d.foliageImageManager().onMouseDragged(map2d, drawingLibrary);
      // }

      // if (!map2d.brushTexture()) {
      //   return;
      // }
      const isPhone = window.innerWidth < 600;
      if (map2d.drawingTool() === "pan") {
        // pan the image
        // if phone then pan scale is 1 else 0.05
        // const panScale = (isPhone) ? 1 : 1;
        // map2d._panX += (drawingLibrary.mouseX - drawingLibrary.pmouseX) / map2d.scale();
        // map2d._panY += (drawingLibrary.mouseY - drawingLibrary.pmouseY) / map2d.scale();

        map2d.bonsaiPot().onMouseDragged(map2d, drawingLibrary);
        if (
          map2d.foliageImageManager() &&
          map2d.foliageImageManager().onMouseDragged(map2d, drawingLibrary)
        ) {
          return;
        }
        return;
      } else if (map2d.drawingTool() === "floodFill") {
        return;
      } else if (map2d.drawingTool() === "resizePot") {
        if (!map2d.isBonsaiPotVisible()) {
          return;
        }
        map2d.bonsaiPot().onMouseDragged(map2d, drawingLibrary);
      } else if (map2d.drawingTool() === "rotate") {
        // rotate the image
        // if phone then rotate scale is 1 else 0.05
        const rotateScale = isPhone ? 1 : 0.5;
        if (map2d._rotate === undefined) {
          map2d._rotate = 0;
        }

        map2d._rotate = rotationAboutAPoint(
          map2d._rotate,
          drawingLibrary.mouseX,
          drawingLibrary.mouseY,
          drawingLibrary.pmouseX,
          drawingLibrary.pmouseY,
          drawingLibrary.width / 2,
          drawingLibrary.height / 2,
          rotateScale
        );

        return;
      } else if (map2d.drawingTool() === "brush" || map2d.isEraser()) {
        map2d.iDrewSomething = true;

        // if the color bush mode then draw the color
        if (map2d.isColorBrush() || map2d.isEraser()) {
          let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);
          drawingLibrary._circleMask.push();
          const brushSize = map2d.isEraser()
            ? map2d.eraserSize()
            : map2d.brushSize();

          // if shift key pressed
          if (map2d.isEraser()) {
            let transX = map2d._panX + drawingLibrary.width / 2 / map2d.scale();
            let transY =
              map2d._panY + drawingLibrary.height / 2 / map2d.scale();
            let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);

            map2d._lastEraseLocations.push({
              x:
                drawingLibrary.mouseX / map2d.scale() -
                transX -
                drawingLibrary.width / 2,
              y: yDraw / map2d.scale() - transY - drawingLibrary.height / 2,
              brushSize: brushSize,
              rotation: 0,
            });
          }
          if (map2d.isEraser()) {
            drawingLibrary._circleMask.erase();
          }
          let strokeColor = "black";
          // if its the outline brush then set yellow
          if (isOutline(toolbars)) {
            strokeColor = "yellow";
          }

          brushStrokeAtLocation(
            yDraw,
            brushSize,
            map2d,
            drawingLibrary,
            drawingLibrary._circleMask,
            strokeColor
          );

          drawingLibrary._circleMask.noErase();
        }
        // BRANCHING
        else if (map2d.isBranchTextureBrush()) {
          // if mouse pressed
          if (drawingLibrary.mouseIsPressed) {
            // add empty array property to window object called drawnLine
            let currentBranch = map2d.currentBranch();
            if (currentBranch === undefined) {
              currentBranch = [];
            }
            // add current mouse position to drawnLine array
            // Modify the drawing to take scale and translation into account
            let transX = map2d._panX + drawingLibrary.width / 2 / map2d.scale();
            let transY =
              map2d._panY + drawingLibrary.height / 2 / map2d.scale();
            let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);
            currentBranch.push({
              x: drawingLibrary.mouseX / map2d.scale() - transX,
              y: yDraw / map2d.scale() - transY,
            });

            applyArrayToTaperSegments(currentBranch, map2d.brushSize(), 1);
            map2d.setCurrentBranch(currentBranch);
          }
        } else {
          let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);

          // Draw the foliage image to the circular mask
          // Calculate a random rotation between -65 and 65 degrees
          const rotation = Math.floor(Math.random() * 130) - 65;

          // calculate a random scale between 0.5 and 1.5
          const scale = Math.random() * 1 + 0.5;
          // Random offset between -5 and 5
          const xOffset =
            Math.floor((Math.random() * map2d.brushSize()) / 3) -
            map2d.brushSize() / 6;
          const yOffset =
            Math.floor((Math.random() * map2d.brushSize()) / 3) -
            map2d.brushSize() / 6;

          // calculate a random darkening from 200 to 150
          const darkening = Math.floor(Math.random() * 50) + 175;

          // randomize the alpha between 200 and 255
          const alpha = Math.floor(Math.random() * 55) + 200;

          drawingLibrary._circleMask.push();

          let transX = map2d._panX + drawingLibrary.width / 2 / map2d.scale();
          let transY = map2d._panY + drawingLibrary.height / 2 / map2d.scale();
          // drawingLibrary._circleMask.scale(map2d.scale());
          drawingLibrary._circleMask.translate(-transX, -transY);
          drawingLibrary._circleMask.translate(
            drawingLibrary.mouseX / map2d.scale() + xOffset,
            yDraw / map2d.scale() + yOffset
          );
          drawingLibrary._circleMask.rotate(rotation);
          drawingLibrary._circleMask.scale(scale);
          drawingLibrary._circleMask.tint(darkening, alpha);
          drawingLibrary._circleMask.image(
            map2d.brushTexture(),
            0,
            0,
            map2d.brushSize(),
            map2d.brushSize()
          );
          drawingLibrary._circleMask.pop();
        }
      }
    };

    // If scroll increase scale
    drawingLibrary.mouseWheel = (event) => {
      if (event.target !== drawingLibrary._canvas.canvas) {
        return;
      }
      event.preventDefault();
      if (event.delta > 0) {
        map2d.decrementScale();
      } else {
        map2d.incrementScale();
      }
    };

    drawingLibrary._circleMask = drawingLibrary.createGraphics(
      cnv.width,
      cnv.height
    );
    drawingLibrary._circleMask.imageMode(drawingLibrary.CENTER);
    drawingLibrary._circleMask.angleMode(drawingLibrary.DEGREES);

    // draw a red cricle in the middel
    drawingLibrary._circleMask.fill(255, 0, 0);
    drawingLibrary._circleMask.noStroke();

    // Create a history of previous canvas states.
    map2d.setCanvasHistory([drawingLibrary._circleMask.get()]);

    drawingLibrary.frameRate(framerate);

    map2d.setUndo(() => {
      // #REF
      localStorage.setItem("bonsai-design_edited", true);
      // log the current canvas state
      // map2d.removeLastCanvas();
      if (map2d.isBranchTextureBrush()) {
        map2d.removeLastBranch();
        map2d.setCurrentBranch(undefined);
      }
      // if is flood fill
      // else if (map2d.drawingTool() === "floodFill") {
      //   map2d.removeLastFloodFill();
      // }
      else {
        map2d.removeLastCanvas();
        let latestCanvas = map2d.latestCanvas();

        // if latest canvas is an array
        // latest canvas state is the current canvas state
        drawingLibrary._circleMask.clear();
        if (latestCanvas && !Array.isArray(latestCanvas)) {
          // drawingLibrary._circleMask.set(drawingLibrary._canvasHistory[drawingLibrary._canvasHistory.length - 1]);
          drawingLibrary._circleMask.image(
            latestCanvas,
            drawingLibrary.width / 2,
            drawingLibrary.height / 2
          );
        }
        drawingLibrary._circleMask.updatePixels();
      }
      // window.plausible('Undo Bonsai Designer');
    });
  };
  map2d.draw = (drawingLibrary) => {
    // #REF
    // drawingLibrary.background(255);
    drawingLibrary._penAndPencilCanvas.clear();
    drawingLibrary.clear();
    drawingLibrary.smooth();

    // pan the image
    drawingLibrary.push();
    drawingLibrary.scale(map2d.scale());
    // draw scale

    // drawingLibrary.translate(map2d._panX + drawingLibrary.width / 2 / map2d.scale(), map2d._panY + drawingLibrary.height / 2 / map2d.scale());
    let transX = map2d._panX + drawingLibrary.width / 2 / map2d.scale();
    let transY = map2d._panY + drawingLibrary.height / 2 / map2d.scale();
    // if mouse pressed
    if (drawingLibrary.mouseIsPressed && !map2d._wasTwoFingerScaling) {
      if (map2d._dragStartX && !map2d._wasTwoFingerScaling) {
        transX += (drawingLibrary.mouseX - map2d._dragStartX) / map2d.scale();
      }
      if (map2d._dragStartY && !map2d._wasTwoFingerScaling) {
        transY += (drawingLibrary.mouseY - map2d._dragStartY) / map2d.scale();
      }
    }

    drawingLibrary.translate(transX, transY);

    let latestFloodFillImage = map2d.latestFloodFill();
    if (map2d.isCanvasVisualEnabled()) {
      if (drawingLibrary._backgroundImage) {
        let canvasVisualWidth = drawingLibrary._backgroundImage.width;
        let canvasVisualHeight = drawingLibrary._backgroundImage.height;
        let canvasVisualScale = Math.min(
          drawingLibrary.width / canvasVisualWidth,
          drawingLibrary.height / canvasVisualHeight
        );
        let canvasImagePosition = {
          x: changableBackgroundImagePosition.x,
          y: changableBackgroundImagePosition.y,
        };
        drawingLibrary.fill(255);
        drawingLibrary.rect(
          canvasImagePosition.x,
          canvasImagePosition.y,
          canvasVisualWidth * canvasVisualScale,
          canvasVisualHeight * canvasVisualScale
        );
      }
    }
    if (map2d.foliageImageManager()) {
      map2d.foliageImageManager().drawBackground(drawingLibrary);
    }

    if (
      map2d.toolbar() === map2d.ORIENTATION_TOOLBAR ||
      map2d.toolbar() === map2d.COLOURING_TOOLBAR ||
      map2d.toolbar() === map2d.FOLIAGE_TOOLBAR
    ) {
      if (map2d.isBonsaiPotVisible()) {
        map2d.bonsaiPot().draw(drawingLibrary, false);
      }
    }

    const backgroundImage = map2d.backgroundImage();

    if (map2d.isBackgroundImageVisible()) {
      if (
        drawingLibrary._backgroundImage &&
        drawingLibrary._backgroundImageSrc === backgroundImage.src
      ) {
        // get the height and width of the background image, then draw to fit the canvas
        let backgroundImageWidth = drawingLibrary._backgroundImage.width;
        let backgroundImageHeight = drawingLibrary._backgroundImage.height;
        let backgroundImageScale = Math.min(
          drawingLibrary.width / backgroundImageWidth,
          drawingLibrary.height / backgroundImageHeight
        );
        drawImageScaledAtPosition(
          drawingLibrary,
          drawingLibrary._backgroundImage,
          changableBackgroundImagePosition.x,
          changableBackgroundImagePosition.y,
          backgroundImageScale,
          map2d._rotate + drawingLibrary._backgroundImage._rotation || 0
        );
      } else {
        if (backgroundImage && backgroundImage.src) {
          if (!map2d.isBackgroundImageLoading()) {
            map2d.setBackgroundImageLoading(true);
            drawingLibrary.loadImage(
              backgroundImage.src,
              (img) => {
                drawingLibrary._backgroundImage = img;
                drawingLibrary._backgroundImageSrc = backgroundImage.src;
                drawingLibrary._backgroundImage._rotation = 0;
                if (!map2d._imageLoadedAndResized) {
                  resizeCanvasToBackgroundImage(
                    drawingLibrary,
                    drawingLibrary._canvas,
                    img
                  );

                  // need o resize this aswell otherwise draw position is all wrong
                  drawingLibrary._circleMask = drawingLibrary.createGraphics(
                    drawingLibrary._canvas.width,
                    drawingLibrary._canvas.height
                  );
                  drawingLibrary._circleMask.imageMode(drawingLibrary.CENTER);
                  drawingLibrary._circleMask.angleMode(drawingLibrary.DEGREES);

                  // draw a red cricle in the middel
                  drawingLibrary._circleMask.fill(255, 0, 0);
                  drawingLibrary._circleMask.noStroke();

                  // Create a history of previous canvas states.
                  map2d.setCanvasHistory([drawingLibrary._circleMask.get()]);

                  map2d.createBonsaiPot(
                    drawingLibrary._canvas.width / 2,
                    drawingLibrary._canvas.height * 0.6
                  );
                  // set pan to center
                  map2d._panX = -drawingLibrary.width / 2;
                  map2d._panY = -drawingLibrary.height / 2;
                }
              },
              (err) => {
                console.error(err);
                // map2d.setBackgroundImageLoading(false);
              }
            );
          }
        }
      }
    }

    // If it is in OUTLINE mode then draw everything on drawingLibrary._penAndPencilCanvas
    // if not then draw everything on drawingLibrary._canvas

    let canvasToDrawOn = drawingLibrary;

    // This is causing eraser not to work
    if (isOutline(toolbars)) {
      canvasToDrawOn = drawingLibrary._penAndPencilCanvas;
    }

    if (map2d.isForegroundImageVisible()) {
      if (
        drawingLibrary._foregroundImage &&
        drawingLibrary._foregroundImageSrc === map2d.foregroundImage().src
      ) {
        // get the height and width of the background image, then draw to fit the canvas
        let foregroundImageWidth = drawingLibrary._foregroundImage.width;
        let foregroundImageHeight = drawingLibrary._foregroundImage.height;
        let foregroundImageScale = Math.min(
          drawingLibrary.width / foregroundImageWidth,
          drawingLibrary.height / foregroundImageHeight
        );
        drawImageScaledAtPosition(
          canvasToDrawOn,
          drawingLibrary._foregroundImage,
          changableBackgroundImagePosition.x,
          changableBackgroundImagePosition.y,
          foregroundImageScale,
          map2d._rotate + drawingLibrary._foregroundImage._rotation || 0
        );
      } else {
        if (map2d.foregroundImage() && map2d.foregroundImage().src) {
          if (!map2d.isForegroundImageLoading()) {
            map2d.setForegroundImageLoading(true);
            drawingLibrary.loadImage(
              map2d.foregroundImage().src,
              (img) => {
                drawingLibrary._foregroundImage = img;
                drawingLibrary._foregroundImageSrc =
                  map2d.foregroundImage().src;
                drawingLibrary._foregroundImage._rotation = 0;

                // if is outline then make it yellow
                if (isOutline(toolbars)) {
                  replaceColourInImage(
                    drawingLibrary._foregroundImage,
                    "#000000",
                    "#ffff00"
                  );
                }

                if (!map2d._imageLoadedAndResized) {
                  resizeCanvasToBackgroundImage(
                    drawingLibrary,
                    drawingLibrary._canvas,
                    img
                  );

                  // need o resize this aswell otherwise draw position is all wrong
                  drawingLibrary._circleMask = drawingLibrary.createGraphics(
                    drawingLibrary._canvas.width,
                    drawingLibrary._canvas.height
                  );
                  drawingLibrary._circleMask.imageMode(drawingLibrary.CENTER);
                  drawingLibrary._circleMask.angleMode(drawingLibrary.DEGREES);

                  // draw a red cricle in the middel
                  drawingLibrary._circleMask.fill(255, 0, 0);
                  drawingLibrary._circleMask.noStroke();

                  // Create a history of previous canvas states.
                  map2d.setCanvasHistory([drawingLibrary._circleMask.get()]);

                  map2d.createBonsaiPot(
                    drawingLibrary._canvas.width / 2,
                    drawingLibrary._canvas.height * 0.6
                  );
                  // set pan to center
                  map2d._panX = -drawingLibrary.width / 2;
                  map2d._panY = -drawingLibrary.height / 2;
                }
              },
              (err) => {
                console.error(err);
                // map2d.setForegroundImageLoading(false);
              }
            );
          }
        }
      }
    }
    map2d.floodFillHistory().forEach((canvas) => {
      // rotate the canvas
      drawingLibrary.push();

      // translate to middle of canvas
      drawingLibrary.translate(-canvas.x(), -canvas.y());
      //apply rotation
      drawingLibrary.rotate(map2d._rotate || 0);
      drawingLibrary.rotate(canvas.rotation() || 0);

      //apply the scale
      drawingLibrary.scale(1 / canvas.scale());

      drawingLibrary.image(canvas, 0, 0);
      drawingLibrary.pop();
    });

    if (!drawingLibrary._branchMask && map2d._brushTexture) {
      let img = map2d._brushTexture;
      drawingLibrary._branchMask = drawingLibrary.createGraphics(1500, 800);
      // map2d._brushTexture.resize(drawingLibrary.width, drawingLibrary.height);
      // set the background of the mask to 0
      drawingLibrary._branchMask.background(0);
    }
    // if (map2d.latestFloodFill()) {
    //   return;
    // }

    // DRAW BRANCHES
    const currentBranch = map2d.currentBranch();
    if (currentBranch && drawingLibrary._branchMask) {
      for (let i = 1; i < currentBranch.length; i++) {
        let previousNode = currentBranch[i - 1];
        let node = currentBranch[i];

        // Set stroke weight to taper
        drawingLibrary._branchMask.stroke(255);
        drawingLibrary._branchMask.fill(255);
        drawVaryingWidthLine(
          drawingLibrary._branchMask,
          previousNode.x,
          previousNode.y,
          node.x,
          node.y,
          previousNode.taper,
          node.taper
        );

        drawVaryingWidthLine(
          drawingLibrary,
          previousNode.x,
          previousNode.y,
          node.x,
          node.y,
          previousNode.taper,
          node.taper
        );
      }
    }

    if (map2d._maskedImage) {
      // drawingLibrary.image(map2d._maskedImage, 0, 0);
      // get the height and width of the background image, then draw to fit the canvas
      let backgroundImageWidth = map2d._maskedImage.width;
      let backgroundImageHeight = map2d._maskedImage.height;
      let backgroundImageScale = Math.min(
        map2d._maskedImage.width / backgroundImageWidth,
        map2d._maskedImage.height / backgroundImageHeight
      );

      // drawImageScaledAtPosition(
      //   drawingLibrary,
      //   drawingLibrary._branchMask,
      //   0,
      //   0,
      //   1
      //   // backgroundImageScale
      // );
      let width = map2d._maskedImage.width;
      let height = map2d._maskedImage.height;
      drawingLibrary.image(
        map2d._maskedImage,
        width / 2,
        height / 2,
        width,
        height
      );
    }

    const branches = map2d.branches();
    if (
      (map2d.brushTexture() &&
        map2d._oldTexture != map2d.brushTexture() &&
        drawingLibrary._branchMask &&
        branches &&
        map2d.isBranchTextureBrush()) ||
      (branches &&
        map2d._oldLinesLength != branches.length &&
        drawingLibrary._branchMask)
    ) {
      if (map2d._oldLinesLength > branches.length) {
        // a branch has been removed
        drawingLibrary._branchMask.background(0);
        if (branches && drawingLibrary._branchMask) {
          for (let i = 0; i < branches.length; i++) {
            let line = branches[i];
            for (let j = 1; j < line.length; j++) {
              let previousNode = line[j - 1];
              let node = line[j];

              drawVaryingWidthLine(
                drawingLibrary._branchMask,
                previousNode.x,
                previousNode.y,
                node.x,
                node.y,
                previousNode.taper,
                node.taper
              );
            }
          }
        }
      }
      map2d
        .brushTexture()
        .resize(
          drawingLibrary._branchMask.width,
          drawingLibrary._branchMask.height
        );
      map2d._maskedImage = p5ImageMaskUsingGraphics(
        drawingLibrary,
        map2d.brushTexture(),
        drawingLibrary._branchMask
      );
      map2d._oldLinesLength = branches.length;
      map2d._oldTexture = map2d.brushTexture();
    }

    // Draw the canvas history
    // If it is in OUTLINE mode then draw everything on drawingLibrary._penAndPencilCanvas
    // if not then draw everything on drawingLibrary._canvas

    // draw each of the layers in history
    canvasToDrawOn.push();
    //

    // translate to middle of canvas
    canvasToDrawOn.translate(
      canvasToDrawOn.width / 2,
      canvasToDrawOn.height / 2
    );
    //apply rotation
    canvasToDrawOn.rotate(map2d._rotate || 0);
    map2d.canvasHistory().forEach((canvas) => {
      // if has a property action
      if (canvas.action) {
        canvasToDrawOn.push();

        // canvasToDrawOn.rotate(-map2d._rotate || 0);
        // canvasToDrawOn.translate(
        //   -canvasToDrawOn.width / 2,
        //   -canvasToDrawOn.height / 2
        // );
        let image = canvas.image;

        // translate to middle of canvas
        // //apply rotation
        // canvasToDrawOn.rotate(map2d._rotate || 0);

        // translate minus pan

        canvasToDrawOn.rotate(image.rotation() || 0);

        //apply the scale
        canvasToDrawOn.scale(1 / image.scale());

        canvasToDrawOn.image(image, image.x(), image.y());
        canvasToDrawOn.pop();
      }

      // if canvas is an array
      else if (Array.isArray(canvas)) {
        // draw the _lastEraseLocations
        // un translate
        canvasToDrawOn.push();

        canvasToDrawOn.rotate(canvas.rotation || 0);
        canvasToDrawOn.stroke(0);
        canvasToDrawOn.fill(0);
        canvasToDrawOn.erase();

        const brushSize = map2d.eraserSize();

        canvas.forEach((location) => {
          canvasToDrawOn.ellipse(location.x, location.y, brushSize, brushSize);
        });
        canvasToDrawOn.noErase();

        // retranslate
        canvasToDrawOn.pop();
      } else {
        // rotate the canvas
        canvasToDrawOn.push();

        // translate to middle of canvas
        // canvasToDrawOn.translate(
        //   canvasToDrawOn.width / 2,
        //   canvasToDrawOn.height / 2
        // );
        //apply rotation
        // canvasToDrawOn.rotate(map2d._rotate || 0);
        canvasToDrawOn.rotate(canvas._rotation || 0);
        canvasToDrawOn.image(canvas, 0, 0);
        canvasToDrawOn.pop();
      }
    });

    // draw the _lastEraseLocations
    canvasToDrawOn.stroke(0);
    canvasToDrawOn.fill(0);
    canvasToDrawOn.erase();
    canvasToDrawOn.push();
    const brushSize = map2d.eraserSize();
    map2d._lastEraseLocations.forEach((location) => {
      canvasToDrawOn.ellipse(location.x, location.y, brushSize, brushSize);
    });
    canvasToDrawOn.noErase();
    canvasToDrawOn.pop();

    canvasToDrawOn.pop();

    // if is outline then draw the canvas
    if (isOutline(toolbars)) {
      drawingLibrary.image(
        drawingLibrary._penAndPencilCanvas,
        drawingLibrary.width / 2,
        drawingLibrary.height / 2
      );
    }

    drawingLibrary.image(
      drawingLibrary._circleMask,
      drawingLibrary.width / 2,
      drawingLibrary.height / 2
    );

    if (
      map2d.toolbar() === map2d.ORIENTATION_TOOLBAR ||
      map2d.toolbar() === map2d.COLOURING_TOOLBAR ||
      map2d.toolbar() === map2d.FOLIAGE_TOOLBAR
    ) {
      if (map2d.isBonsaiPotVisible()) {
        // draw bonsai pot at the horizontal center and 1/3 of the way down the canvas
        let x = drawingLibrary.width / 2;
        let y = drawingLibrary.height * 0.66;

        map2d.bonsaiPot().draw(drawingLibrary);
        // drawBonsaiPot(x, y, map2d.bonsaiPot(), drawingLibrary);
      } else {
        // Make the last 1/3 of the canvas black and 50% transparent
        // drawingLibrary.fill(0, 127);
        // drawingLibrary.stroke(0);
        // drawingLibrary.strokeWeight(2);
        // drawingLibrary.rect(
        //   0,
        //   drawingLibrary.height * 0.66,
        //   drawingLibrary.width,
        //   drawingLibrary.height
        // );
      }
    }

    // draw foliage manager
    if (map2d.foliageImageManager()) {
      map2d.foliageImageManager().drawMidground(drawingLibrary);
      map2d.foliageImageManager().drawForeground(drawingLibrary);
    }
    if (map2d.foliageImageManager().showTriangle()) {
      map2d.foliageImageManager().drawTriangle(drawingLibrary);
    }

    drawingLibrary.pop();
    if (map2d.drawingTool() === "brush" || map2d.isEraser()) {
      drawingLibrary.noFill();
      drawingLibrary.stroke(255);
      drawingLibrary.strokeWeight(1);
      // black and white dashed stroke
      const brushSize = map2d.isEraser()
        ? map2d.eraserSize()
        : map2d.brushSize();
      // console.log("brushSize", map2d.brushSize());
      // console.log("eraserSize", map2d.eraserSize());
      let yDraw = mobileConsciousBrushYPosition(drawingLibrary, map2d);

      // Draws the outside of the hover brush/eraser
      drawingLibrary.circle(
        drawingLibrary.mouseX,
        yDraw,
        brushSize * map2d.scale()
      );
      drawingLibrary.stroke(0);
      drawingLibrary.circle(
        drawingLibrary.mouseX,
        yDraw,
        brushSize * map2d.scale() - 1
      );

      // if brush mode is color then draw a small circle of the color
      if (map2d.isColorBrush()) {
        drawingLibrary.fill(map2d.brushColor());
        drawingLibrary.noStroke();

        // Draws the inside of the hover brush/eraser

        drawingLibrary.circle(
          drawingLibrary.mouseX,
          yDraw,
          brushSize * map2d.scale()
        );
      } else if (map2d.isTextureBrush() && map2d.brushTexture()) {
        drawingLibrary.imageMode(drawingLibrary.CENTER);
        drawingLibrary.image(
          map2d.brushTexture(),
          drawingLibrary.mouseX,
          yDraw,
          brushSize * map2d.scale(),
          brushSize * map2d.scale()
        );
      }
    }
  };

  map2d.createLibrary(containerElement, map2d.setup, map2d.draw);

  return map2d;
}

function drawImageScaledAtPosition(p5, image, x, y, scale, rotation = 0) {
  // Scale image to fit in the canvas
  let xPos = p5.width / 2 + (x * p5.width) / 2;
  let yPos = p5.height / 2 + (y * p5.height) / 2;

  // get scaled image width and height
  let scaledWidth = image.width * scale;
  let scaledHeight = image.height * scale;

  p5.push();

  // translate to the center of the image
  p5.translate(xPos, yPos);

  // rotate the image
  p5.rotate(rotation);
  p5.image(image, 0, 0, scaledWidth, scaledHeight);

  p5.pop();
}

export function pipeMixins(...mixins) {
  return Object.create(Object.assign({}, ...mixins));
}

export const withP5DrawingLibrary = (function withP5DrawingLibrary() {
  const sketch = (setupCb, drawCb, that, p) => {
    // TODO not entirely sure about this here
    p.setup = () => setupCb(p);
    p.draw = () => drawCb(p);
    that._p = p;
  };

  function createLibrary(containerElement, setupCb, drawCb) {
    this.drawingLibrary_ = new p5(
      sketch.bind(this, setupCb, drawCb, this),
      containerElement
    );
  }

  function destroyLibrary() {
    // if _penAndPencilCanvas, _cirlceMask, _branchMask then remove
    if (this.drawingLibrary_._penAndPencilCanvas)
      this.drawingLibrary_._penAndPencilCanvas.remove();
    this.drawingLibrary_._penAndPencilCanvas = null;
    if (this.drawingLibrary_._circleMask)
      this.drawingLibrary_._circleMask.remove();
    this.drawingLibrary_._circleMask = null;
    if (this.drawingLibrary_._branchMask)
      this.drawingLibrary_._branchMask.remove();
    this.drawingLibrary_._branchMask = null;

    // foregorund and backgroundimage
    if (this.drawingLibrary_._foregroundImage)
      this.drawingLibrary_._foregroundImage = null;
    if (this.drawingLibrary_._backgroundImage)
      this.drawingLibrary_._backgroundImage = null;

    this.drawingLibrary_._canvas = null;
    this.drawingLibrary_.remove();
    this.drawingLibrary_ = null;
    this._p = null;
    // delete all canvas in the dom
    const canvases = document.getElementsByTagName("canvas");
    for (let i = 0; i < canvases.length; i++) {
      canvases[i].remove();
    }
  }

  function drawingLibrary() {
    return this.drawingLibrary_;
  }

  // Reveal and hoist common drawingLibrary components
  function width() {
    return this.drawingLibrary().width;
  }

  function height() {
    return this.drawingLibrary().height;
  }

  function loadImage(...args) {
    return this.drawingLibrary().loadImage(...args);
  }

  return {
    createLibrary,
    destroyLibrary,
    drawingLibrary,
    width,
    height,
    loadImage,
  };
})();
