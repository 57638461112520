import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import AppBarDashboard from "../components/AppBar/Dashboard";
import BonsaiMasonryDesignList from "../components/BonsaiMasonryDesignList";
import { getAllData } from "../libs/firebase/firestore/getAllData";
import { getAuth } from "firebase/auth";
import LoadingScreen from "../components/elements/LoadingScreen";
import { tutorialBonsai } from "../libs/tutorial/bonsai/tutorialBonsai";
import { useParams } from "react-router-dom";
import { getDataFirebaseRecursion } from "../utilities/getAllDataRecursion";
import BottomBarRoot from "../components/BottomBar/Root";
import { getData } from "../libs/firebase/firestore/getData";
import { useNavigateToTop } from "../libs/useNavigateToTop";
import AppBarCommunity from "../components/AppBar/Community";
import DashboardList from "../components/DashboardList";
import DashboardListStable from "../components/DashboardListStable";

//  no data component
function NoDataMessage({}) {
  return (
    <Box
      style={{
        padding: "1rem",
        background: "white",
        borderRadius: "1rem",
        margin: "1.5rem",
        border: "1px solid #ccc",
      }}
    >
      <Typography variant="h6" component="h4" gutterBottom>
        Welcome to the community page
      </Typography>
    </Box>
  );
}

function WelcomeMessage({}) {
  return (
    <Box
      style={{
        padding: "1rem",
        background: "white",
        borderRadius: "1rem",
        margin: "1.5rem",
        border: "1px solid #ccc",
      }}
    >
      <Typography variant="h6" component="h4" gutterBottom>
        Here are trees others need help with. You can make a design and send it
        to the owner.
      </Typography>
    </Box>
  );
}

export function ScreenCommunityDashboard({ onClick, onCreate }) {
  const [data, setData] = React.useState([]);
  const [noData, setNoData] = React.useState(false);

  const { userId } = useParams();
  const navigate = useNavigateToTop();

  let currentUser = getAuth().currentUser;

  const handleClick = (image, index) => {
    let request = data[index];
    navigate(
      `/${request.ownerId || request.ownerID}/bonsai/${
        request.bonsaiId || request.bonsaiID
      }`
    );

    // onClick(data[index], userId, currentUser);
  };

  const handleGotRequestsData = (data) => {
    if (!data) return;
    let requests = data;

    // remove any requests where the owner is the current user
    // requests = requests.filter((x) => x.ownerId !== getAuth().currentUser.uid);
    setData(requests);

    if (requests.length === 0) setNoData(true);
  };

  useEffect(() => {
    getAllData("/designRequests", handleGotRequestsData, (err) => {
      console.log(err);
    });
  }, []);
  if (!getAuth().currentUser && !userId)
    return <LoadingScreen title={"finding trees..."} />;

  function dataToDashboardList(inputData) {
    /**
     * Returns data as an array of objects with the following properties:
     * image: string
     * title: string
     * subtitle: string
     * badge: string
     *
     */
    let dataToDisplay = inputData.map((x) => {
      let title = "";
      let subtitle = "";

      // If user is not the owner then set the title and subtitle to the owner's name

      // If the tree belongs to the current user then set the title and subtitle to "Your tree"
      let ownerId = x.ownerId || x.ownerID;

      if (ownerId === getAuth().currentUser.uid) {
        title = "Your tree";
        subtitle = "";
      }

      let data = {
        image: x.fronts[0],
        title: title,
        subtitle: subtitle,
        badge: null,
        disabled: ownerId === getAuth().currentUser.uid,
      };
      return data;
    });
    return dataToDisplay;
  }

  const showData = (data && data.length) || noData;
  // const dataToDisplay = data.filter((x) => x.fronts);
  return (
    <Box style={{ overflow: "hidden", paddingBottom: "4em" }}>
      <AppBarCommunity />
      <WelcomeMessage />
      {!showData && <LoadingScreen title={"finding your trees..."} />}
      {noData && <NoDataMessage />}
      {showData && (
        <DashboardListStable
          data={dataToDashboardList(data)}
          onClick={handleClick}
        />
        // <BonsaiMasonryDesignList
        //   data={dataToDisplay.map((x) => (x.fronts ? x.fronts[0] : null))}
        //   onClick={handleClick}
        // />
      )}
      <BottomBarRoot startValue={1} />
    </Box>
  );
}
